import { ChartStoragePanelState } from '@pages/app/rca/tabs/components/storage/chart-storage-hook';
import Column from '@components/layout-util-components/column';
import { InputAdornment, styled } from '@mui/material';
import { StyledSearchField } from '@components/table/table-filters';
import { ReactComponent as SearchIcon } from '@assets/svgs/search.svg';
import { Gap } from '@components/layout-util-components/gap';
import ChartStorageNode from '@pages/app/rca/tabs/components/storage/chart-storage-node';
import { FlatButton, PrimaryButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { RcaNode, StorageNode } from '@store/rca-editor/types';
import DeleteStorageNodeModal from '@pages/app/rca/tabs/components/modals/delete-storage-node-modal';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { DragEvent, Fragment, useMemo, useRef, useState } from 'react';
import { RcaUtil } from '@util/rca-util';
import {
  createStorageDragNode,
  removeNodeFromStorage,
  stopDraggingNode,
} from '@store/rca-editor/rca-editor-actions';
import { useAppDispatch, useAppSelector } from '@store/store';
import ChartStorageAddNode from '@pages/app/rca/tabs/components/storage/views/chart-storage-add-node';
import { isNullOrEmpty } from '@util/string-util';
import { useLazyGetCaseTotalsForChainItemQuery } from '@api/endpoints/case.api';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import { usePageAlertVariants } from '@components/alerts';
import { LoadingIndicator } from '@components/loading-indicator';

// Fix to prevent chrome's globe icon appearing when dragging out of storage
// https://www.sam.today/blog/html5-dnd-globe-icon
export const EMPTY_IMAGE = new Image(1, 1);
EMPTY_IMAGE.src =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

interface Props {
  state: ChartStoragePanelState;
}

const Container = styled(Column)(() => ({
  flex: '1 0 50%',
  backgroundColor: '#ECEDF0',
  padding: '16px',
  overflowY: 'auto',
  '.custom-row': {
    padding: '0 4px',
  },
}));

const SearchField = styled(StyledSearchField)({
  '& .MuiInputBase-root': {
    background: '#FFF',
    borderRadius: 4,
    boxShadow: '4px 0 20px rgba(0,0,0,0.05)',
  },
});

export default function ChartStorageListView({ state }: Props) {
  const [isAdding, setIsAdding] = useState(false);

  const dragNodeRef = useRef<RcaNode>();
  const dispatch = useAppDispatch();
  const { showPopup } = useUiPopup();
  const { storageNodes, allowAddNode, allowEditNode, allowDragAndDrop } = state;
  const [search, setSearch] = useState<string>();
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const [getTotalsForChainItem, { isFetching: checkingChainItemTotals }] =
    useLazyGetCaseTotalsForChainItemQuery();
  const { showSuccessMessage } = usePageAlertVariants();
  const [deletingNode, setDeletingNode] = useState<StorageNode>();

  const onDeleteNode = async (node: StorageNode) => {
    setDeletingNode(node);

    const { chainItemId } = node;

    const { data: totals } = await getTotalsForChainItem({
      caseId,
      chainItemId: chainItemId!,
    });

    const hasNotes = totals!.notes > 0;
    const hasTasks = totals!.tasks! > 0;
    const hasImpacts = totals!.impacts! > 0;
    const hasEvidence = totals!.evidence! > 0;
    const hasSolutions = totals!.solutions! > 0;
    const hasAnyData =
      hasNotes || hasTasks || hasImpacts || hasEvidence || hasSolutions;

    if (!hasAnyData) {
      dispatch(removeNodeFromStorage(node));
      showSuccessMessage(
        `You have successfully deleted cause Box “${node.description}” from cause storage`
      );
      return;
    }

    return showPopup(DeleteStorageNodeModal, {
      node,
    });
  };

  const onDragStart = (e: DragEvent, node: StorageNode) => {
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setDragImage(EMPTY_IMAGE, 0, 0);

    const nodePos = RcaUtil.getNodePosFromCursor(e);
    dragNodeRef.current = dispatch(
      createStorageDragNode(node, nodePos.x, nodePos.y)
    );
  };

  const onDragEnd = () => {
    if (dragNodeRef.current != null) {
      dispatch(stopDraggingNode(dragNodeRef.current));
    }
  };

  const filteredNodes = useMemo(() => {
    return storageNodes.filter((x) => {
      return (
        isNullOrEmpty(search) ||
        (x.description ?? '').toLowerCase().indexOf(search!.toLowerCase()) !==
          -1
      );
    });
  }, [search, storageNodes]);

  return (
    <>
      <Column>
        <SearchField
          sx={{ flexGrow: 1 }}
          variant="filled"
          type="search"
          value={search || ''}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Gap size={8} />
      </Column>
      <Gap size={10} />
      <Container>
        {allowAddNode && (
          <PrimaryButton disabled={isAdding} onClick={() => setIsAdding(true)}>
            <FontAwesomeIcon icon={faPlus} size="2xl" />
          </PrimaryButton>
        )}
        {isAdding && (
          <>
            <Gap size={10} />
            <ChartStorageAddNode onClose={() => setIsAdding(false)} />
          </>
        )}
        <Gap size={10} />
        {filteredNodes.map((node) => {
          return (
            <Fragment key={node.clientUuid}>
              <ChartStorageNode
                description={node.description}
                chainItemId={node.chainItemId}
                clientUuid={node.clientUuid}
                onDragStart={
                  allowDragAndDrop
                    ? (e) => {
                        onDragStart(e, node);
                      }
                    : undefined
                }
                onDragEnd={allowDragAndDrop ? onDragEnd : undefined}
                actionButton={
                  allowEditNode ? (
                    <FlatButton outline onClick={() => onDeleteNode(node)}>
                      {checkingChainItemTotals &&
                      deletingNode?.chainItemId === node.chainItemId ? (
                        <LoadingIndicator show />
                      ) : (
                        <FontAwesomeIcon icon={faTrashAlt} />
                      )}
                    </FlatButton>
                  ) : undefined
                }
              />
              <Gap size={2} />
            </Fragment>
          );
        })}
      </Container>
    </>
  );
}

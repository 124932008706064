import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  styled,
  TextField,
} from '@mui/material';
import { Children, PropsWithChildren, ReactNode } from 'react';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import Spacer from '@components/layout-util-components/spacer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';

type Props = {
  isLoading?: boolean;
  isFetching?: boolean;
  search?: string;
  onSearch?: (query?: string) => void;
  searchPlaceholder?: string;
  rightOfSearchContent?: ReactNode;
  size?: 'normal' | 'large';
};

const StyledFilters = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: 16,
  rowGap: 10,
});

export const StyledSearchField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.greyVeryDark}`,
    borderRadius: 4,
    background: 'transparent',
    color: theme.palette.mainBlue,
    fontFamily: 'Usual',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    opacity: 1.0,
    '&:focus-within': {
      background: theme.palette.white.main,
      color: theme.palette.blackBlack,
    },
  },
  '&>*': {
    height: 36,
    '&>input': {
      padding: 0,
      '&::placeholder': {
        color: theme.palette.mainBlue,
        opacity: 1,
      },
    },
    '&>.MuiInputAdornment-root': {
      marginTop: '0 !important',
      color: theme.palette.mainBlue,
      opacity: 1,
      fontSize: 14,
      height: 20,
      width: 20,
      minWidth: 20,
      justifyContent: 'center',
      marginRight: 5,

      '&.MuiInputAdornment-positionEnd': {
        marginLeft: 8,
        marginRight: -5,
        '*': {
          fontSize: 14,
        },
      },
    },
  },
}));

export default function WCTTableFilters({
  isLoading,
  isFetching,
  search,
  onSearch,
  children,
  searchPlaceholder,
  rightOfSearchContent,
  size = 'normal',
}: PropsWithChildren<Props>) {
  const minWidth = size === 'large' ? 320 : 220;
  return (
    <StyledFilters>
      {onSearch != null ? (
        isLoading ? (
          <Skeleton variant="rectangular" width={minWidth}>
            <StyledSearchField
              variant="filled"
              type="search"
              value={search || ''}
              onChange={(e) => onSearch(e.target.value)}
              placeholder={searchPlaceholder || 'Type to search'}
              InputLabelProps={{
                classes: {
                  focused: 'focused',
                },
              }}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputAdornment>
                ),
                endAdornment: search && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => onSearch('')}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </IconButton>
                ),
              }}
            />
          </Skeleton>
        ) : (
          <StyledSearchField
            variant="filled"
            type="text"
            value={search || ''}
            onChange={(e) => onSearch(e.target.value)}
            placeholder={searchPlaceholder || 'Type to search'}
            sx={{
              minWidth: minWidth,
              marginRight: rightOfSearchContent ? '-16px' : 0,
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              ),
              endAdornment: search && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="toggle password visibility"
                    onClick={() => onSearch('')}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )
      ) : (
        <div></div>
      )}
      {rightOfSearchContent ? (
        <Box>{isLoading ? <Skeleton></Skeleton> : rightOfSearchContent}</Box>
      ) : null}
      <FetchingIndicator show={!isLoading && !!isFetching} />
      <Spacer />
      {children
        ? Children.map(children, (filter, i) => {
            return isLoading ? (
              <Skeleton variant="rectangular">{filter}</Skeleton>
            ) : (
              filter
            );
          })
        : null}
    </StyledFilters>
  );
}

import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import Column from '@components/layout-util-components/column';
import { Button, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import WCTTextField from '@components/input/text-field';
import useField from '@hooks/use-field-hook';
import {
  lessThan,
  required,
  ruleForEach,
  validatorSelector,
} from '@util/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/pro-light-svg-icons';
import ActionsRow from '@components/layout-util-components/actions-row';
import { Fragment, useMemo } from 'react';
import Spacer from '@components/layout-util-components/spacer';
import { Divider } from '@components/layout-util-components/divider';
import { Option } from '@api/types/option';
import Row from '@components/layout-util-components/row';
import useBusyAction from '@hooks/use-busy-action-hook';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { usePageAlertVariants } from '@components/alerts';
import { isApiError } from '@api/types/api-error';
import { invalidation } from '@api/cache-util';
import AsyncButton from '@components/buttons/async-button';
import { useCreateCaseTypeCategoryMutation } from '@api/endpoints/company/company-case-type-category.api';
import { CreateCaseTypeCategoryRequest } from '@api/types/company/company-case-type-category/create-case-category.request';

export interface AddCategoryModalProps extends ModalProps<void> {
  defaultValue?: string;
}

function AddCategoryModal({ defaultValue, onClose }: AddCategoryModalProps) {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const [createCategory] = useCreateCaseTypeCategoryMutation();

  const category = useField(
    [required(), lessThan(200, 'Enter 200 characters or less')],
    defaultValue
  );
  const caseTypes = useField<Array<Option>>(
    [
      ruleForEach(
        validatorSelector<Option>('label', [
          required(),
          lessThan(200, 'Enter 200 characters or less'),
        ])
      ),
    ],
    useMemo(() => [], [])
  );

  const { validateAll } = useFieldsWatcher([category, caseTypes]);

  const hasCaseTypes = caseTypes.value.length > 0;

  const [onCreateCaseType, isBusy] = useBusyAction(async () => {
    try {
      if (!validateAll()) {
        return;
      }

      const names = caseTypes.value.map((impact) => impact.label);
      await createCategory({
        name: category.value,
        names: names,
      }).unwrap();

      await invalidation('CaseType');

      showSuccessMessage('Category added successfully');

      onClose();
    } catch (e) {
      if (isApiError<CreateCaseTypeCategoryRequest>(e)) {
        showErrorMessage(e.errors?.names ?? e.message);
        category.setError(e.errors?.name);
      } else {
        console.log(e);
      }
    }
  });

  return (
    <Column>
      <Typography>Add Category</Typography>
      <Gap size={20} />
      <WCTTextField
        name="value"
        label="Category Title"
        value={category.value}
        onChange={category.set}
        error={category.error}
      />
      <Gap size={10} />
      {hasCaseTypes ? <Divider compact addMargin /> : <Gap size={20} />}
      {caseTypes.value.map((impact, i) => (
        <Fragment key={impact.id || i + 1}>
          <Row>
            <WCTTextField
              name={`impact_${i}_title`}
              label="Business Function Title"
              value={impact.label}
              onChange={(value) => {
                caseTypes.set(
                  caseTypes.value.map((t, j) =>
                    i === j ? { ...t, label: value } : t
                  )
                );
              }}
              error={caseTypes.errors?.[i]}
            />
            <Gap size={10} />
            <Button
              variant="text"
              onClick={() =>
                caseTypes.set(caseTypes.value.filter((_, j) => i !== j))
              }
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Row>
          <Gap size={20} />
        </Fragment>
      ))}
      <Button
        variant="text"
        sx={{ alignSelf: 'flex-start' }}
        onClick={() => {
          caseTypes.set([...caseTypes.value, '']);
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        Add Business Function
      </Button>
      <Gap size={20} />
      <ActionsRow>
        <Button variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <Spacer />
        <AsyncButton onClick={onCreateCaseType} isBusy={isBusy}>
          Save
        </AsyncButton>
      </ActionsRow>
    </Column>
  );
}

export default withModal(AddCategoryModal);

import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import Column from '@components/layout-util-components/column';
import { Button, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import WCTTextField from '@components/input/text-field';
import useField from '@hooks/use-field-hook';
import {
  lessThan,
  required,
  ruleForEach,
  validatorSelector,
} from '@util/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/pro-light-svg-icons';
import ActionsRow from '@components/layout-util-components/actions-row';
import React, { Fragment, useMemo } from 'react';
import Spacer from '@components/layout-util-components/spacer';
import { Divider } from '@components/layout-util-components/divider';
import { Option } from '@api/types/option';
import Row from '@components/layout-util-components/row';
import {
  useCreateChildThemeMutation,
  useCreateThemeMutation,
} from '@api/endpoints/company/company-theme.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { usePageAlertVariants } from '@components/alerts';
import { isApiError } from '@api/types/api-error';
import { CreateThemeRequest } from '@api/types/theme/create-theme.request';
import { CreateChildThemeRequest } from '@api/types/theme/create-child-theme.request';
import { invalidation } from '@api/cache-util';
import AsyncButton from '@components/buttons/async-button';

export interface AddCategoryModalProps extends ModalProps<void> {
  defaultValue?: string;
}

function AddCategoryModal({ defaultValue, onClose }: AddCategoryModalProps) {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const [createTheme] = useCreateThemeMutation();
  const [createChildTheme] = useCreateChildThemeMutation();

  const category = useField(
    [required(), lessThan(200, 'Enter 200 characters or less')],
    defaultValue
  );
  const themes = useField<Array<Option>>(
    [
      ruleForEach(
        validatorSelector<Option>('label', [
          required(),
          lessThan(200, 'Enter 200 characters or less'),
        ])
      ),
    ],
    useMemo(() => [], [])
  );

  const { validateAll } = useFieldsWatcher([category, themes]);

  const hasThemes = themes.value.length > 0;

  const [onCreateTheme, isBusy] = useBusyAction(async () => {
    try {
      if (!validateAll()) {
        return;
      }

      const parent = await createTheme({ name: category.value }).unwrap();

      if (themes.value.length > 0) {
        const names = themes.value.map((theme) => theme.label);
        await createChildTheme({ parentId: parent.themeId, names }).unwrap();
      }

      await invalidation('CompanyTheme');

      showSuccessMessage('Category added successfully');

      onClose();
    } catch (e) {
      if (isApiError<CreateThemeRequest>(e)) {
        showErrorMessage(e.message);
        category.setError(e.errors?.name);
      } else if (isApiError<CreateChildThemeRequest>(e)) {
        showErrorMessage(e.message);
        themes.setError(e.errors);
      } else {
        console.log(e);
      }
    }
  });

  return (
    <Column>
      <Typography>Add Category</Typography>
      <Gap size={20} />
      <WCTTextField
        name="value"
        label="Category Title"
        value={category.value}
        onChange={category.set}
        error={category.error}
      />
      {hasThemes ? <Divider compact addMargin /> : <Gap size={20} />}
      {themes.value.map((theme, i) => (
        <Fragment key={theme.id || i + 1}>
          <Row>
            <WCTTextField
              name={`theme_${i}_title`}
              label="Theme Title"
              value={theme.label}
              onChange={(value) => {
                themes.set(
                  themes.value.map((t, j) =>
                    i === j ? { ...t, label: value } : t
                  )
                );
              }}
              error={themes.errors?.[i]}
            />
            <Gap size={10} />
            <Button
              variant="text"
              onClick={() => themes.set(themes.value.filter((_, j) => i !== j))}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Row>
          <Gap size={20} />
        </Fragment>
      ))}
      <Button
        variant="text"
        sx={{ alignSelf: 'flex-start' }}
        onClick={() => {
          themes.set([...themes.value, '']);
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        Add Theme
      </Button>
      <Gap size={20} />
      <ActionsRow>
        <Button variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <Spacer />
        <AsyncButton onClick={onCreateTheme} isBusy={isBusy}>
          Save
        </AsyncButton>
      </ActionsRow>
    </Column>
  );
}

export default withModal(AddCategoryModal);

import requireAuth from '@components/hoc/with-required-auth-hoc';
import useCompanyCaseTypes from './company-case-types-hook';
import SubPageTitle from '@components/menu-items/subpage-title';
import { Gap } from '@components/layout-util-components/gap';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { WCTTableFilters } from '@components/table';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import HierachyItem from '@pages/app/company-profile/rca-settings/components/hierachy-item';
import { Button } from '@mui/material';
import AddCategoryModal, {
  AddCategoryModalProps,
} from './components/add-case-type-category-modal';
import AddCaseTypeModal, {
  AddCaseTypeModalProps,
} from './components/add-case-type-modal';
import { CaseTypeCategoryResource } from '@api/types/company/company-case-type-category/case-type-category.resource';
import { CompanyCaseTypeResource } from '@api/types/company/company-case-type/company-case-type.resource';
import EditCaseTypeModal, {
  EditCaseTypeModalProps,
} from './components/edit-case-type-modal';
import { LoadingIndicator } from '@components/loading-indicator';
import useCompanyProfileLayout from '@components/layouts/app/company-profile-layout-hook';

function CompanyCaseTypesPage() {
  const {
    categories,
    loadingCategories,
    fetchingCategories,
    toggleCategory,
    setSearchText,
    searchText,
  } = useCompanyCaseTypes();
  const { companyProfileDefaultUrl } = useCompanyProfileLayout();

  const { showPopup } = useUiPopup();

  const openAddCategoryModal = async () => {
    await showPopup<AddCategoryModalProps, void>(AddCategoryModal);
  };

  const openAddCaseTypeModal = async (category: CaseTypeCategoryResource) => {
    await showPopup<AddCaseTypeModalProps, void>(AddCaseTypeModal, {
      categoryId: category.caseTypeCategoryId,
    });
  };

  const openEditCaseTypeModal = (
    caseType: CompanyCaseTypeResource | CaseTypeCategoryResource
  ) => {
    showPopup<EditCaseTypeModalProps, void>(EditCaseTypeModal, {
      caseType: caseType,
    });
  };

  return (
    <>
      <SubPageTitle
        items={[
          { label: 'Company Profile', path: companyProfileDefaultUrl },
          { label: 'RCA Settings', path: '/company-profile/rca-settings' },
          { label: 'Business Function Configuration' },
        ]}
      />
      <Gap size={32} />
      <WCTTableFilters
        search={searchText}
        onSearch={setSearchText}
        isLoading={loadingCategories}
        isFetching={fetchingCategories}
      >
        <Button variant="contained" onClick={openAddCategoryModal}>
          <FontAwesomeIcon icon={faPlus} />
          Add Category
        </Button>
      </WCTTableFilters>
      <Gap size={20} />
      {loadingCategories ? (
        <LoadingIndicator show />
      ) : (
        categories?.map((category) => (
          <Fragment key={category.caseTypeCategoryId}>
            <HierachyItem
              canEdit={!category.hasRCA}
              isActive={!category.disabled}
              onEdit={() => {
                openEditCaseTypeModal(category);
              }}
              name={category.name}
              onToggleActive={(_) => toggleCategory(category)}
              addButton={{
                label: 'Add Business Function',
                onPressed: () => openAddCaseTypeModal(category),
              }}
              subItems={category.caseTypes.map((caseType) => ({
                id: caseType.caseTypeId,
                label: caseType.name,
                canEdit: !(category.disabled || caseType.disabled),
                onEdit: (_) => {
                  openEditCaseTypeModal(caseType);
                },
              }))}
            />
            <Gap size={10} />
          </Fragment>
        ))
      )}
      <Gap size={20} />
    </>
  );
}

export default requireAuth(CompanyCaseTypesPage);

import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import Column from '@components/layout-util-components/column';
import { Button, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import WCTTextField from '@components/input/text-field';
import useField from '@hooks/use-field-hook';
import {
  lessThan,
  required,
  ruleForEach,
  validatorSelector,
} from '@util/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/pro-light-svg-icons';
import ActionsRow from '@components/layout-util-components/actions-row';
import { Fragment, useMemo } from 'react';
import Spacer from '@components/layout-util-components/spacer';
import { Option } from '@api/types/option';
import Row from '@components/layout-util-components/row';
import useBusyAction from '@hooks/use-busy-action-hook';
import { isApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { invalidation } from '@api/cache-util';
import AsyncButton from '@components/buttons/async-button';
import { useCreateCompanyCaseTypeMutation } from '@api/endpoints/company/company-case-type.api';
import { CreateCompanyCaseTypeRequest } from '@api/types/company/company-case-type/create-company-case-type.request';
import useFieldsWatcher from '@hooks/use-fields-watcher';

export interface AddCaseTypeModalProps extends ModalProps<void> {
  categoryId: number;
}

function AddCaseTypeModal({ onClose, categoryId }: AddCaseTypeModalProps) {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const [createCaseType] = useCreateCompanyCaseTypeMutation();

  const caseTypes = useField<Array<Option>>(
    [
      ruleForEach(
        validatorSelector<Option>('label', [
          required(),
          lessThan(200, 'Enter 200 characters or less'),
        ])
      ),
    ],
    useMemo(() => [{ id: -1, label: '' }], [])
  );

  const firstCaseType = caseTypes.value.at(0);
  const restCaseTypes = caseTypes.value.slice(1);

  const { validateAll } = useFieldsWatcher([caseTypes]);

  const [onSave, isBusy] = useBusyAction(async () => {
    try {
      if (!validateAll()) {
        return;
      }

      const names = caseTypes.value.map((impact) => impact.label);
      await createCaseType({
        caseTypeCategoryId: categoryId,
        names,
      }).unwrap();

      await invalidation('CaseType');

      showSuccessMessage('Business Function added successfully');

      onClose();
    } catch (e) {
      if (isApiError<CreateCompanyCaseTypeRequest>(e)) {
        const errors = e.errors as any;
        showErrorMessage(
          errors?.name ?? e.errors?.caseTypeCategoryId ?? e.message
        );
        caseTypes.setError(e.errors);
        caseTypes.setError(e.errors?.caseTypeCategoryId);
      }
    }
  });

  return (
    <Column>
      <Typography>Add Business Function</Typography>
      <Gap size={20} />
      <WCTTextField
        name="value"
        label="Business Function Title"
        value={firstCaseType?.label}
        onChange={(value) => {
          caseTypes.set([{ ...firstCaseType, label: value }, ...restCaseTypes]);
        }}
        error={caseTypes.errors?.[0]}
      />
      <Gap size={20} />
      {restCaseTypes.map((theme, i) => (
        <Fragment key={theme.id || i + 1}>
          <Row>
            <WCTTextField
              name={`case_type_${i}_title`}
              label="Business Function Title"
              value={theme.label}
              onChange={(value) => {
                caseTypes.set(
                  caseTypes.value.map((t, j) =>
                    i === j - 1 ? { ...t, label: value } : t
                  )
                );
              }}
              error={caseTypes.errors?.[i + 1]}
            />
            <Gap size={10} />
            <Button
              variant="text"
              onClick={() =>
                caseTypes.set(caseTypes.value.filter((_, j) => i !== j))
              }
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Row>
          <Gap size={20} />
        </Fragment>
      ))}
      <Button
        variant="text"
        sx={{ alignSelf: 'flex-start' }}
        onClick={() => {
          caseTypes.set([...caseTypes.value, '']);
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        Add another Business Function
      </Button>
      <Gap size={20} />
      <ActionsRow>
        <Button variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <Spacer />
        <AsyncButton onClick={onSave} isBusy={isBusy}>
          Save
        </AsyncButton>
      </ActionsRow>
    </Column>
  );
}

export default withModal(AddCaseTypeModal);

import { useGetCaseSeverityOptionsQuery } from '@api/endpoints/case-severity.api';
import { useGetCaseStatusOptionsQuery } from '@api/endpoints/case-status.api';
import { useGetCaseTypeOptionsQuery } from '@api/endpoints/case-type.api';
import { useGetAllCaseDataForGroupQuery } from '@api/endpoints/case.api';
import { useGetCompanyGroupDetailForUserQuery } from '@api/endpoints/company-group.api';
import { useGetCompanyGroupDetailQuery } from '@api/endpoints/company/company-company-group.api';
import { SortDirection } from '@api/types/sort-direction';
import { useFilter } from '@components/table';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { useMemo, useState } from 'react';
import LeaveGroupModal, {
  LeaveGroupAction,
  LeaveGroupModalProps,
} from './leave-group-modal';
import useUser from '@store/user/user-hook';

export enum ActiveTab {
  RCA,
  TeamMember,
}

export default function useGroupDetail(id: number) {
  const { showPopup } = useUiPopup();
  var user = useUser();
  const isManaging = user.isAdminOrSuperAdminUser;

  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.RCA);

  const { data: userCompanyGroup, isLoading: loadingUserCompanyGroup } =
    useGetCompanyGroupDetailForUserQuery(id, { skip: isManaging });

  const { data: companyGroup, isLoading: loadingCompanyGroup } =
    useGetCompanyGroupDetailQuery(id, { skip: !isManaging });

  const { data: statusOptions, isLoading: loadingStatusOptions } =
    useGetCaseStatusOptionsQuery();

  const { data: caseTypeOptions, isLoading: loadingCaseTypeOptions } =
    useGetCaseTypeOptionsQuery({});

  const { data: severityOptions, isLoading: loadingSeverityOptions } =
    useGetCaseSeverityOptionsQuery();

  const search = useFilter<string>();
  const type = useFilter<number>();
  const status = useFilter<number>();
  const severity = useFilter<number>();

  const {
    data: caseData,
    isLoading: loadingCases,
    isFetching,
  } = useGetAllCaseDataForGroupQuery({
    companyGroupId: id ? [id] : undefined,
    skip: 0,
    take: 100,
    sortBy: 'created',
    sortDirection: SortDirection.asc,
    searchText: search.value,
    caseStatusId: status.value ? [status!.value] : [],
    caseTypeId: type.value,
    caseSeverityId: severity.value,
  });

  const leaveGroup = () => {
    showPopup<LeaveGroupModalProps, LeaveGroupAction>(LeaveGroupModal, {
      companyGroupId: id,
      companyName: companyGroup?.name || '',
    });
  };

  const currentUserIsMember = useMemo(() => {
    const g = isManaging ? companyGroup : userCompanyGroup;
    return (
      g && g.groupMembers.find((x) => x.companyUserId === user.companyUserId)
    );
  }, [userCompanyGroup, companyGroup, isManaging, user]);

  return {
    isLoading:
      loadingUserCompanyGroup ||
      loadingCompanyGroup ||
      loadingStatusOptions ||
      loadingCaseTypeOptions ||
      loadingSeverityOptions,
    isFetching,
    group: isManaging ? companyGroup : userCompanyGroup,
    isLoadingCases: loadingCases,
    caseData: caseData,
    activeTab,
    setActiveTab,
    search,
    status,
    statusOptions,
    type,
    caseTypeOptions,
    severity,
    severityOptions,
    leaveGroup,
    currentUserIsMember,
  };
}

import { api } from '@api/api';
import { makeError } from '@api/types/api-error';
import { CompanyCaseImpactResource } from '@api/types/company/company-case-impact/company-case-impact.resource';
import { CreateCompanyCaseImpactRequest } from '@api/types/company/company-case-impact/create-company-case-impact.request';
import { UpdateCompanyCaseImpactRequest } from '@api/types/company/company-case-impact/update-company-case-impact.request';

const caseImpactTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyCaseImpacts: build.query<CompanyCaseImpactResource[], void>({
      query: (searchText) => ({
        url: 'company/caseImpactType',
        method: 'GET',
      }),
      providesTags: (response) =>
        response
          ? [
              'CaseImpact',
              ...response.map((data) => ({
                id: data.caseImpactTypeId,
                type: 'CaseImpact' as const,
              })),
            ]
          : [],
    }),
    createCompanyCaseImpact: build.mutation<
      void,
      CreateCompanyCaseImpactRequest
    >({
      query: (body) => ({
        url: 'company/caseImpactType',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateCompanyCaseImpactRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['CaseImpact'] : []),
    }),
    updateCompanyCaseImpact: build.mutation<
      void,
      UpdateCompanyCaseImpactRequest
    >({
      query: (body) => ({
        url: 'company/caseImpactType',
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<UpdateCompanyCaseImpactRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['CaseImpact'] : []),
    }),
    removeCompanyCaseImpact: build.mutation<void, number>({
      query: (id) => ({
        url: `company/caseImpactType/${id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => makeError<never>(error),
      invalidatesTags: (_, error) => (!error ? ['CaseImpact'] : []),
    }),
    enableCompanyCaseImpact: build.mutation<void, number>({
      query: (id) => ({
        url: `company/caseImpactType/${id}/enable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CaseImpact' }, 'CaseImpact'] : [],
    }),
    disableCompanyCaseImpact: build.mutation<void, number>({
      query: (id) => ({
        url: `company/caseImpactType/${id}/disable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CaseImpact' }, 'CaseImpact'] : [],
    }),
  }),
});

export const {
  useGetCompanyCaseImpactsQuery,
  useCreateCompanyCaseImpactMutation,
  useRemoveCompanyCaseImpactMutation,
  useUpdateCompanyCaseImpactMutation,
  useEnableCompanyCaseImpactMutation,
  useDisableCompanyCaseImpactMutation,
} = caseImpactTypeApi;

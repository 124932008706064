import React from 'react';
import { ReactComponent as FinancialIcon } from '@assets/svgs/help-text-icon-financial.svg';
import { ReactComponent as VeryHighIcon } from '@assets/svgs/help-text-icon-very-high.svg';
import { ReactComponent as HighIcon } from '@assets/svgs/help-text-icon-high.svg';
import { ReactComponent as MediumIcon } from '@assets/svgs/help-text-icon-medium.svg';
import { ReactComponent as LowIcon } from '@assets/svgs/help-text-icon-low.svg';
import { ReactComponent as VeryLowIcon } from '@assets/svgs/help-text-icon-very-low.svg';
import { HelpTextIconType } from '@api/types/case-impact-type/case-impact-type.option';

interface CaseImpactHelpTextImageProps {
  type?: HelpTextIconType;
}

export default function CaseImpactHelpTextImage({
  type,
}: CaseImpactHelpTextImageProps) {
  switch (type) {
    case 'VeryHigh':
      return <VeryHighIcon />;
    case 'High':
      return <HighIcon />;
    case 'Medium':
      return <MediumIcon />;
    case 'Low':
      return <LowIcon />;
    case 'VeryLow':
      return <VeryLowIcon />;
    default:
      return <FinancialIcon />;
  }
}

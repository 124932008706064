import { Palette } from '@mui/material';
import { Components } from '@mui/material/styles/components';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    // 'nu-contained': true;
  }
}

export const buttonStyles = (palette: Palette): Components['MuiButton'] => ({
  defaultProps: {
    size: 'medium',
  },
  variants: [
    {
      props: {},
      style: {
        minWidth: 'unset',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
        fontFamily: 'Usual',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.17000000178813934px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationKkipInk: 'none',
        borderRadius: '4px',
        // remove elevation
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    {
      props: { size: 'medium' },
      style: {
        padding: '5px 12px',
        minHeight: 36,
        fontSize: '14px',
        svg: {
          height: '14px',
          width: '14px',
        },
      },
    },
    {
      props: { size: 'large' },
      style: {
        minHeight: 36,
        padding: '10px 15px',
        fontSize: '14px',
        svg: {
          height: '14px',
          width: '14px',
        },
      },
    },
    {
      props: { variant: 'contained' },
      style: {
        textTransform: 'unset',
        backgroundColor: palette.mainBlue,
        color: palette.white.main,

        '&:hover': {
          backgroundColor: palette.mainBlueRollover,
        },

        '&:disabled,&.fake-disabled': {
          backgroundColor: palette.disabled,
          color: palette.disabledText,
        },
      },
    },
    {
      props: { variant: 'text' },
      style: {
        textTransform: 'unset',
        border: palette.border.dividers,
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        color: palette.mainBlue,

        '&:hover': {
          backgroundColor: 'transparent',
          border: palette.border.dividers,
          color: palette.mainBlueRollover,
        },

        '&:disabled,&.fake-disabled': {
          backgroundColor: 'transparent',
          color: palette.disabledText,
        },
      },
    },
    {
      props: { variant: 'outlined' },
      style: {
        textTransform: 'unset',
        backgroundColor: 'transparent',
        border: palette.border.dividers,
        borderColor: palette.mainBlue,
        color: palette.mainBlue,

        '&:hover': {
          backgroundColor: '#075A950D',
          borderColor: palette.mainBlueRollover,
          color: palette.mainBlueRollover,
        },

        '&:disabled,&.fake-disabled': {
          backgroundColor: 'transparent',
          borderColor: palette.disabledText,
          color: palette.disabledText,
        },
      },
    },
  ],
});

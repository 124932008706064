import { RcaNodeType } from '@store/rca-editor/types';
import { styled } from '@mui/material';
import { ANALYSIS_COMPLETE_BORDER_COLOR } from '@pages/app/rca/tabs/components/end-state-content/analysis-complete-info';
import { FURTHER_ANALYSIS_BORDER_COLOR } from '@pages/app/rca/tabs/components/end-state-content/further-analysis-info';

const NodeDefaultBody = '/svgs/node-default-body.svg';
const NodeEndStateBody = '/svgs/node-end-state-body.svg';
const NodeConnectionBody = '/svgs/node-connection-body.svg';
const NodeDefaultBorder = '/svgs/node-default-border.svg';
const NodeEndStateBorder = '/svgs/node-end-state-border.svg';
const NodeConnectionBorder = '/svgs/node-connection-border.svg';
const NodeDefaultSelectedBorder = '/svgs/node-default-selected-border.svg';
const NodeEndStateSelectedBorder = '/svgs/node-end-state-selected-border.svg';
const NodeConnectionSelectedBorder =
  '/svgs/node-connection-selected-border.svg';
const NodeDefaultHoverBorder = '/svgs/node-default-border-hover.svg';
const NodeEndStateHoverBorder = '/svgs/node-end-state-border-hover.svg';
const NodeConnectionHoverBorder = '/svgs/node-connection-border-hover.svg';

const StyleMap: Record<
  string,
  {
    body: string;
    border: string;
    selectedBorder: string;
    hoverBorder: string;
  }
> = {
  [RcaNodeType.default]: {
    body: NodeDefaultBody,
    border: NodeDefaultBorder,
    selectedBorder: NodeDefaultSelectedBorder,
    hoverBorder: NodeDefaultHoverBorder,
  },
  [RcaNodeType.endState]: {
    body: NodeEndStateBody,
    border: NodeEndStateBorder,
    selectedBorder: NodeEndStateSelectedBorder,
    hoverBorder: NodeEndStateHoverBorder,
  },
  [RcaNodeType.connection]: {
    body: NodeConnectionBody,
    border: NodeConnectionBorder,
    selectedBorder: NodeConnectionSelectedBorder,
    hoverBorder: NodeConnectionHoverBorder,
  },
};

const FallbackStyle = StyleMap[RcaNodeType.default];

interface StyledNodeProps {
  highlightColor?: string;
  outlineColor?: string;
  nodeType: RcaNodeType;
  isSelected?: boolean;
  isConnection?: boolean;
  isCompleteEndState?: boolean;
  isFurtherAnalysisRequired?: boolean;
  isDragging: boolean;
}

function getCursor(isDragging: boolean, draggable?: boolean | string) {
  if (isDragging) {
    return 'grabbing';
  }
  if (draggable) {
    return 'grab';
  }

  return 'default';
}

export const StyledNode = styled('div')<StyledNodeProps>(
  ({
    theme,
    outlineColor,
    highlightColor,
    nodeType,
    isSelected,
    isConnection,
    isCompleteEndState,
    isFurtherAnalysisRequired,
    isDragging,
    draggable,
  }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    filter: 'drop-shadow(0px 4px 16px #00000026)',
    cursor: getCursor(isDragging, draggable),
    pointerEvents: isDragging ? 'none' : undefined,

    '.node-actions-container': {
      cursor: getCursor(isDragging, draggable),
    },

    '.node-content': {
      cursor: getCursor(isDragging, draggable),
      overflow: 'visible',
    },

    ...(isConnection && {
      filter: 'none',
      '.node-content .content-text': {
        fontStyle: 'italic',
      },
    }),

    '.node-background': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      mask: `url(${
        StyleMap[nodeType]?.body ?? FallbackStyle.body
      }) center center no-repeat`,
      maskSize: 'contain',
      backgroundColor: highlightColor ?? 'white',
      pointerEvents: 'none',

      ...(isConnection && {
        backgroundColor: '#E5E6EB',
      }),
    },

    '.node-border': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      mask: `url(${
        StyleMap[nodeType]?.border ?? FallbackStyle.border
      }) center center no-repeat`,
      maskSize: 'contain',
      backgroundColor: outlineColor ?? theme.palette.blue.dark,
      pointerEvents: 'none',

      ...(isCompleteEndState &&
        !isSelected && {
          backgroundColor: ANALYSIS_COMPLETE_BORDER_COLOR,
        }),

      ...(isFurtherAnalysisRequired &&
        !isSelected && {
          backgroundColor: FURTHER_ANALYSIS_BORDER_COLOR,
        }),

      ...(isSelected && {
        mask: `url(${
          StyleMap[nodeType]?.selectedBorder ?? FallbackStyle.selectedBorder
        }) center center no-repeat`,
      }),
      ...(isConnection && {
        backgroundColor: '#D4D7DF',
        mask: `url(${
          StyleMap[nodeType]?.selectedBorder ?? FallbackStyle.selectedBorder
        }) center center no-repeat`,
      }),
    },

    '&:hover': {
      '.node-border': {
        ...(!isSelected && {
          mask: `url(${
            StyleMap[nodeType]?.hoverBorder ?? FallbackStyle.hoverBorder
          }) center center no-repeat`,
        }),
        ...(isCompleteEndState &&
          !isSelected && {
            backgroundColor: ANALYSIS_COMPLETE_BORDER_COLOR,
          }),
        ...(isFurtherAnalysisRequired &&
          !isSelected && {
            backgroundColor: FURTHER_ANALYSIS_BORDER_COLOR,
          }),
        ...(isConnection && {
          backgroundColor: '#C1C3C9',
          mask: `url(${
            StyleMap[nodeType]?.selectedBorder ?? FallbackStyle.selectedBorder
          }) center center no-repeat`,
        }),
      },
    },

    '&:hover.readonly': {
      '.node-border': {
        mask: `url(${
          StyleMap[nodeType]?.border ?? FallbackStyle.border
        }) center center no-repeat`,
      },
    },

    '&.disproved .node-border ': {
      mask: `url(${
        StyleMap[nodeType]?.selectedBorder ?? FallbackStyle.selectedBorder
      }) center center no-repeat`,
    },

    '&.disproved .node-content p': {
      color: '#6C6C6C',
    },

    '&.faded': {
      opacity: 0.4,
    },

    '&.readonly .node-border': {
      backgroundColor: 'transparent',
    },
    '&.readonly .node-actions-container': {
      display: 'none',
    },
    '&.readonly .meta-connection': {
      display: 'none',
    },
  })
);

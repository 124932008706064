import { api } from '@api/api';
import { NestedOption } from '@api/types/nested-option';

const caseImpactTypeCategoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseImpactTypeCategoryOptions: build.query<NestedOption[], number>({
      query: (caseId: number) => ({
        url: 'caseImpactTypeCategory/options',
        method: 'GET',
        params: {
          caseId,
        },
      }),
      providesTags: ['CaseImpact'],
    }),
  }),
});

export const { useGetCaseImpactTypeCategoryOptionsQuery } =
  caseImpactTypeCategoryApi;

import { isApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useState } from 'react';
import useBusyAction from '@hooks/use-busy-action-hook';
import {
  useDisableCaseTypeCategoryMutation,
  useEnableCaseTypeCategoryMutation,
  useGetCompanyCaseTypeCategoriesQuery,
} from '@api/endpoints/company/company-case-type-category.api';
import { CaseTypeCategoryResource } from '@api/types/company/company-case-type-category/case-type-category.resource';

export default function useCompanyCaseTypes() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const [searchText, setSearchText] = useState<string>();

  const {
    data: categories,
    isLoading: loadingCategories,
    isFetching: fetchingCategories,
  } = useGetCompanyCaseTypeCategoriesQuery(searchText);

  const [enableCategory] = useEnableCaseTypeCategoryMutation();
  const [disableCategory] = useDisableCaseTypeCategoryMutation();

  const [toggleCategory] = useBusyAction(
    async (category: CaseTypeCategoryResource) => {
      try {
        if (!category.disabled) {
          await disableCategory(category.caseTypeCategoryId);

          showSuccessMessage(
            `You have successfully disabled category '${category.name}'`
          );
        } else {
          await enableCategory(category.caseTypeCategoryId);
          showSuccessMessage(
            `You have successfully enabled category '${category.name}'`
          );
        }
      } catch (error) {
        if (isApiError(error)) {
          showErrorMessage(error.message);
        }
      }
    }
  );

  return {
    categories,
    loadingCategories,
    toggleCategory,
    setSearchText,
    searchText,
    fetchingCategories,
  };
}

import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import Column from '@components/layout-util-components/column';
import { Button, Typography } from '@mui/material';
import { Divider } from '@components/layout-util-components/divider';
import { Gap } from '@components/layout-util-components/gap';
import WCTTextField from '@components/input/text-field';
import useField from '@hooks/use-field-hook';
import {
  lessThan,
  required,
  ruleForEach,
  validatorSelector,
} from '@util/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/pro-light-svg-icons';
import ActionsRow from '@components/layout-util-components/actions-row';
import React, { Fragment, useMemo } from 'react';
import Spacer from '@components/layout-util-components/spacer';
import { Option } from '@api/types/option';
import Row from '@components/layout-util-components/row';
import useBusyAction from '@hooks/use-busy-action-hook';
import { isApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { invalidation } from '@api/cache-util';
import AsyncButton from '@components/buttons/async-button';
import { useCreateCompanyCaseImpactMutation } from '@api/endpoints/company/company-case-impact-type.api';
import {
  CreateCaseImpactType,
  CreateCompanyCaseImpactRequest,
} from '@api/types/company/company-case-impact/create-company-case-impact.request';
import WCTSelectField from '@components/input/select-field';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { CaseImpactTypeMetricsResource } from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import { CaseTypeMetricInput } from './case-type-metric-input';
import WCTSwitchField from '@components/input/switch-field';
import { ReactComponent as CircleQuestion } from '@assets/svgs/circle-question.svg';

interface HelpTextItem {
  caseImpactTypeMetricOptionId?: number;
  helpText: string;
}

interface CreateImpactItem {
  name?: string;
  caseImpactTypeMetricId?: number;
  guidance?: boolean;
  helpText?: HelpTextItem[];
}

export interface AddImpactModalProps extends ModalProps<void> {
  categoryId: number;
  metricOptions: CaseImpactTypeMetricsResource[];
}

function AddImpactModal({
  onClose,
  categoryId,
  metricOptions,
}: AddImpactModalProps) {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const [createImpact] = useCreateCompanyCaseImpactMutation();

  const impacts = useField<Array<CreateImpactItem>>(
    [
      ruleForEach(
        validatorSelector<CreateImpactItem>('name', [
          required(),
          lessThan(200, 'Enter 200 characters or less'),
        ])
      ),
      ruleForEach(
        validatorSelector<CreateImpactItem>('caseImpactTypeMetricId', [
          required(),
        ])
      ),
      ruleForEach(
        validatorSelector<CreateImpactItem>('helpText', [
          validatorSelector<HelpTextItem>('helpText', [
            lessThan(150, 'Enter 150 characters or less'),
          ]),
        ])
      ),
    ],
    useMemo(() => [{}], [])
  );

  const { validateAll } = useFieldsWatcher([impacts]);

  const hasImpacts = impacts.value.length > 0;

  const [onSave, isBusy] = useBusyAction(async () => {
    try {
      if (!validateAll()) {
        return;
      }

      await createImpact({
        caseImpactTypeCategoryId: categoryId,
        caseImpactTypes: impacts.value.map((caseImpact) => ({
          name: caseImpact.name,
          caseImpactTypeMetricId: caseImpact.caseImpactTypeMetricId,
          helpText: caseImpact.guidance
            ? caseImpact.helpText?.map((h) => ({
                caseImpactTypeMetricOptionId: h.caseImpactTypeMetricOptionId,
                helpText: h.helpText,
              }))
            : [],
        })) as CreateCaseImpactType[],
      }).unwrap();

      await invalidation('CaseImpact');

      showSuccessMessage('Impact added successfully');

      onClose();
    } catch (e) {
      if (isApiError<CreateCompanyCaseImpactRequest>(e)) {
        const errors = e.errors as any;
        showErrorMessage(
          errors?.name ?? e.errors?.caseImpactTypeCategoryId ?? e.message
        );
        impacts.setError(e.errors);
      }
    }
  });

  const onMetricOptionChange = (v: number, i) => {
    impacts.set(
      impacts.value.map((t, j) =>
        i === j ? { ...t, caseImpactTypeMetricId: v, helpText: [] } : t
      )
    );
  };

  return (
    <Column>
      <Typography>Add Impact</Typography>
      <Gap size={20} />
      <Column>
        {impacts.value.map((impact, i) => {
          const metricOption = metricOptions.find(
            (x) => x.caseImpactTypeMetricId === impact.caseImpactTypeMetricId
          );
          return (
            <Fragment key={i + 1}>
              {i > 0 && <Divider compact addMargin />}
              <Typography variant="body1">Impact Title*</Typography>
              <Gap size={10} />
              <Row>
                <WCTTextField
                  name={`impact_${i}_title`}
                  label="Impact Title"
                  value={impact.name}
                  onChange={(value) => {
                    impacts.set(
                      impacts.value.map((t, j) =>
                        i === j ? { ...t, name: value } : t
                      )
                    );
                  }}
                  error={impacts.errors?.name?.[i]}
                />
                <Gap size={10} />
                <Button
                  variant="text"
                  onClick={() => {
                    const newImpacts =
                      impacts?.value?.filter((_, j) => i !== j) ?? [];
                    if (newImpacts.length === 0) {
                      newImpacts.push({});
                    }
                    impacts.set(newImpacts);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Row>
              <Gap size={10} />
              <CaseTypeMetricInput
                value={impact.caseImpactTypeMetricId}
                metricOptions={metricOptions}
                onChange={(v: number) => onMetricOptionChange(v, i)}
                error={impacts.errors?.caseImpactTypeMetricId?.[i]}
              />
              <Gap size={10} />
              {impact.caseImpactTypeMetricId && (
                <WCTSwitchField
                  label="Give help guidance to users on what each value represents within your organisation"
                  name={`impact_${i}_guidance`}
                  value={!!impact.guidance}
                  error={impacts.errors?.guidance?.[i]}
                  onChange={(value) => {
                    impacts.set(
                      impacts.value.map((t, j) =>
                        i === j ? { ...t, guidance: value } : t
                      )
                    );
                  }}
                />
              )}
              {impact.guidance && (
                <Column>
                  <Gap size={20} />
                  <Typography variant="body1">Help Guidance</Typography>
                  <Row>
                    <Typography
                      variant="body2"
                      style={{ padding: '2px 8px 8px 0px' }}
                    >
                      This guidance will appear for the option on hover of a
                      question mark icon
                    </Typography>
                    <CircleQuestion width={14} height={14} />
                  </Row>

                  {(((metricOption && metricOption.options) ?? []).length > 0
                    ? metricOption!.options
                    : [
                        {
                          label: metricOptions.find(
                            (x) =>
                              x.caseImpactTypeMetricId ===
                              impact.caseImpactTypeMetricId
                          )?.name,
                          id: undefined,
                        },
                      ]
                  ).map((metricOption) => {
                    const ht = impact.helpText?.find(
                      (x) => x.caseImpactTypeMetricOptionId === metricOption.id
                    );

                    return (
                      <>
                        <Gap size={10} />
                        <WCTTextField
                          key={metricOption.id}
                          name={`impact_${i}_help_text_${impact.caseImpactTypeMetricId}`}
                          label={`Help Guidance for ${metricOption.label}`}
                          value={ht?.helpText}
                          maxLength={150}
                          showCharCount
                          onChange={(value) => {
                            const existingHelpText = impact.helpText ?? [];
                            if (!ht) {
                              existingHelpText.push({
                                caseImpactTypeMetricOptionId: metricOption.id,
                                helpText: value,
                              });
                            } else {
                              existingHelpText.find(
                                (x) =>
                                  x.caseImpactTypeMetricOptionId ===
                                  metricOption.id
                              )!.helpText = value;
                            }
                            impacts.set(
                              impacts.value.map((t, j) =>
                                i === j
                                  ? { ...t, helpText: [...existingHelpText] }
                                  : t
                              )
                            );
                          }}
                        />
                      </>
                    );
                  })}
                </Column>
              )}
            </Fragment>
          );
        })}
      </Column>
      {hasImpacts && <Divider compact addMargin />}
      <Button
        variant="text"
        sx={{ alignSelf: 'flex-start' }}
        onClick={() => {
          impacts.set([...impacts.value, '']);
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        {hasImpacts ? 'Add another Impact' : 'Add Impact'}
      </Button>
      <Gap size={20} />
      <ActionsRow>
        <Button variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <Spacer />
        <AsyncButton onClick={onSave} isBusy={isBusy}>
          Save
        </AsyncButton>
      </ActionsRow>
    </Column>
  );
}

export default withModal(AddImpactModal);

import { Palette } from '@mui/material';
import { Components } from '@mui/material/styles/components';

declare module '@mui/material/IconButton' {}

export const iconButtonStyles = (
  palette: Palette
): Components['MuiIconButton'] => ({
  defaultProps: {
    size: 'medium',
  },
  variants: [
    {
      props: {},
      style: {
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          color: palette.disabledText,
          svg: {
            color: palette.disabledText,
          },
        },
      },
    },
  ],
});

import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import Column from '@components/layout-util-components/column';
import { Button, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import WCTTextField from '@components/input/text-field';
import useField from '@hooks/use-field-hook';
import { lessThan, required } from '@util/validators';
import ActionsRow from '@components/layout-util-components/actions-row';
import React from 'react';
import Spacer from '@components/layout-util-components/spacer';
import WCTSwitchField from '@components/input/switch-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { ThemeResource } from '@api/types/theme/theme.resource';
import {
  useDeleteThemeMutation,
  useDisableThemeMutation,
  useEnableThemeMutation,
  useUpdateThemeMutation,
} from '@api/endpoints/company/company-theme.api';
import { usePageAlertVariants } from '@components/alerts';
import useBusyAction from '@hooks/use-busy-action-hook';
import { isApiError } from '@api/types/api-error';
import { UpdateThemeRequest } from '@api/types/theme/update-theme.request';
import { invalidation } from '@api/cache-util';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import AsyncButton from '@components/buttons/async-button';
import useRolloverInfo from '@components/rollover-info/use-rollover-info';
import RolloverInfo from '@components/rollover-info/rollover-info';

export interface EditThemeModalProps extends ModalProps<void> {
  theme: ThemeResource;
  isCategory: boolean;
}

function EditThemeModal({
  theme,
  onClose,
  isCategory: forceIsCategory,
}: EditThemeModalProps) {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const [updateTheme] = useUpdateThemeMutation();
  const [deleteTheme] = useDeleteThemeMutation();
  const [disableTheme] = useDisableThemeMutation();
  const [enableTheme] = useEnableThemeMutation();

  const name = useField<string>(
    [required(), lessThan(200, 'Enter 200 characters or less')],
    theme.name
  );
  const isActive = useField<boolean>([required()], !theme.disabled);

  const { validateAll, isDirty } = useFieldsWatcher([name, isActive]);

  const isCategory =
    forceIsCategory ||
    theme.childThemes == null ||
    theme.childThemes.length > 0;

  const itemType = isCategory ? 'Category' : 'Theme';
  const deleteButtonRollOver = useRolloverInfo(
    isCategory
      ? 'This Category has Themes in use and so can no longer be deleted'
      : 'This Theme is in use and so can no longer be deleted',
    theme.hasRCA
  );

  const [onSave, isSaving] = useBusyAction(async () => {
    if (!isDirty) {
      onClose();
      return;
    }

    try {
      if (theme.hasRCA) {
        if (!isActive.validate(true)) {
          return;
        }

        if (!isActive.value) {
          await disableTheme(theme.themeId).unwrap();
        } else {
          await enableTheme(theme.themeId).unwrap();
        }
      } else {
        if (!validateAll()) {
          return;
        }

        await updateTheme({
          themeId: theme.themeId,
          name: name.value,
          disable: !isActive.value,
        }).unwrap();
      }

      await invalidation('CompanyTheme');

      showSuccessMessage(`${itemType} updated successfully`);

      onClose();
    } catch (error) {
      if (isApiError<UpdateThemeRequest>(error)) {
        showErrorMessage(error.errors?.themeId ?? error.message);
        name.setError(error.errors?.name);
      }
    }
  });

  const [onDeleteTheme, isDeleting] = useBusyAction(async () => {
    try {
      await deleteTheme(theme.themeId).unwrap();

      await invalidation('CompanyTheme');

      showSuccessMessage(`${itemType} deleted successfully`);

      onClose();
    } catch (error) {
      if (isApiError(error)) {
        const errors = error.errors as any;
        showErrorMessage(errors?.themeId ?? error.message);
      }
    }
  });
  return (
    <>
      <Column>
        <Typography>Edit {itemType}</Typography>
        <Gap size={20} />
        <WCTTextField
          name="theme_name"
          label={`Rename ${itemType}`}
          value={name.value}
          onChange={name.set}
          error={name.error}
          readonly={theme.hasRCA}
          helperText={
            theme.hasRCA
              ? isCategory
                ? 'This Category has Themes in use and so can no longer be renamed'
                : 'This Theme is in use and so can no longer be renamed'
              : undefined
          }
        />
        <Gap size={13} />
        <WCTSwitchField
          label="Active"
          value={isActive.value}
          onChange={isActive.set}
          error={isActive.error}
          sx={{ alignSelf: 'flex-end' }}
        />
        <Gap size={20} />
        <ActionsRow>
          <Button variant="outlined" onClick={() => onClose()}>
            Cancel
          </Button>
          <Spacer />
          <AsyncButton
            ref={deleteButtonRollOver.ref}
            variant="outlined"
            onClick={() => {
              if (!theme.hasRCA) {
                onDeleteTheme();
              }
            }}
            className={theme.hasRCA ? 'fake-disabled' : ''}
            disabled={isSaving}
            isBusy={isDeleting}
          >
            <FontAwesomeIcon icon={faTrash} />
            Delete {itemType}
          </AsyncButton>
          <AsyncButton onClick={onSave} disabled={isDeleting} isBusy={isSaving}>
            Save
          </AsyncButton>
        </ActionsRow>
      </Column>
      <RolloverInfo {...deleteButtonRollOver} />
    </>
  );
}

export default withModal(EditThemeModal);

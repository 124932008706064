import { alpha, darken, Palette } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const chipStyles = (palette: Palette): Components['MuiChip'] => ({
  styleOverrides: {},
  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        padding: '8px 15px',
        minHeight: 36,
        gap: 5,
        borderRadius: 30,
        borderColor: palette.mainBlue,
        color: palette.blackBlack,
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '18.59px',
        letterSpacing: '0.17000000178813934px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',

        '&:hover': {
          backgroundColor: alpha(palette.mainBlue as string, 0.05),
        },

        '.svg-inline--fa': {
          color: palette.mainBlue,
          fontSize: '14px',
          width: 35,
          minWidth: 35,
          '&:hover': {
            color: darken(palette.mainBlue as string, 0.2),
          },
        },

        '&.MuiChip-deletable': {
          paddingRight: 0,
          cursor: 'pointer',
        },

        '&.Mui-disabled,&.fake-disabled': {
          borderColor: '#B8B8B8',
          backgroundColor: 'transparent',
          color: '#B8B8B8',
          opacity: 1.0,

          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: '#B8B8B8',
            cursor: 'default',
          },

          '.svg-inline--fa': {
            color: '#B8B8B8',

            '&:hover': {
              color: '#B8B8B8',
              cursor: 'default',
            },
          },
        },

        '*': {
          padding: '0px !important',
          margin: '0px !important',
        },
      },
    },
  ],
});

import { api } from '@api/api';
import { NestedOption } from '@api/types/nested-option';

const caseTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseTypeOptions: build.query<NestedOption[], { caseId?: number }>({
      query: (params) => ({
        url: 'caseTypeCategory/options',
        params,
      }),
      providesTags: ['CaseType'],
    }),
  }),
});

export const { useGetCaseTypeOptionsQuery } = caseTypeApi;

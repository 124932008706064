import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import Column from '@components/layout-util-components/column';
import { Button, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import WCTTextField from '@components/input/text-field';
import useField from '@hooks/use-field-hook';
import { lessThan, required } from '@util/validators';
import ActionsRow from '@components/layout-util-components/actions-row';
import Spacer from '@components/layout-util-components/spacer';
import WCTSwitchField from '@components/input/switch-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { usePageAlertVariants } from '@components/alerts';
import useBusyAction from '@hooks/use-busy-action-hook';
import { isApiError } from '@api/types/api-error';
import { invalidation } from '@api/cache-util';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import AsyncButton from '@components/buttons/async-button';
import { CaseImpactCategoryResource } from '@api/types/case-impact-type/case-impact-category.resource';
import {
  useDisableCaseImpactCategoryMutation,
  useEnableCaseImpactCategoryMutation,
  useRemoveCaseImpactCategoryMutation,
  useUpdateCaseImpactCategoryMutation,
} from '@api/endpoints/company/company-case-impact-type-category.api';
import { UpdateCaseImpactCategoryRequest } from '@api/types/case-impact-type/update-case-impact-category.request';
import { UpdateCompanyCaseImpactRequest } from '@api/types/company/company-case-impact/update-company-case-impact.request';
import useRolloverInfo from '@components/rollover-info/use-rollover-info';
import RolloverInfo from '@components/rollover-info/rollover-info';

export interface EditImpactCategoryModalProps extends ModalProps<void> {
  impact: CaseImpactCategoryResource;
}

function EditImpactCategoryModal({
  impact,
  onClose,
}: EditImpactCategoryModalProps) {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const [updateCategory] = useUpdateCaseImpactCategoryMutation();
  const [enableCategory] = useEnableCaseImpactCategoryMutation();
  const [disableCategory] = useDisableCaseImpactCategoryMutation();

  const [deleteCategory] = useRemoveCaseImpactCategoryMutation();

  const name = useField<string>(
    [required(), lessThan(200, 'Enter 200 characters or less')],
    impact.name
  );
  const isActive = useField<boolean>([required()], !impact.disabled);

  const { validateAll, isDirty } = useFieldsWatcher([name, isActive]);

  const itemType = 'Category';
  const deleteButtonRollOver = useRolloverInfo(
    'This Category has impacts in use and so can no longer be deleted',
    impact.hasRCA
  );

  const [onSave, isSaving] = useBusyAction(async () => {
    if (!isDirty) {
      onClose();
      return;
    }

    try {
      if (impact.hasRCA) {
        if (!isActive.validate(true)) {
          return;
        }

        if (!isActive.value) {
          await disableCategory(impact.caseImpactTypeCategoryId).unwrap();
        } else {
          await enableCategory(impact.caseImpactTypeCategoryId).unwrap();
        }
      } else {
        if (!validateAll()) {
          return;
        }

        await updateCategory({
          caseImpactTypeCategoryId: impact.caseImpactTypeCategoryId,
          name: name.value,
          disable: !isActive.value,
        }).unwrap();
      }

      await invalidation('CaseImpact');

      showSuccessMessage(`${itemType} updated successfully`);

      onClose();
    } catch (error) {
      if (isApiError<UpdateCaseImpactCategoryRequest>(error)) {
        showErrorMessage(
          error.errors?.disable ??
            error.errors?.caseImpactTypeCategoryId ??
            error.message
        );
        name.setError(error.errors?.name);
      } else if (isApiError<UpdateCompanyCaseImpactRequest>(error)) {
        const errors = error.errors as any;
        showErrorMessage(
          errors?.caseImpactTypeCategoryId ??
            errors?.caseImpactTypeId ??
            error.message
        );

        name.setError(errors?.name);
      }
    }
  });

  const [onDelete, isDeleting] = useBusyAction(async () => {
    try {
      await deleteCategory(impact.caseImpactTypeCategoryId).unwrap();

      await invalidation('CaseImpact');

      showSuccessMessage(`${itemType} deleted successfully`);

      onClose();
    } catch (error) {
      if (isApiError(error)) {
        const errors = error.errors as any;
        showErrorMessage(
          errors?.caseImpactTypeCategoryId ??
            errors?.caseImpactTypeId ??
            error.message
        );
      }
    }
  });
  return (
    <>
      <Column>
        <Typography>Edit {itemType}</Typography>
        <Gap size={20} />
        <WCTTextField
          name="impact_name"
          label={`Rename ${itemType}`}
          value={name.value}
          onChange={name.set}
          error={name.error}
          readonly={impact.hasRCA}
          helperText={
            impact.hasRCA
              ? 'This Category has Impacts in use and so can no longer be renamed'
              : undefined
          }
        />
        <Gap size={13} />
        <WCTSwitchField
          label="Active"
          value={isActive.value}
          onChange={isActive.set}
          error={isActive.error}
          sx={{ alignSelf: 'flex-end' }}
        />
        <Gap size={20} />
        <ActionsRow>
          <Button variant="outlined" onClick={() => onClose()}>
            Cancel
          </Button>
          <Spacer />
          <AsyncButton
            ref={deleteButtonRollOver.ref}
            variant="outlined"
            onClick={() => {
              if (!impact.hasRCA) {
                onDelete();
              }
            }}
            className={impact.hasRCA ? 'fake-disabled' : ''}
            disabled={isSaving}
            isBusy={isDeleting}
          >
            <FontAwesomeIcon icon={faTrash} />
            Delete {itemType}
          </AsyncButton>
          <AsyncButton onClick={onSave} disabled={isDeleting} isBusy={isSaving}>
            Save
          </AsyncButton>
        </ActionsRow>
      </Column>
      <RolloverInfo {...deleteButtonRollOver} />
    </>
  );
}

export default withModal(EditImpactCategoryModal);

import { Button, ButtonProps, styled } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';
import { LoadingIndicator } from '@components/loading-indicator';

interface Props {
  isBusy: boolean;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const StyledButton = styled(Button)<Props>(
  ({ theme: { palette }, isBusy }) => ({
    position: 'relative',
    backgroundColor: isBusy ? palette.disabled : undefined,
    color: isBusy ? palette.disabledText : undefined,
  })
);
const AsyncButton = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  function (
    {
      children,
      isBusy,
      disabled,
      className,
      onClick,
      variant = 'contained',
      size = 'medium',
    }: PropsWithChildren<Props>,
    ref
  ) {
    return (
      <StyledButton
        ref={ref}
        variant={variant}
        size={size}
        isBusy={isBusy}
        disabled={disabled || isBusy}
        className={className}
        onClick={onClick}
      >
        {children}
        <LoadingIndicator show={isBusy} />
      </StyledButton>
    );
  }
);

export default AsyncButton;

import {
  alpha,
  Button,
  ButtonProps,
  Link,
  Menu,
  MenuItem,
  MenuProps,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';
import AppLogo from '@assets/svgs/app-logo.svg';
import { ReactComponent as HomeIcon } from '@assets/svgs/home.svg';
import { ReactComponent as TasksAltIcon } from '@assets/svgs/tasks-alt.svg';
import { ReactComponent as GlassesIcon } from '@assets/svgs/glasses-light.svg';
import { ReactComponent as FileIcon } from '@assets/svgs/file.svg';
import { ReactComponent as SolutionIcon } from '@assets/svgs/solution.svg';
import { ReactComponent as HelpRingIcon } from '@assets/svgs/help-ring.svg';
import { useMatch, useNavigate } from 'react-router-dom';
import MenuItemRow from '@components/menu-items/app/menu-item-row';
import { PageAlertDisplay, usePageAlertVariants } from '@components/alerts';
import { CaseResource } from '@api/types/case/case.resource';
import { InlineBoxSkeleton } from '@components/skeletons';
import useDirtyGuard from '@hooks/use-dirty-guard';
import React, { useEffect, useRef } from 'react';
import { useGetCaseTotalsQuery } from '@api/endpoints/case.api';
import { useAppDispatch, useAppSelector } from '@store/store';
import { selectIsLensesSlideOutOpen } from '@store/reports/reports-selectors';
import { setOpenLensesSlideOut } from '@store/reports/reports-slice';
import { DEFAULT_PAGE_ALERT_MAX_TIME } from '@components/alerts/page-alert-display';
import { Gap } from '@components/layout-util-components/gap';
import { useIsPath } from '@util/path-util';
import {
  toggleHealthScorePanelOpen,
  toggleHelpPanelOpen,
} from '@store/rca-editor/rca-editor-slice';
import { RcaUtil } from '@util/rca-util';
import MenuItemProfile from '@components/menu-items/app/menu-item-profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { truncateString } from '@util/string-util';
import {
  faBullseyePointer,
  faChevronDown,
} from '@fortawesome/pro-light-svg-icons';
import { useCasePermission } from '@hooks/case/use-case-permission';
import NotificationsIcon from '@components/notifications/notifications-icon';
import { toggleNotificationsPanel } from '@store/notifications/notifications-slice';
import { ReactComponent as RcaIndicatorIcon } from '@assets/svgs/analysis.svg';
import { selectIsNotificationsOpen } from '@store/notifications/selectors';

interface Props {
  caseDetail?: CaseResource;
  isOffline?: boolean;
}

const StyledResponsiveMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 250,
    '@media (max-width:1300px)': {
      minWidth: 250,
    },
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      padding: '8px 15px',
      svg: {
        fontSize: 18,
        width: '20px',
        color: theme.palette.text.secondary,
        marginRight: '15px',
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      '> div': {
        marginLeft: 'auto',
      },
    },
  },
}));

const StyledMenuButton = styled((props: ButtonProps) => (
  <Button aria-haspopup="true" variant="outlined" disableElevation {...props} />
))(({ theme }) => ({}));

const Container = styled('div', { target: 'rca-editor-app-bar' })(
  ({ theme: { palette } }) => ({
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    height: 80,
    backgroundColor: 'white',
    padding: '0 0 0 15px',
    zIndex: 1200,
    width: '100vw',
    borderBottom: '1px solid #D8DBE3',
    '@media (max-width:1500px)': {
      height: 70,
      padding: '0 0 0 10px',
    },

    '& > a': {
      width: 52,
      height: 52,
      display: 'block',
    },

    '.gap': {
      '@media (max-width:1500px)': {
        flex: '0 0 15px',
      },
    },

    '& > .vert-divider': {
      alignSelf: 'stretch',
      margin: '-15px 15px',
      backgroundColor: palette.common.grey30,
      width: 1,
      border: 'none',
      '&.lg': {
        margin: '-15px 20px',
        '@media (max-width:1500px)': {
          margin: '-15px 15px',
        },
      },
    },

    '.mobile-menu': {
      '@media (min-width:1200px)': {
        display: 'none',
      },
      button: {
        border: '2px solid #E0E0E0',
        height: 44,
        width: 350,
        '@media (max-width:1300px)': {
          width: 250,
        },
        textTransform: 'none',
        justifyContent: 'flex-start',
        color: '#000',
        borderRadius: '5px',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          border: '2px solid #FF6C00',
          backgroundColor: 'rgba(255, 108, 0, 0.04)',
        },
        svg: {
          margin: '0 5px',
        },
        '.MuiButton-endIcon': {
          marginLeft: 'auto',
        },
      },
    },

    '.menu-item-row': {
      height: 80,
      width: 'auto',
      background: 'none',
      border: '0',
      borderRadius: '0',
      color: palette.common.textDark,
      flexGrow: 0,
      textAlign: 'left',
      borderBottom: '3px solid rgba(255,255,255,0)',
      margin: '0 12px',
      gap: '10px',
      '&:hover': {
        background: 'none',
        borderBottom: `3px solid ${palette.common.grey30}`,
      },
      '& svg': {
        color: palette.common.textMid,
        height: '15px',
      },
      '&.selected': {
        borderBottom: `3px solid ${palette.primary.main}`,
        '& svg': {
          color: palette.common.textMid,
          '& path': {
            fill: palette.common.textMid,
          },
        },
      },

      '& .menu-item-descriptor p': {
        fontFamily: 'Usual',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.17px',
      },
      '@media (min-width:750px)': {
        padding: '10px 5px',
      },
      '@media (max-width:1199px)': {
        display: 'none',
      },
      '@media (min-width:1650px)': {
        padding: '10px 8px',
        margin: '0 12px',
        svg: {
          height: '16px',
        },
        '& .menu-item-descriptor p': {
          fontSize: '14px',
        },
      },
      '@media (min-width:1800px)': {
        padding: '10px 10px',
        margin: '0 15px',
        svg: {
          height: '18px',
        },
        '& .menu-item-descriptor p': {
          fontSize: '15px',
        },
      },
    },
    '> .menu-item-row': {
      '@media (max-width:1650px)': {
        height: 80,
        gap: 5,
        flexDirection: 'column',
        '& svg': {
          height: '22px',
        },
        '.menu-item-descriptor': {
          display: 'flex',
          gap: 5,
          alignItems: 'center',
          div: {
            fontSize: 9,
          },
        },
      },
      '@media (max-width:1500px)': {
        height: 70,
        padding: '14px 6px',
      },
      '@media (min-width:1501px)': {
        padding: '16px 10px',
        gap: 10,
      },
      '@media (min-width:1651px)': {
        '.menu-item-descriptor': {
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          alignItems: 'center',
          div: {
            fontSize: 9,
          },
        },
      },
    },
    '.btn.health': {
      marginLeft: 'auto',
    },
    '.btn .menu-item-row': {
      height: 44,
      borderRadius: 5,
      padding: '0 14px',
      border: `1px solid ${palette.common.grey40}`,
    },
    '.health .menu-item-row': {
      background: 'rgba(255,108,0,0.12)',
      border: `1px solid ${palette.warning.main}`,
      '@media (max-width:1540px)': {
        display: 'flex',
      },
      '@media (max-width:1500px)': {
        padding: '0 10px',
      },
      '& p': {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        lineHeight: 6,
      },
      '& .percentage': {
        color: palette.warning.main,
        fontSize: '18px',
        fontWeight: '600',
        '& span': {
          fontWeight: '400',
        },
      },
    },

    '.health.healthy .menu-item-row': {
      background: 'rgba(69,197,150,0.12)',
      border: `1px solid ${palette.success.main}`,
      '& .percentage': {
        color: palette.success.main,
      },
    },

    '.health.unhealthy .menu-item-row': {
      background: 'rgba(255,61,61,0.12)',
      border: `1px solid ${palette.error.main}`,
      '& .percentage': {
        color: palette.error.main,
      },
    },
    '.health.not-applicable .menu-item-row': {
      background: 'none',
      border: '1px solid #D8D8E3',
      '& .percentage': {
        color: '#636D73',
      },
    },
    '.lenses .menu-item-row': {
      border: `1px solid ${palette.blue.dark}`,
      gap: 0,
      margin: '0',
      color: palette.blue.dark,
      '& svg': {
        color: '#00223A',
      },
      '@media (max-width:1500px)': {
        padding: '0 10px',
      },
      '@media (max-width:1550px)': {
        display: 'flex',
      },
      '& p': {
        marginLeft: '6px',
        color: palette.blue.dark,
        span: {
          color: palette.blue.dark,
        },
      },
      '&:hover': {
        border: `1px solid ${palette.blue.dark}`,
        transition: 'all 0.2s ease-in-out',
        backgroundColor: 'rgba(0, 34, 58, 0.05)',
      },
    },
  })
);

const StyledCaseDetail = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  flexGrow: 1,
  paddingRight: 20,
  maxWidth: '450px',
  marginRight: 'auto',
  '@media (max-width:1500px)': {
    height: 70,
  },
  '@media (max-width:1799px)': {
    maxWidth: '400px',
    marginRight: '0',
  },
  '@media (max-width:1699px)': {
    maxWidth: '295px',
  },
  '@media (max-width:1599px)': {
    maxWidth: '350px',
  },
  '@media (max-width:1410px)': {
    display: 'none',
  },
  '& .title': {
    fontSize: '12px',
    textTransform: 'uppercase',
    color: palette.common.textMid,
    marginTop: '5px',
  },
  '& .subtitle': {
    fontSize: '15px',
    fontWeight: '400',
    color: palette.common.textDark,
    textDecoration: 'none',
  },
}));

const StyledSquareButton = styled('div')(({ theme: { palette } }) => ({
  width: 44,
  height: 44,
  borderRadius: 5,
  border: `1px solid ${palette.common.grey40}`,
  color: '#121212',
  textAlign: 'center',
  lineHeight: '3',
  '&:hover, &.active': {
    border: `1px solid ${palette.primary.main}`,
    transition: 'all 0.2s ease-in-out',
    backgroundColor: 'rgba(255, 108, 0, 0.04)',
    cursor: 'pointer',
  },
  '& svg': {
    color: '#00223A',
    height: '18px',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    fill: 'inherit',
  },
}));

const AvatarBox = styled('div')(({ theme: { palette } }) => ({
  width: '80px',
  height: '80px',
  padding: '17.5px',
  borderLeft: `1px solid ${palette.common.grey35}`,
  background: '#FFF9EF',
  '@media (max-width:1500px)': {
    width: '70px',
    height: '70px',
    padding: '12.5px',
  },
  '.menu-item-profile': {
    padding: 0,
    width: 'auto',
    height: 'auto',
    background: 'none',
  },
}));

const StyledIndicatorContainer = styled('div')(({ theme: { palette } }) => ({
  flex: '0 0 auto',
  background: palette.primary.main,
  width: 18,
  height: 18,
  borderRadius: '50%',
  fontSize: '9px',
  fontWeight: '600',
  lineHeight: '18px',
  letterSpacing: '0.15px',
  textAlign: 'center',
  color: palette.common.white,
}));

export default function RcaEditorAppBar({ caseDetail, isOffline }: Props) {
  const { showWarningMessage, hideAlert } = usePageAlertVariants();
  const dispatch = useAppDispatch();
  const appBarRef = useRef<HTMLDivElement>(null);
  const isLensesOpen = useAppSelector(selectIsLensesSlideOutOpen);
  const isNotificationOpen = useAppSelector(selectIsNotificationsOpen);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { confirmDirty } = useDirtyGuard();
  const { caseId } = caseDetail || {};

  const navigate = useNavigate();
  const { canChangeCaseDetails } = useCasePermission();

  const isChart = !!useMatch('/rca/tabs/:caseId/chart');
  const isTasks = !!useMatch('/rca/tabs/:caseId/tasks');
  const isImpacts = !!useMatch('/rca/tabs/:caseId/impacts');
  const isEvidence = !!useMatch('/rca/tabs/:caseId/evidence');
  const isSolutions = !!useMatch('/rca/tabs/:id/solutions');
  const isOverview =
    useIsPath([`/rca/tabs/${caseId}`, `/rca/tabs/${caseId}/overview`], {
      startsWith: true,
    }) &&
    [isChart, isTasks, isEvidence, isSolutions, isImpacts].every((x) => !x);

  const { data: totals } = useGetCaseTotalsQuery(caseId ?? -1, {
    skip: caseId == null,
  });

  const menuOptions = [
    {
      icon: <HomeIcon />,
      onClick: confirmDirty(() => {
        handleClose();
        navigate(`/rca/tabs/${caseDetail!.caseId}/overview`);
      }),
      isSelected: isOverview,
      label: 'Overview',
    },
    {
      icon: <FontAwesomeIcon icon={faBullseyePointer} />,
      onClick: confirmDirty(() => {
        handleClose();
        navigate(`/rca/tabs/${caseDetail!.caseId}/impacts`);
      }),
      indicatorContent: totals?.impacts,
      isSelected: isImpacts,
      label: 'Impacts',
    },
    {
      icon: <RcaIndicatorIcon width={28} height="auto" />,
      onClick: confirmDirty(() => {
        handleClose();
        navigate(`/rca/tabs/${caseDetail!.caseId}/chart`);
      }),
      isSelected: isChart,
      label: 'Analysis',
    },
    {
      icon: <FileIcon />,
      onClick: confirmDirty(() => {
        handleClose();
        navigate(`/rca/tabs/${caseDetail!.caseId}/evidence`);
      }),
      indicatorContent: totals?.evidence,
      isSelected: isEvidence,
      label: 'Evidence',
    },
    {
      icon: <SolutionIcon />,
      onClick: confirmDirty(() => {
        handleClose();
        navigate(`/rca/tabs/${caseDetail!.caseId}/solutions`);
      }),
      isSelected: isSolutions,
      indicatorContent: totals?.solutions,
      label: 'Solutions',
    },
    {
      icon: <TasksAltIcon />,
      onClick: confirmDirty(() => {
        handleClose();
        navigate(`/rca/tabs/${caseDetail!.caseId}/tasks`);
      }),
      indicatorContent: totals?.tasks,
      isSelected: isTasks,
      label: 'Tasks',
    },
  ];

  useEffect(() => {
    if (isOffline) {
      showWarningMessage(
        `You can still view the data, but you will not be able to save any changes. Click <a href="${window.location.href}">here</a> to reload.`,
        'You are currently offline'
      );
    } else {
      hideAlert();
    }
  }, [hideAlert, isOffline, showWarningMessage]);

  return (
    <>
      <Container ref={appBarRef}>
        <PageAlertDisplay
          maxTime={!isOffline ? DEFAULT_PAGE_ALERT_MAX_TIME : undefined}
          absolute
        />
        <Link href="/">
          <img className="logo" src={AppLogo} alt="logo" width="52" />
        </Link>
        <div className="vert-divider" />
        <StyledCaseDetail>
          <div>
            {caseDetail ? (
              <>
                {canChangeCaseDetails ? (
                  <Link
                    className="subtitle"
                    onClick={() => navigate(`/rca/edit/${caseDetail!.caseId}`)}
                  >
                    {truncateString(caseDetail.name, 25)}
                  </Link>
                ) : (
                  <Typography className="subtitle">
                    {caseDetail.name}
                  </Typography>
                )}
                <Typography className="title">
                  {caseDetail.caseAnalysisTypeName}
                </Typography>
              </>
            ) : (
              <>
                <Typography>
                  <Skeleton />
                </Typography>
                <Typography>
                  <Skeleton />
                </Typography>
              </>
            )}
          </div>
        </StyledCaseDetail>

        <div className="mobile-menu">
          {caseDetail ? (
            <>
              <StyledMenuButton
                id="customized-button"
                aria-controls={open ? 'customized-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                startIcon={menuOptions.find((x) => x.isSelected)?.icon}
                endIcon={<FontAwesomeIcon icon={faChevronDown} />}
              >
                {menuOptions.find((x) => x.isSelected)?.label}
              </StyledMenuButton>
              <StyledResponsiveMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {menuOptions.map((m, k) => (
                  <MenuItem key={k} onClick={m.onClick} disableRipple>
                    {m.icon}
                    {m.label}
                    {m.indicatorContent != null && m.indicatorContent > 0 && (
                      <StyledIndicatorContainer>
                        {m.indicatorContent}
                      </StyledIndicatorContainer>
                    )}
                  </MenuItem>
                ))}
              </StyledResponsiveMenu>
            </>
          ) : (
            <InlineBoxSkeleton width={80} />
          )}
        </div>
        {menuOptions.map((m, k) =>
          caseDetail ? (
            <MenuItemRow
              key={k}
              icon={m.icon}
              onClick={m.onClick}
              outline
              isSelected={m.isSelected}
              indicatorContent={m.indicatorContent}
            >
              {m.label}
            </MenuItemRow>
          ) : (
            <InlineBoxSkeleton width={80} />
          )
        )}
        <Gap size={32} />
        {totals?.healthScore != null ? (
          <>
            <div
              className={`btn health ${RcaUtil.getHealthStateFromScore(
                totals!.healthScore ?? -1
              )}`}
            >
              <MenuItemRow
                onClick={() => {
                  // navigate(`/rca/tabs/${caseId}/chart`);
                  dispatch(toggleHealthScorePanelOpen());
                }}
                outline
              >
                <Typography
                  variant="overline"
                  color="common.textMid"
                  style={{ fontSize: '10px' }}
                >
                  Health
                  <br />
                  Score
                </Typography>
                {totals!.healthScore < 0 ? (
                  <span className="percentage">N/A</span>
                ) : (
                  <span className="percentage">
                    {totals!.healthScore.toFixed(0)}
                    <span style={{ fontSize: '0.825em' }}>%</span>
                  </span>
                )}
              </MenuItemRow>
            </div>
            <div className="btn lenses">
              <MenuItemRow
                onClick={() => {
                  dispatch(setOpenLensesSlideOut(!isLensesOpen));
                }}
                icon={<GlassesIcon />}
              >
                <Typography
                  variant="overline"
                  color="common.textMid"
                  style={{ fontSize: '10px' }}
                >
                  Lenses
                </Typography>
              </MenuItemRow>
            </div>
          </>
        ) : (
          <>
            <InlineBoxSkeleton width={120} />
            <Gap size={15} />
            <InlineBoxSkeleton width={95} />
          </>
        )}

        <div className="vert-divider lg" />
        {caseDetail ? (
          <>
            <StyledSquareButton
              className={`nav ${isNotificationOpen ? 'active' : ''}`}
              onClick={() => dispatch(toggleNotificationsPanel())}
            >
              <NotificationsIcon applyTopMargin />
            </StyledSquareButton>
            {/* <Gap size={15} />
            <StyledSquareButton
              className="nav"
              onClick={() => dispatch(toggleHelpPanelOpen())}
            >
              <HelpRingIcon />
            </StyledSquareButton> */}
          </>
        ) : (
          <>
            <InlineBoxSkeleton width={32} />
            {/* <Gap size={15} />
            <InlineBoxSkeleton width={32} /> */}
          </>
        )}
        <Gap size={20} className="gap" />
        <AvatarBox>
          <MenuItemProfile hideInformation chevronRight />
        </AvatarBox>
      </Container>
    </>
  );
}

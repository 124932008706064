import {
  useDisableThemeMutation,
  useEnableThemeMutation,
  useGetThemesQuery,
} from '@api/endpoints/company/company-theme.api';
import { isApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useState } from 'react';
import useBusyAction from '@hooks/use-busy-action-hook';
import { ThemeResource } from '@api/types/theme/theme.resource';

export default function useCompanyThemes() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const [searchText, setSearchText] = useState<string>();

  const {
    data: themes,
    isLoading: loadingThemes,
    isFetching: fetchingThemes,
  } = useGetThemesQuery(searchText);

  const [enable] = useEnableThemeMutation();
  const [disable] = useDisableThemeMutation();

  const [toggleTheme] = useBusyAction(async (theme: ThemeResource) => {
    try {
      if (!theme.disabled) {
        await disable(theme.themeId);

        showSuccessMessage(
          `You have successfully disabled theme '${theme.name}'`
        );
      } else {
        await enable(theme.themeId);
        showSuccessMessage(
          `You have successfully enabled theme '${theme.name}'`
        );
      }
    } catch (error) {
      if (isApiError(error)) {
        showErrorMessage(error.message);
      }
    }
  });

  return {
    themes,
    loadingThemes,
    toggleTheme,
    setSearchText,
    searchText,
    fetchingThemes,
  };
}

import { api } from '@api/api';
import { CaseImpactCategoryResource } from '@api/types/case-impact-type/case-impact-category.resource';
import { makeError } from '@api/types/api-error';
import { CreateCaseImpactCategoryRequest } from '@api/types/case-impact-type/create-case-impact-category.request';
import { UpdateCaseImpactCategoryRequest } from '@api/types/case-impact-type/update-case-impact-category.request';
import { maybeUpdateCache } from '@api/cache-util';

const caseImpactCategoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyCaseImpactCategories: build.query<
      CaseImpactCategoryResource[],
      string | undefined
    >({
      query: (searchText) => ({
        url: 'company/caseImpactTypeCategory',
        method: 'GET',
        params: {
          searchText,
        },
      }),
      providesTags: (response) =>
        response
          ? [
              'CaseImpact',
              ...response.map((data) => ({
                id: data.caseImpactTypeCategoryId,
                type: 'CaseImpact' as const,
              })),
            ]
          : [],
    }),
    createCaseImpactCategory: build.mutation<
      void,
      CreateCaseImpactCategoryRequest
    >({
      query: (body) => ({
        url: 'company/caseImpactTypeCategory',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateCaseImpactCategoryRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['CaseImpact'] : []),
    }),
    updateCaseImpactCategory: build.mutation<
      void,
      UpdateCaseImpactCategoryRequest
    >({
      query: (body) => ({
        url: 'company/caseImpactTypeCategory',
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<UpdateCaseImpactCategoryRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['CaseImpact'] : []),
    }),
    removeCaseImpactCategory: build.mutation<void, number>({
      query: (id) => ({
        url: `company/caseImpactTypeCategory/${id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => makeError<never>(error),
      invalidatesTags: (_, error) => (!error ? ['CaseImpact'] : []),
    }),
    enableCaseImpactCategory: build.mutation<void, number>({
      query: (id) => ({
        url: `company/caseImpactTypeCategory/${id}/enable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CaseImpact' }, 'CaseImpact'] : [],
      onQueryStarted: async (arg, { queryFulfilled }) => {
        const patches = maybeUpdateCache('CaseImpact', arg, [
          {
            api: caseImpactCategoryApi,
            endpoint:
              caseImpactCategoryApi.endpoints.getCompanyCaseImpactCategories,
            callback: (d: CaseImpactCategoryResource[]) => {
              d.forEach((category) => {
                if (category.caseImpactTypeCategoryId === arg) {
                  category.disabled = undefined;
                }
              });
            },
          },
        ]);

        try {
          await queryFulfilled;
        } catch (e) {
          patches.forEach((x) => x.undo());
        }
      },
    }),
    disableCaseImpactCategory: build.mutation<void, number>({
      query: (id) => ({
        url: `company/caseImpactTypeCategory/${id}/disable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CaseImpact' }, 'CaseImpact'] : [],
      onQueryStarted: async (arg, { queryFulfilled }) => {
        const patches = maybeUpdateCache('CaseImpact', arg, [
          {
            api: caseImpactCategoryApi,
            endpoint:
              caseImpactCategoryApi.endpoints.getCompanyCaseImpactCategories,
            callback: (d: CaseImpactCategoryResource[]) => {
              d.forEach((category) => {
                if (category.caseImpactTypeCategoryId === arg) {
                  category.disabled = Date().toString();
                }
              });
            },
          },
        ]);

        try {
          await queryFulfilled;
        } catch (e) {
          patches.forEach((x) => x.undo());
        }
      },
    }),
  }),
});

export const {
  useGetCompanyCaseImpactCategoriesQuery,
  useRemoveCaseImpactCategoryMutation,
  useCreateCaseImpactCategoryMutation,
  useUpdateCaseImpactCategoryMutation,
  useDisableCaseImpactCategoryMutation,
  useEnableCaseImpactCategoryMutation,
} = caseImpactCategoryApi;

import { Link, styled } from '@mui/material';
import { ReactComponent as ChevronLeft } from '@assets/svgs/chevron-left.svg';
import { StepperState } from '@components/stepper/use-stepper-hook';
import Stepper from '@components/stepper/stepper';
import Spacer from '@components/layout-util-components/spacer';
import { forwardRef, ReactNode } from 'react';
import { PageAlertDisplay } from '@components/alerts';
import { Gap } from '@components/layout-util-components/gap';
import AppLogo from '@assets/svgs/app-logo.svg';

interface Props<T = any> {
  stepper?: StepperState<T>;
  onBack: () => void;
  end?: ReactNode;
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: '0 0 auto',
  padding: '0 0 0 15px',
  height: 80,
  backgroundColor: 'white',
  borderBottom: '1px solid #D8DBE3',
  position: 'fixed',
  width: '100%',
  zIndex: 10001,
  '& > .vert-divider': {
    alignSelf: 'stretch',
    margin: '0 15px',
    backgroundColor: '#E6EDF0',
    width: 1,
    border: 'none',
    '&.lg': {
      margin: '-15px 20px',
    },
  },
});

const BackButton = styled('button')(({ theme: { palette } }) => ({
  width: 35,
  height: 35,
  backgroundColor: '#fff',
  color: 'black',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid rgb(216, 216, 227)',
  cursor: 'pointer',
  borderRadius: '50%',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    border: '1px solid #FF6C00',
    backgroundColor: 'rgba(255, 108, 0, 0.04)',
  },
}));

const StepperContainer = styled('div')({
  justifySelf: 'center',
  flex: '1 0 auto',
});

const RcaStepperAppBar = forwardRef<HTMLDivElement, Props>(
  ({ stepper, onBack, end }: Props, ref) => {
    const { steps, currentStepIndex } = stepper ?? {};

    return (
      <Container ref={ref}>
        <PageAlertDisplay absolute ignoreError />
        <Link href="/">
          <img className="logo" src={AppLogo} alt="logo" width="52" />
        </Link>
        <div className="vert-divider" />
        <BackButton type="button" onClick={onBack}>
          <ChevronLeft />
        </BackButton>

        {steps ? (
          <>
            <Spacer />
            <StepperContainer>
              <Stepper activeStep={currentStepIndex!} steps={steps!} />
            </StepperContainer>
            <Spacer />
          </>
        ) : null}
        {end != null ? (
          <>
            {end}
            <Gap size={50} />
          </>
        ) : null}
      </Container>
    );
  }
);

export default RcaStepperAppBar;

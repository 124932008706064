/* eslint-disable react-hooks/rules-of-hooks */
// noinspection JSUnusedGlobalSymbols

import * as EmailValidator from 'email-validator';
import { TOptions } from 'i18next';
import { PhoneNumber } from '@components/input/phone-number-field';
import { MessageType } from '@util/types/message-type';
import { Address } from '@components/input/address-field';
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from 'postcode-validator';
import i18n from '@i18n/index';
import { isNullOrEmpty } from '@util/string-util';
import { datetimeShortFormatter } from '@i18n/formatters';
import { getDate } from '@util/date-util';

const t = (key: string, opt?: TOptions) =>
  i18n.t(`validation.${key}`, { ns: 'common', ...opt });

type ValidationCallback<T> = (value?: T) => MessageType | undefined;
type Conditional = { when?: () => boolean };

export interface Validator<T> {
  validate: ValidationCallback<T>;
  propName?: string;
}

export function createValidator<T>(
  callback: ValidationCallback<T>,
  propName?: string
): Validator<T> {
  return {
    validate: callback,
    propName,
  };
}

export const required = <T>(
  { when }: Conditional = {},
  error?: MessageType
) => {
  return createValidator<T>((value) => {
    if (when != null && !when()) {
      return undefined;
    }

    const msg = error || 'Required Field';
    if (value == null) {
      return msg;
    }

    if (typeof value === 'string' && value.length === 0) {
      return msg;
    }

    if (Array.isArray(value) && value.length === 0) {
      return msg;
    }
  });
};

export const equals = <T>(expected: T, error?: MessageType) => {
  return createValidator<T>((value) => {
    const msg = error || t('equals', { value: expected });
    if (expected !== value) {
      return msg;
    }
  });
};

export const greaterThan = <T>(num: number, msg?: string) => {
  return createValidator<T>((value) => {
    if (value == null) {
      return;
    }

    if (typeof value === 'string' && value.length < num) {
      return msg ? msg : t('greaterThan.string', { value: num });
    } else if (typeof value === 'number' && value < num) {
      return msg ? msg : t('greaterThan.number', { value: num });
    }
  });
};

export const lessThan = <T>(num: number, msg?: string) => {
  return createValidator<T>((value) => {
    if (value == null) {
      return;
    }

    if (typeof value === 'string' && value.length > num) {
      return msg ? msg : t('lessThan.string', { value: num });
    } else if (typeof value === 'number' && value > num) {
      return msg ? msg : t('lessThan.number', { value: num });
    }
  });
};

export const beforeDateTime = <T>(
  before: string,
  { when }: Conditional = {},
  error?: MessageType
) => {
  return createValidator<T>((value) => {
    if (value == null || (when != null && !when()) || isNullOrEmpty(before)) {
      return;
    }

    if (typeof value !== 'string') {
      return;
    }
    const beforeAsDate = getDate(before);
    const valueAsDate = getDate(value);

    if (beforeAsDate == null || valueAsDate == null) {
      return;
    }

    const msg = error || `Must be before ${datetimeShortFormatter(before)}`;
    if (valueAsDate.isAfter(beforeAsDate)) {
      return msg;
    }
  });
};

export const afterDateTime = <T>(
  after: string,
  { when }: Conditional = {},
  error?: MessageType
) => {
  return createValidator<T>((value) => {
    if (value == null || (when != null && !when()) || isNullOrEmpty(after)) {
      return;
    }

    if (typeof value !== 'string') {
      return;
    }
    const afterAsDate = getDate(after);
    const valueAsDate = getDate(value);

    if (afterAsDate == null || valueAsDate == null) {
      return;
    }

    const msg = error || `Must be after ${datetimeShortFormatter(after)}`;
    if (valueAsDate.isBefore(afterAsDate)) {
      return msg;
    }
  });
};

export const exactLength = <T>(length: number) => {
  return createValidator<T>((value) => {
    if (value == null) {
      return;
    }

    if (typeof value === 'string' && value.length !== length) {
      return t('exactLength', { value: length });
    }
  });
};

export const containsUppercase = <T>() => {
  return createValidator<T>((value) => {
    if (value == null || typeof value !== 'string') {
      return;
    }

    if (!/[A-Z]/.test(value)) {
      return t('includesUppercase');
    }
  });
};

export const containsLowercase = <T>() => {
  return createValidator<T>((value) => {
    if (value == null || typeof value !== 'string') {
      return;
    }

    if (!/[a-z]/.test(value)) {
      return t('includesLowercase');
    }
  });
};

export const containsNumber = <T>() => {
  return createValidator<T>((value) => {
    if (value == null || typeof value !== 'string') {
      return;
    }

    if (!/[0-9]/.test(value)) {
      return t('includesNumber');
    }
  });
};

export const containsSymbol = <T>() => {
  return createValidator<T>((value) => {
    if (value == null || typeof value !== 'string') {
      return;
    }

    // eslint-disable-next-line no-useless-escape
    if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
      return t('includesSymbol');
    }
  });
};

export const validCurrency = <T>({ when }: Conditional = {}) => {
  return createValidator<T>((value) => {
    if (when != null && !when()) {
      return undefined;
    }

    if (value == null || typeof value !== 'string') {
      return;
    }

    if (
      !/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(value)
    ) {
      return t('currency');
    }
  });
};

export const validPostcode = <T>() => {
  return createValidator<T>((value) => {
    if (value != null && typeof value === 'string' && !isNullOrEmpty(value)) {
      const country = i18n.language.split('-')[1];

      if (postcodeValidatorExistsForCountry(country)) {
        if (!postcodeValidator(value, country)) {
          return 'Must be a valid postcode';
        }
      }
    }
  });
};

export const validEmail = <T>() => {
  return createValidator<T>((value) => {
    if (value != null && typeof value === 'string') {
      if (!EmailValidator.validate(value)) {
        return t('validEmail');
      }
    }
  });
};

export const onlyNumbers = <T>({ when }: Conditional = {}) => {
  return createValidator<T>((value) => {
    if (when != null && !when()) {
      return undefined;
    }

    if (value != null && typeof value == 'string' && !/^\d+$/.test(value)) {
      return t('onlyNumbers');
    }
  });
};

export const validatorSelector = <T>(
  propName: keyof T,
  validators: Array<Validator<T>>
) => {
  for (const validator of validators) {
    validator.propName = propName as string;
  }

  return createValidator<any | undefined>((innerValue) => {
    const objHolder = innerValue || ({} as T);
    for (const validator of validators) {
      const error = validator.validate(objHolder[propName] as any);
      if (error != null) {
        return error;
      }
    }
  }, propName as string);
};

export const validPassword = () => [
  greaterThan(12),
  containsNumber(),
  containsLowercase(),
  containsUppercase(),
  containsSymbol(),
];

export const phoneNumber = (conditional: Conditional = {}) => [
  validatorSelector<PhoneNumber>('number', [
    required(conditional),
    onlyNumbers(),
  ]),
  validatorSelector<PhoneNumber>('dialCode', [
    required(conditional, 'A dial code must be selected'),
  ]),
];

export const requiredAddress = (conditional: Conditional = {}) => [
  validatorSelector<Address>('addressLineOne', [required(conditional)]),
  validatorSelector<Address>('town', [required(conditional)]),
  validatorSelector<Address>('postcode', [required(conditional)]),
];

export const ruleForEach = <T>(
  validator: Validator<T>,
  { when }: Conditional = {}
) => {
  return createValidator<Array<T>>((value) => {
    if (when != null && !when()) {
      return undefined;
    }

    if (!Array.isArray(value)) {
      return undefined;
    }

    const errors: Record<string, Record<number, string>> = {}; // Initialize errors object

    for (const [index, item] of value.entries()) {
      const error = validator.validate(item);
      if (error) {
        if (!errors[validator.propName!]) {
          errors[validator.propName!] = {};
        }

        errors[validator.propName!][index] = error as string;
      }
    }

    // Return undefined if no errors, otherwise return the errors object
    return Object.keys(errors).length > 0 ? errors : undefined;
  });
};

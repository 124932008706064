import requireAuth from '@components/hoc/with-required-auth-hoc';
import useCompanyThemes from './company-themes-hook';
import SubPageTitle from '@components/menu-items/subpage-title';
import { Gap } from '@components/layout-util-components/gap';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { WCTTableFilters } from '@components/table';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import HierachyItem from '@pages/app/company-profile/rca-settings/components/hierachy-item';
import { Button } from '@mui/material';
import AddCategoryModal, {
  AddCategoryModalProps,
} from '@pages/app/company-profile/rca-settings/company-themes/components/add-category-modal';
import AddThemeModal, {
  AddThemeModalProps,
} from '@pages/app/company-profile/rca-settings/company-themes/components/add-theme-modal';
import EditThemeModal, {
  EditThemeModalProps,
} from '@pages/app/company-profile/rca-settings/company-themes/components/edit-theme-modal';
import { LoadingIndicator } from '@components/loading-indicator';
import { ThemeResource } from '@api/types/theme/theme.resource';
import useCompanyProfileLayout from '@components/layouts/app/company-profile-layout-hook';

function CompanyThemesPage() {
  const {
    themes,
    loadingThemes,
    fetchingThemes,
    toggleTheme,
    setSearchText,
    searchText,
  } = useCompanyThemes();

  const { companyProfileDefaultUrl } = useCompanyProfileLayout();

  const { showPopup } = useUiPopup();

  const openAddCategoryModal = async () => {
    await showPopup<AddCategoryModalProps, void>(AddCategoryModal, {});
  };

  const openAddThemeModal = async (category: ThemeResource) => {
    await showPopup<AddThemeModalProps, void>(AddThemeModal, {
      categoryId: category.themeId,
    });
  };

  const openEditThemeModal = (theme: ThemeResource, isCategory = false) => {
    showPopup<EditThemeModalProps, void>(EditThemeModal, {
      theme,
      isCategory: isCategory,
    });
  };

  return (
    <>
      <SubPageTitle
        items={[
          { label: 'Company Profile', path: companyProfileDefaultUrl },
          { label: 'RCA Settings', path: '/company-profile/rca-settings' },
          { label: 'Theme Configuration' },
        ]}
      />
      <Gap size={32} />
      <WCTTableFilters
        search={searchText}
        onSearch={setSearchText}
        isLoading={loadingThemes}
        isFetching={fetchingThemes}
      >
        <Button variant="contained" onClick={openAddCategoryModal}>
          <FontAwesomeIcon icon={faPlus} />
          Add Category
        </Button>
      </WCTTableFilters>
      <Gap size={20} />
      {loadingThemes ? (
        <LoadingIndicator show />
      ) : (
        themes?.map((category) => (
          <Fragment key={category.themeId}>
            <HierachyItem
              canEdit={!category.hasRCA}
              isActive={!category.disabled}
              onEdit={() => {
                openEditThemeModal(category, true);
              }}
              name={category.name}
              onToggleActive={(_) => toggleTheme(category)}
              addButton={{
                label: 'Add Theme',
                onPressed: () => openAddThemeModal(category),
              }}
              subItems={category.childThemes.map((theme) => ({
                id: theme.themeId,
                label: theme.name,
                canEdit: !(category.disabled || theme.disabled),
                onEdit: (_) => {
                  openEditThemeModal(theme);
                },
              }))}
            />
            <Gap size={10} />
          </Fragment>
        ))
      )}
      <Gap size={20} />
      {/*<ReferenceDataDisableList*/}
      {/*  options={options}*/}
      {/*  isLoading={loadingThemes}*/}
      {/*  onEnable={enableTheme}*/}
      {/*  onDisable={disableTheme}*/}
      {/*  onDelete={deleteTheme}*/}
      {/*  type="theme"*/}
      {/*  onCreateClick={openChildModal}*/}
      {/*  isTopLevel*/}
      {/*  sx={{*/}
      {/*    padding: '8px 20px',*/}
      {/*    borderRadius: '10px',*/}
      {/*    borderBottom: '1px solid #E7E8E6',*/}
      {/*  }}*/}
      {/*  childSx={{*/}
      {/*    padding: '5px 20px',*/}
      {/*    borderRadius: '10px',*/}
      {/*    borderBottom: '1px solid #E7E8E6',*/}
      {/*  }}*/}
      {/*  space={8}*/}
      {/*/>*/}
    </>
  );
}

export default requireAuth(CompanyThemesPage);

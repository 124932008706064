import { Popover, styled } from '@mui/material';
import React from 'react';
import { RolloverInfoState } from '@components/rollover-info/use-rollover-info';
import { isNullOrEmpty } from '@util/string-util';

const StyledPopover = styled(Popover)(({ theme: { palette } }) => ({
  pointerEvents: 'none',
  outline: 'none !important',
  '*': {
    pointerEvents: 'none !important',
    background: 'none',
    outline: 'none !important',
  },

  '& .MuiPaper-root': {
    minWidth: 174,
    maxWidth: 209,
    padding: '3px 6px !important',
    background: palette.grey['900'],
    outline: 'none',
    opacity: 1,
    color: palette.common.white,
    fontFamily: 'Usual',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '17.16px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
}));

export default function RolloverInfo({
  id,
  target,
  isShown,
  message,
}: RolloverInfoState) {
  if (target == null || isNullOrEmpty(message)) {
    return <></>;
  }

  return (
    <StyledPopover
      id={id}
      open={isShown}
      anchorEl={target}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {message}
    </StyledPopover>
  );
}

import ProfileLayoutScaffold from '@components/layouts/app/scaffolds/profile-layout-scaffold';
import useCompanyProfileLayout from './company-profile-layout-hook';

export default function CompanyProfileLayout() {
  const { tabs } = useCompanyProfileLayout();

  return (
    <ProfileLayoutScaffold
      baseRoute="company-profile"
      title="Company Profile"
      tabs={tabs}
    />
  );
}

import { isApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useState } from 'react';
import useBusyAction from '@hooks/use-busy-action-hook';
import {
  useDisableCaseImpactCategoryMutation,
  useEnableCaseImpactCategoryMutation,
  useGetCompanyCaseImpactCategoriesQuery,
} from '@api/endpoints/company/company-case-impact-type-category.api';
import { CaseImpactCategoryResource } from '@api/types/case-impact-type/case-impact-category.resource';

export default function useCompanyImpacts() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const [searchText, setSearchText] = useState<string>();

  const {
    data: impactCategories,
    isLoading: loadingCategories,
    isFetching: fetchingCategories,
  } = useGetCompanyCaseImpactCategoriesQuery(searchText);

  const [enableCategory] = useEnableCaseImpactCategoryMutation();
  const [disableCategory] = useDisableCaseImpactCategoryMutation();

  const [toggleCategory] = useBusyAction(
    async (category: CaseImpactCategoryResource) => {
      try {
        if (!category.disabled) {
          await disableCategory(category.caseImpactTypeCategoryId);

          showSuccessMessage(
            `You have successfully disabled category '${category.name}'`
          );
        } else {
          await enableCategory(category.caseImpactTypeCategoryId);
          showSuccessMessage(
            `You have successfully enabled category '${category.name}'`
          );
        }
      } catch (error) {
        if (isApiError(error)) {
          showErrorMessage(error.message);
        }
      }
    }
  );

  return {
    impactCategories,
    loadingCategories,
    toggleCategory,
    setSearchText,
    searchText,
    fetchingCategories,
  };
}

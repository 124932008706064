import { useGetInstallConfigurationQuery } from '@api/endpoints/install.api';
import { Permission } from '@api/types/permission';
import { usePermissions } from '@store/user/permission-hook';
import { ProfileLayoutTab } from './scaffolds/profile-layout-scaffold';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useCompanyProfileLayout() {
  const navigate = useNavigate();

  const canAccessUserManagement = usePermissions([
    Permission.manageCompanyUsers,
  ]);

  const canAccessCompanyDetails = usePermissions([Permission.manageCompany]);

  const canAccessBilling = usePermissions([Permission.manageCompanyPayments]);

  const { data: installConfiguration } = useGetInstallConfigurationQuery();

  const canAccessLocations = usePermissions([
    Permission.manageCompanyLocations,
  ]);

  const canManageCompanyReferenceData = usePermissions([
    Permission.manageCompanyReferenceData,
  ]);

  const canSeeCompanyProfile = usePermissions([Permission.manageCompany]);

  const canSeeCompanyLocations = usePermissions([
    Permission.manageCompanyLocations,
  ]);

  const canManagePayments = usePermissions([Permission.manageCompanyPayments]);

  const tabs = useMemo((): Array<ProfileLayoutTab> => {
    const ret: Array<ProfileLayoutTab> = [];

    if (canAccessCompanyDetails) {
      ret.push({
        path: '',
        subPaths: ['information', 'mfa', 'settings'],
        name: 'Company Settings',
        index: true,
      });
    }

    if (canAccessLocations) {
      ret.push({
        path: 'locations',
        name: 'Company Locations',
      });
    }

    if (canAccessUserManagement) {
      ret.push({
        path: 'users',
        name: 'User Management',
      });
    }

    if (canAccessBilling && installConfiguration?.manualPayments !== true) {
      ret.push({
        path: 'billing',
        name: 'Billing',
      });
    }

    if (canManageCompanyReferenceData) {
      ret.push({
        path: 'rca-settings',
        name: 'RCA Settings',
        subPaths: [
          'rca-settings-themes',
          'rca-settings-impacts',
          'rca-settings-case-types',
          'rca-settings-types',
          'rca-settings-labels',
        ],
      });
    }

    return ret;
  }, [
    canAccessBilling,
    canAccessCompanyDetails,
    canAccessLocations,
    canAccessUserManagement,
    canManageCompanyReferenceData,
    installConfiguration?.manualPayments,
  ]);

  const canSeeProfilePage = useMemo(() => {
    return (
      canSeeCompanyProfile ||
      canManagePayments ||
      canSeeCompanyLocations ||
      canManageCompanyReferenceData ||
      canAccessUserManagement
    );
  }, [
    canSeeCompanyProfile,
    canManagePayments,
    canSeeCompanyLocations,
    canManageCompanyReferenceData,
    canAccessUserManagement,
  ]);

  const companyProfileDefaultUrl = useMemo(() => {
    if (canSeeCompanyProfile) {
      return '/company-profile';
    } else if (canManagePayments) {
      return '/company-profile/billing';
    } else if (canSeeCompanyLocations) {
      return '/company-profile/locations';
    } else if (canAccessUserManagement) {
      return '/company-profile/users';
    } else if (canManageCompanyReferenceData) {
      return '/company-profile/rca-settings';
    }

    return '/company-profile';
  }, [
    canSeeCompanyProfile,
    canManagePayments,
    canSeeCompanyLocations,
    canAccessUserManagement,
    canManageCompanyReferenceData,
  ]);

  const onCompanyProfileClick = () => {
    navigate(companyProfileDefaultUrl);
  };

  return {
    tabs,
    canAccessBilling,
    canAccessCompanyDetails,
    canAccessLocations,
    canAccessUserManagement,
    canManageCompanyReferenceData,
    canSeeProfilePage,
    onCompanyProfileClick,
    companyProfileDefaultUrl,
  };
}

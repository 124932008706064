import { useEffect, useMemo, useRef, useState } from 'react';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { useFilter, usePagination } from '@components/table';
import {
  useCompleteCaseMutation,
  useGetCaseDetailQuery,
  useGetGroupOptionsForCaseQuery,
  useGetUsersForCaseQuery,
  useInviteUserToCaseMutation,
  useRemoveUserFromCaseMutation,
  useUpdateSyncedGroupsForCaseMutation,
  useUpdateUserCaseRoleMutation,
} from '@api/endpoints/case.api';
import { useGetCompanyGroupOptionsQuery } from '@api/endpoints/company/company-company-group.api';
import { ApiError, isApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useGetCaseRoleOptionsQuery } from '@api/endpoints/case-role.api';
import { CaseUserResource } from '@api/types/case/case-collaboration/case-user.resource';
import { InviteCaseUserRequest } from '@api/types/case/case-collaboration/invite-case-user.request';
import { UpdateSyncGroupsRequest } from '@api/types/case/case-collaboration/update-sync-groups.request';
import { useIsPath } from '@util/path-util';
import { useSetChainEditorMutation } from '@api/endpoints/chain/chain.api';
import { SetChainEditorRequest } from '@api/types/chain/set-chain-editor-request';
import { CaseRoleOption } from '@api/types/case-role/case-role-option';
import { invalidation } from '@api/cache-util';

export enum CollaborationViewMode {
  form,
  users,
}

export default function useCollaboration(caseId: number) {
  const isEdit = useIsPath('/rca/edit', { startsWith: true });
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const [viewMode, setViewMode] = useState(CollaborationViewMode.form);
  const recomendedUsersPagination = usePagination();
  const contributorsPagination = usePagination();

  const { data: caseDetail, isLoading: loadingCaseDetail } =
    useGetCaseDetailQuery(caseId);

  const { data: roleOptions, isLoading: loadingRoleOptions } =
    useGetCaseRoleOptionsQuery();

  const { data: groupOptions, isLoading: loadingGroupOptions } =
    useGetCompanyGroupOptionsQuery({});

  const { data: invitedGroups, isLoading: loadingInvitedGroups } =
    useGetGroupOptionsForCaseQuery(caseId);

  const contributorSearch = useFilter<string>();
  const {
    data: recomendedUsers,
    isLoading: loadingRecommendedUsers,
    isFetching: fetchingRecommendedUsers,
  } = useGetUsersForCaseQuery({
    caseId,
    searchText: contributorSearch.value,
    invitedOnly: false,
    skip: recomendedUsersPagination.skip,
    take: recomendedUsersPagination.take,
  });

  const {
    data: contributors,
    isLoading: loadingContributors,
    isFetching: fetchingContributors,
  } = useGetUsersForCaseQuery({
    caseId,
    searchText: contributorSearch.value,
    invitedOnly: true,
    skip: contributorsPagination.skip,
    take: contributorsPagination.take,
  });

  const [completeCase, { isLoading: isCompletingCase }] =
    useCompleteCaseMutation();

  const [updateSyncGroups, { isLoading: isUpdatingGroups }] =
    useUpdateSyncedGroupsForCaseMutation();

  const [setChainEditor] = useSetChainEditorMutation();
  const [inviteUser, { isLoading: isInvitingUser }] =
    useInviteUserToCaseMutation();
  const [updateUserRole, { isLoading: isUpdatingUserRole }] =
    useUpdateUserCaseRoleMutation();
  const [removeUser, { isLoading: isRemovingUserFromCase }] =
    useRemoveUserFromCaseMutation();

  const isPublicCase = useField<boolean>(
    [
      required(
        undefined,
        // eslint-disable-next-line quotes
        "Please select either 'Invite Only' or 'All Users'"
      ),
    ],
    isEdit ? caseDetail?.isPublic : undefined
  );
  const groups = useField<Array<number>>(
    [],
    useMemo(
      () => caseDetail?.groups.map((group) => group.id) ?? [],
      [caseDetail?.groups]
    )
  );
  const shouldCollaborate = useField<boolean>(
    [
      required(
        undefined,
        // eslint-disable-next-line quotes
        "Please select either 'Yes' or 'No'"
      ),
    ],
    isEdit ? caseDetail?.hasCollaboration : undefined
  );

  const { isValid: isFormFieldsValid, validateAll } = useFieldsWatcher([
    isPublicCase,
    groups,
    shouldCollaborate,
  ]);

  const isLoading =
    loadingInvitedGroups ||
    loadingGroupOptions ||
    loadingRecommendedUsers ||
    loadingContributors ||
    loadingRoleOptions ||
    loadingCaseDetail;

  const isBusy =
    fetchingRecommendedUsers ||
    fetchingContributors ||
    isInvitingUser ||
    isUpdatingUserRole ||
    isRemovingUserFromCase ||
    isCompletingCase ||
    isUpdatingGroups;

  const isValid =
    viewMode === CollaborationViewMode.form ? isFormFieldsValid : true;
  const canSubmit = !isLoading && !isBusy && isValid;

  const hasInit = useRef(false);
  useEffect(() => {
    if (caseDetail != null && !hasInit.current) {
      setViewMode(
        caseDetail!.hasCollaboration
          ? CollaborationViewMode.users
          : CollaborationViewMode.form
      );
      hasInit.current = true;
    }
  }, [caseDetail]);

  const onSyncGroups = async () => {
    if (!validateAll()) {
      return false;
    }

    return updateSyncGroups({
      caseId,
      groups: groups.value,
      hasCollaboration: shouldCollaborate.value,
      isPublic: isPublicCase.value,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage('Collaboration settings updated');
        return true;
      })
      .catch(({ message, errors }: ApiError<UpdateSyncGroupsRequest>) => {
        showErrorMessage(message);
        groups.setError(errors?.groups);
        shouldCollaborate.setError(errors?.hasCollaboration);
        isPublicCase.setError(errors?.isPublic);
        return false;
      });
  };

  const onInviteUser = async (userId: number) => {
    try {
      await inviteUser({
        caseId,
        companyUserId: userId,
      }).unwrap();
      await invalidation('CaseUser');
      return true;
    } catch (e) {
      if (isApiError<InviteCaseUserRequest>(e)) {
        showErrorMessage(e.errors?.companyUserId ?? e.message);
      }
      return false;
    }
  };

  const onUpdateUserRole = async (
    updatedUser: CaseUserResource,
    newRole: CaseRoleOption
  ) => {
    try {
      // If we're making the user a role that isn't allowed to be a chain editor, and this
      // is a user who is a chain editor, we need to prevent it from happening
      if (updatedUser.isChainEditor) {
        showErrorMessage(
          'Cannot change the role of the chain editor, please reassign first'
        );
        return false;
      }

      await updateUserRole({
        caseId,
        companyUserCaseId: updatedUser.companyUserCaseId,
        caseRoleId: newRole.caseRoleId,
        caseRole: newRole.caseRole,
        caseRoleName: newRole.name,
      }).unwrap();

      showSuccessMessage(`${updatedUser.name} role updated`);
      return true;
    } catch (e) {
      if (isApiError(e)) {
        showErrorMessage(e.message);
      }
      return false;
    }
  };

  const onRemoveUser = async (removedUser: CaseUserResource) => {
    if (removedUser.companyUserId === caseDetail?.createdByCompanyUserId) {
      showErrorMessage('Cannot remove the creator of this analysis');
      return false;
    }

    if (removedUser.isChainEditor) {
      showErrorMessage('Cannot remove the chain editor, please reassign first');
      return false;
    }

    try {
      await removeUser({
        caseId,
        companyUserCaseId: removedUser.companyUserCaseId,
      }).unwrap();

      await invalidation('CaseUser');

      return true;
    } catch (e) {
      if (isApiError(e)) {
        showErrorMessage(e.message);
      }
      return false;
    }
  };

  const onCompleteCase = () => {
    return completeCase(caseId)
      .unwrap()
      .then(() => true)
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  const onSetChainEditor = (user: CaseUserResource) => {
    return setChainEditor({
      chainId: caseDetail!.chainId,
      companyUserId: user.companyUserId,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(`${user.name} is now the chain editor`);
        return true;
      })
      .catch(({ errors, message }: ApiError<SetChainEditorRequest>) => {
        showErrorMessage(errors?.chainId ?? errors?.companyUserId ?? message);
        return false;
      });
  };

  return {
    caseId,
    viewMode,
    setViewMode,
    isPublicCase,
    groupOptions,
    groups,
    shouldCollaborate,
    isFormFieldsValid,
    contributors,
    contributorSearch,
    invitedGroups,
    recomendedUsers,
    isLoading,
    roleOptions,
    onSyncGroups,
    canSubmit,
    isBusy,
    onInviteUser,
    onUpdateUserRole,
    onRemoveUser,
    onCompleteCase,
    isEdit,
    fetchingContributors,
    fetchingRecommendedUsers,
    onSetChainEditor,
    recomendedUsersPagination,
    contributorsPagination,
  };
}

export type CollaborationState = ReturnType<typeof useCollaboration>;

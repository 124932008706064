import Column from '@components/layout-util-components/column';
import { Box, FormHelperText, styled, Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import { CaseImpactTypeMetricsResource } from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import { ReactComponent as RadioDefault } from '@assets/svgs/radio-default.svg';
import { ReactComponent as RadioActive } from '@assets/svgs/radio-active.svg';
import ScaleOf3 from '@assets/images/scale-of-3.png';
import ScaleOf5 from '@assets/images/scale-of-5.png';
import FinancialImpact from '@assets/images/financial-impact.png';
import { Gap } from '@components/layout-util-components/gap';

export interface CaseTypeMetricInputProps {
  value?: number;
  onChange: (v: number) => void;
  metricOptions: CaseImpactTypeMetricsResource[];
  error?: string;
  helperText?: string;
  readonly?: boolean;
}

interface ColumnProps {
  isActive: boolean;
  position: 'left' | 'center' | 'right';
}

const StyledColumn = styled(Column)<ColumnProps>(
  ({ theme: { palette }, isActive, position }) => ({
    flex: 1,
    padding: '12px',
    border: '1px solid #E7E8E6',
    borderColor: isActive ? '#FF6C00' : '#E7E8E6',
    borderRadius:
      position === 'left'
        ? '6px 0 0 6px' // Round only the top-left and bottom-left corners
        : position === 'right'
        ? '0 6px 6px 0' // Round only the top-right and bottom-right corners
        : '0', // No rounding for the center
  })
);

const OptionTitle = styled(Typography)(({ theme: { palette } }) => ({
  marginLeft: '10px',
}));

const OptionDesc = styled(Typography)(({ theme: { palette } }) => ({
  fontSize: '14px',
  marginTop: '10px',
}));

export function CaseTypeMetricInput({
  metricOptions,
  onChange,
  value,
  error,
  helperText,
  readonly,
}: CaseTypeMetricInputProps) {
  const financial = metricOptions.find((x) => x.caseImpactTypeMetricId === 1);
  const scaleOf3 = metricOptions.find((x) => x.caseImpactTypeMetricId === 2);
  const scaleOf5 = metricOptions.find((x) => x.caseImpactTypeMetricId === 4);

  return (
    <Column>
      <Column sx={{ opacity: readonly ? 0.5 : 1 }}>
        <Typography variant="body1">Impact Measure*</Typography>
        <Gap size={4} />
        <Typography variant="body2">
          How would you like to measure this impact?
        </Typography>
        <Gap size={10} />
        <Row stretchItems>
          {financial && (
            <StyledColumn
              isActive={value === financial?.caseImpactTypeMetricId}
              position="left"
              onClick={
                readonly
                  ? undefined
                  : () => onChange(financial?.caseImpactTypeMetricId)
              }
            >
              <Row>
                {value === financial?.caseImpactTypeMetricId ? (
                  <RadioActive />
                ) : (
                  <RadioDefault />
                )}
                <OptionTitle>Financial Value</OptionTitle>
              </Row>
              <OptionDesc style={{ width: '60%' }}>
                Input a value in your default currency
              </OptionDesc>
              <Gap size={15} />
              <Row contentAtEnd>
                <img width={134} src={FinancialImpact} alt="Financial input" />
              </Row>
            </StyledColumn>
          )}
          {scaleOf3 && (
            <StyledColumn
              isActive={value === scaleOf3?.caseImpactTypeMetricId}
              position="center"
              onClick={
                readonly
                  ? undefined
                  : () => onChange(scaleOf3?.caseImpactTypeMetricId)
              }
            >
              <Row sx={{ height: '100%' }}>
                <Column sx={{ alignSelf: 'flex-start' }}>
                  <Row>
                    {value === scaleOf3?.caseImpactTypeMetricId ? (
                      <RadioActive />
                    ) : (
                      <RadioDefault />
                    )}
                    <OptionTitle>Scale of 3</OptionTitle>
                  </Row>
                  <OptionDesc style={{ width: '60%' }}>
                    Options from 'High' to 'Low'
                  </OptionDesc>
                </Column>
                <img src={ScaleOf3} alt="scale of 3" width={124} height={76} />
              </Row>
            </StyledColumn>
          )}
          {scaleOf5 && (
            <StyledColumn
              isActive={value === scaleOf5?.caseImpactTypeMetricId}
              position="right"
              onClick={
                readonly
                  ? undefined
                  : () => onChange(scaleOf5?.caseImpactTypeMetricId)
              }
            >
              <Row sx={{ alignItems: 'flex-start' }}>
                <Column>
                  <Row>
                    {value === scaleOf5?.caseImpactTypeMetricId ? (
                      <RadioActive />
                    ) : (
                      <RadioDefault />
                    )}
                    <OptionTitle>Scale of 5</OptionTitle>
                  </Row>
                  <OptionDesc style={{ width: '60%' }}>
                    Options from 'Very High' to 'Very Low'
                  </OptionDesc>
                </Column>
                <img src={ScaleOf5} alt="scale of 5" width={124} height={123} />
              </Row>
            </StyledColumn>
          )}
        </Row>
      </Column>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Column>
  );
}

import {
  NodeCollapseState,
  NodeEndStateType,
  RcaNode,
  RcaNodeType,
} from '@store/rca-editor/types';
import useNode from '@pages/app/rca/tabs/components/node-types/node-hook';
import { store, useAppDispatch, useAppSelector } from '@store/store';
import {
  commitCreateNode,
  highlightNode,
  makeNodeEndState,
  removeNode,
} from '@store/rca-editor/rca-editor-actions';
import { useContext, useMemo, useState } from 'react';
import {
  makeSelectHighlightAsConnectedNode,
  makeSelectNode,
  makeSelectNodeDisplayProperties,
  makeSelectNodeFamilyCountInfo,
  makeSelectParentNode,
  selectChartDisplayMode,
  selectCurrentDraggingNodeId,
  selectEditingNodeId,
  selectFocusedNodeIdNullable,
  selectIsAnyPanelOpen,
  selectIsDevMode,
  selectIsGeneratingAChainItem,
  selectIsHealthScorePanelOpen,
  selectIsHighlightMode,
  selectStorageNodes,
} from '@store/rca-editor/selectors';
import { RcaUtil } from '@util/rca-util';
import { setSelectedNode } from '@store/rca-editor/rca-editor-slice';
import { NodeEditingTextAreaContext } from '@pages/app/rca/tabs/rca-editor-chart';

export default function useDefaultNode(node: RcaNode) {
  const textAreaRef = useContext(NodeEditingTextAreaContext);
  const dispatch = useAppDispatch();

  const [hasCommitted, setHasCommitted] = useState(
    node.data.chainItemId != null
  );

  const {
    id,
    type,
    selected,
    data: { disproved, chainItemId, label, isRoot, highlight, readonly },
  } = node;

  const hasChainItemId = chainItemId != null;

  const baseNodeState = useNode(node);
  const {
    isEndStateOrConnection,
    isEndState,
    chainId,
    parentNodeType,
    collapsedState,
    isConnection,
    isPresenterMode,
    canEditGraph,
    canContribute,
  } = baseNodeState;

  const selectParentNode = useMemo(() => makeSelectParentNode(id), [id]);
  const parentNode = useAppSelector(selectParentNode);

  const selectFamilyCountInfo = useMemo(() => {
    return makeSelectNodeFamilyCountInfo(id, parentNode?.id);
  }, [id, parentNode?.id]);

  const selectShouldHighlightAsConnection = useMemo(
    () => makeSelectHighlightAsConnectedNode(id),
    [id]
  );
  const selectDisplayProperties = useMemo(() => {
    return makeSelectNodeDisplayProperties(id, chainId ?? -1, chainItemId);
  }, [chainId, chainItemId, id]);

  const chartDisplayMode = useAppSelector(selectChartDisplayMode);
  const focusedNodeId = useAppSelector(selectFocusedNodeIdNullable);
  const isAnyPanelOpen = useAppSelector(selectIsAnyPanelOpen);
  const editingId = useAppSelector(selectEditingNodeId);
  const isHealthScorePanelOpen = useAppSelector(selectIsHealthScorePanelOpen);
  const storageNodes = useAppSelector(selectStorageNodes);
  const maybeFocusedNodeId = useAppSelector(selectFocusedNodeIdNullable);
  const displayProperties = useAppSelector(selectDisplayProperties);
  const isDevMode = useAppSelector(selectIsDevMode);
  const isHighlightMode = useAppSelector(selectIsHighlightMode);
  const isEditing = editingId === id && chainItemId == null;
  const familyCounts = useAppSelector(selectFamilyCountInfo);
  const childCount = familyCounts.childCount;
  const siblingCount = familyCounts.siblingCount;
  const isDragging = useAppSelector(selectCurrentDraggingNodeId) === id;
  const isGeneratingAChainItem = useAppSelector(selectIsGeneratingAChainItem);
  const childCountIncludingMetaNodes =
    familyCounts.childCountIncludingMetaNodes;
  const isConnectedFromOtherNodes = useAppSelector(
    selectShouldHighlightAsConnection
  );

  const hasComeFromStorage = storageNodes.some((x) => x.clientUuid === id);
  const shouldFadeOut =
    (maybeFocusedNodeId != null &&
      id !== maybeFocusedNodeId &&
      !isPresenterMode &&
      !isAnyPanelOpen) ||
    !!displayProperties.faded;
  const shouldDisplayCollapsedIndicator =
    isDragging ||
    collapsedState === NodeCollapseState.collapsed ||
    collapsedState === NodeCollapseState.ghost;
  const shouldDisplayEndStateInfo = isEndState && !isConnection;
  const shouldDisplayEndStateElipsis =
    isEndState && !isConnection && !isPresenterMode && canEditGraph;

  const shouldDisplayConnectionIndicator =
    isConnectedFromOtherNodes && !isConnection;

  const canConnectEdgeFromThisNode = !isEndStateOrConnection;
  const canConnectEdgeToThisNode = !isRoot;
  const canEditFocalPoint = isRoot && canContribute;
  const canDrag =
    !isEndStateOrConnection &&
    !isRoot &&
    !isPresenterMode &&
    !isEditing &&
    !isGeneratingAChainItem &&
    maybeFocusedNodeId == null &&
    canEditGraph;
  const canHandleClick = !(isEditing || isDragging);
  const canShowActions =
    collapsedState === NodeCollapseState.default &&
    !isConnection &&
    !isPresenterMode &&
    !isDragging &&
    focusedNodeId == null &&
    !isAnyPanelOpen &&
    canEditGraph &&
    editingId == null;
  const canMakeEndState =
    childCount === 0 && !disproved && !isEndStateOrConnection && canEditGraph;
  const canShowRightMetaAction =
    !isPresenterMode &&
    !isConnection &&
    collapsedState === NodeCollapseState.default &&
    childCount > 1 &&
    !isAnyPanelOpen &&
    canEditGraph;
  const canShowLeftMetaAction =
    !isPresenterMode &&
    !isConnection &&
    !RcaUtil.isMetaNodeType(parentNodeType) &&
    collapsedState === NodeCollapseState.default &&
    siblingCount > 1 &&
    !isAnyPanelOpen &&
    canEditGraph;
  const canShowBorder =
    (selected && (!isAnyPanelOpen || isHealthScorePanelOpen)) ||
    highlight ||
    displayProperties.outlineColor != null;

  const maybeCommitCreate = async (label?: string) => {
    if (hasCommitted) {
      return;
    }

    try {
      const shouldCommit = label != null && label.length > 0;
      setHasCommitted(shouldCommit);

      if (shouldCommit) {
        await dispatch(commitCreateNode(id, label));
      } else {
        await dispatch(
          removeNode(id, { moveToStorage: false, reparentChildren: true })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const cancelCreate = async () => {
    try {
      if (hasCommitted) {
        return;
      }

      await dispatch(
        removeNode(id, { moveToStorage: false, reparentChildren: true })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const setNodeHighlight = (highlightChain?: boolean) => {
    dispatch(highlightNode(id, highlightChain ?? false));
  };

  const selectNode = () => {
    dispatch(setSelectedNode(node));
    // Select from state as the node passed into this method is the render node provided by react flow
    const nodeInState = makeSelectNode(node.id)(store.getState());
    if (nodeInState != null) {
      RcaUtil.snapFocusToNode(nodeInState);
    }
  };

  const removeEndState = () => {
    return dispatch(makeNodeEndState(id, NodeEndStateType.none));
  };

  return {
    ...baseNodeState,
    canShowActions,
    label,
    maybeCommitCreate,
    cancelCreate,
    canMakeEndState,
    disproved,
    childCount,
    childCountIncludingMetaNodes,
    shouldDisplayEndStateInfo,
    shouldDisplayCollapsedIndicator,
    canShowLeftMetaAction,
    canShowRightMetaAction,
    canConnectEdgeToThisNode,
    canConnectEdgeFromThisNode,
    shouldDisplayConnectionIndicator,
    canHandleClick,
    isEditing,
    isHighlightMode,
    setNodeHighlight,
    selectNode,
    isDevMode,
    hasChainItemId,
    isRoot,
    chainItemId,
    displayProperties,
    canShowBorder,
    removeEndState,
    shouldDisplayEndStateElipsis,
    canDrag,
    hasComeFromStorage,
    shouldFadeOut,
    type: type ?? RcaNodeType.default,
    textAreaRef,
    isDragging,
    chartDisplayMode,
    canEditFocalPoint,
    canEditGraph,
    isParentDisproved: parentNode?.data?.disproved ?? false,
    readonly: readonly === true,
  };
}

export type BaseNodeState = ReturnType<typeof useDefaultNode>;

import { createBrowserRouter } from 'react-router-dom';
import AuthLayout from '@components/layouts/auth-layout';
import CreateAccountPage from '@pages/auth/create-account-page';
import ConfirmEmailRequestPage from '@pages/auth/confirm-email-request-page';
import CreatePasswordPage from '@pages/auth/create-password-page';
import CreateCompanyPage from '@pages/auth/create-company-page';
import CreateAccountProgressPage from '@pages/auth/create-account-progress-page';
import LoginPage from '@pages/auth/login-page';
import ForgotPasswordPage from '@pages/auth/forgot-password-page';
import ResetPasswordPage from '@pages/auth/reset-password-page';
import MfaPage from '@pages/auth/mfa-page';
import AppLandingLayout from '@components/layouts/app/app-landing-layout';
import RegisterInterestPage from '@pages/app/register-interest/register-interest-page';
import AppLayout from '@components/layouts/app/app-layout';
import ProfilePageLayout from '@components/layouts/app/profile-layout';
import LoginErrorPage from '@pages/auth/login-error-page';
import AcceptInvitePage from '@pages/auth/accept-invite-page';
import CompanyProfileLayout from '@components/layouts/app/company-profile-layout';
import PersonalDetailsPage from '@pages/app/profile/personal-details-page';
import ProfileSettingsIndexPage from '@pages/app/profile/settings/profile-settings-index-page';
import ProfileUpdatePasswordPage from '@pages/app/profile/settings/profile-update-password-page';
import ProfileMfaSetupPage from '@pages/app/profile/settings/profile-mfa-setup-page';
import ManageUsersPage from '@pages/app/company-profile/manage-users-page';
import TasksIndexPage from '@pages/app/tasks/tasks-index-page';
import ProfilePreferencesPage from '@pages/app/profile/profile-preferences-page';
import App from 'src/app';
import HomePage from '@pages/app/dashboard/dashboard-page';
import CompanyMfaSetupPage from '@pages/app/company-profile/company-mfa-setup-page';
import BillingPage from '@pages/app/company-profile/billing-page';
import FunctionalLocationsPage from '@pages/app/company-profile/functional-locations-page';
import RcaCreationStepsPage from '@pages/app/rca/create/rca-creation-steps-page';
import RcaEditorOverview from '@pages/app/rca/tabs/rca-editor-overview';
import RcaEditorGraph from '@pages/app/rca/tabs/rca-editor-chart';
import RcaEditorTasks from '@pages/app/rca/tabs/rca-editor-tasks';
import RcaEditorEvidence from '@pages/app/rca/tabs/rca-editor-evidence';
import RcaEditorSolutions from '@pages/app/rca/tabs/rca-editor-solutions';
import CompanySettingsIndexPage from '@pages/app/company-profile/company-settings-index-page';
import CompanySettingsPage from '@pages/app/company-profile/company-information-page';
import AnalysisOverviewPage from '@pages/app/analysis/analysis-overview-page';
import CompanyPreferencesPage from '@pages/app/company-profile/company-preferences-page';
import LogoutCapturePage from '@pages/auth/logout-capture-page';
import EditCasePage from '@pages/app/rca/edit/edit-case-page';
import RcaEditorFiles from '@pages/app/rca/tabs/rca-editor-files';
import HelpCentreIndexPage from '@pages/app/help-centre/help-center-page';
import SkillsPage from '@pages/app/profile/skills-page';
import KudosPage from '@pages/app/profile/kudos-page';
import RcaOutcomePage from '@pages/app/rca/outcome/rca-outcome-page';
import OutcomeReportPage from '@pages/app/outcome/outcome-report-page';
import GroupsPage from '@pages/app/groups/groups-page';
import FaqPage from '@pages/app/help-centre/faq-page';
import VideoPage from '@pages/app/help-centre/video-page';
import { CategoryPage } from '@pages/app/help-centre/view-category-page';
import ArticleDetailPage from '@pages/app/help-centre/article-detail-page';
import ArticlePage from '@pages/app/help-centre/article-page';
import OAuthPage from '@pages/auth/oauth-page';
import CompanyRcaSettingsPage from '@pages/app/company-profile/company-rca-settings-page';
import CompanyThemesPage from '@pages/app/company-profile/rca-settings/company-themes/company-themes-page';
import CompanyCaseImpactTypesPage from '@pages/app/company-profile/rca-settings/company-impacts/company-impacts-page';
import CompanyCaseAnalysisTypesPage from '@pages/app/company-profile/company-case-analysis-types-page';
import CompanySystemTextPage from '@pages/app/company-profile/company-system-text-page';
import ErrorRouteCatcher from '@components/error-handling/error-route-catcher';
import RcaResetter from '@pages/app/rca/rca-resetter';
import SelectCompanyPage from '@pages/auth/select-company-page';
import RcaEditorImpacts from '@pages/app/rca/tabs/rca-editor-impacts';
import SSOCallbackPage from '@pages/auth/sso-callback-page';
import CompanyCaseTypesPage from '@pages/app/company-profile/rca-settings/company-case-types/company-case-types-page';

const appRoutes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorRouteCatcher />,
    children: [
      {
        path: '/',
        element: <AppLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [
          {
            index: true,
            element: <HomePage />,
          },
        ],
      },
      {
        path: 'profile',
        element: <AppLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [
          {
            element: <ProfilePageLayout />,
            children: [
              { index: true, element: <PersonalDetailsPage /> },
              { path: 'preferences', element: <ProfilePreferencesPage /> },
              {
                path: 'skills',
                element: <SkillsPage />,
              },
              { path: 'kudos', element: <KudosPage /> },
              {
                path: 'settings',
                children: [
                  { index: true, element: <ProfileSettingsIndexPage /> },
                  { path: 'password', element: <ProfileUpdatePasswordPage /> },
                  { path: 'mfa', element: <ProfileMfaSetupPage /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'analysis',
        element: <AppLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [{ index: true, element: <AnalysisOverviewPage /> }],
      },
      {
        path: 'groups',
        element: <AppLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [{ index: true, element: <GroupsPage /> }],
      },
      {
        path: 'company-profile',
        element: <AppLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [
          {
            element: <CompanyProfileLayout />,
            children: [
              { index: true, element: <CompanySettingsIndexPage /> },
              {
                path: 'information',
                element: <CompanySettingsPage />,
              },
              {
                path: 'mfa',
                element: <CompanyMfaSetupPage />,
              },
              {
                path: 'settings',
                element: <CompanyPreferencesPage />,
              },
              {
                path: 'locations',
                element: <FunctionalLocationsPage />,
              },
              {
                path: 'users',
                element: <ManageUsersPage />,
              },
              {
                path: 'mfa',
                element: <CompanyMfaSetupPage />,
              },
              {
                path: 'billing',
                element: <BillingPage />,
              },
              {
                path: 'rca-settings',
                element: <CompanyRcaSettingsPage />,
              },
              {
                path: 'rca-settings-themes',
                element: <CompanyThemesPage />,
              },
              {
                path: 'rca-settings-types',
                element: <CompanyCaseAnalysisTypesPage />,
              },
              {
                path: 'rca-settings-impacts',
                element: <CompanyCaseImpactTypesPage />,
              },
              {
                path: 'rca-settings-case-types',
                element: <CompanyCaseTypesPage />,
              },
              {
                path: 'rca-settings-labels',
                element: <CompanySystemTextPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'tasks',
        element: <AppLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [{ index: true, element: <TasksIndexPage /> }],
      },
      {
        path: 'help-centre',
        element: <AppLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [
          { index: true, element: <HelpCentreIndexPage /> },
          {
            path: 'faq',
            children: [{ index: true, element: <FaqPage /> }],
          },
          {
            path: 'video',
            children: [{ index: true, element: <VideoPage /> }],
          },
          {
            path: 'category/:id',
            children: [{ index: true, element: <CategoryPage /> }],
          },
          {
            path: 'article/:id',
            children: [{ index: true, element: <ArticleDetailPage /> }],
          },
          {
            path: 'article',
            children: [{ index: true, element: <ArticlePage /> }],
          },
        ],
      },
      {
        path: 'oauth',
        element: <AuthLayout />,
        errorElement: <ErrorRouteCatcher goTo="/" />,
        children: [{ index: true, element: <OAuthPage /> }],
      },
      {
        path: 'auth',
        element: <AuthLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [
          { path: 'create-account', element: <CreateAccountPage /> },
          { path: 'logout', element: <LogoutCapturePage /> },
          {
            path: 'confirm-email-request',
            element: <ConfirmEmailRequestPage />,
          },
          {
            path: 'accept-invite',
            element: <AcceptInvitePage />,
          },
          {
            path: 'create-password',
            element: <CreatePasswordPage />,
          },
          {
            path: 'create-company',
            element: <CreateCompanyPage />,
          },
          {
            path: 'create-account-progress',
            element: <CreateAccountProgressPage />,
          },
          {
            path: 'login',
            element: <LoginPage />,
          },
          {
            path: 'login-error',
            element: <LoginErrorPage />,
          },
          {
            path: 'mfa',
            element: <MfaPage />,
          },
          {
            path: 'select-company',
            element: <SelectCompanyPage />,
          },
          {
            path: 'forgot-password',
            element: <ForgotPasswordPage />,
          },
          {
            path: 'reset-password',
            element: <ResetPasswordPage />,
          },
          {
            path: 'sso/callback',
            element: <SSOCallbackPage />,
          },
        ],
      },
      {
        path: 'rca',
        element: <RcaResetter />,
        children: [
          {
            path: 'create',
            errorElement: <ErrorRouteCatcher />,
            children: [
              { index: true, element: <RcaCreationStepsPage /> },
              {
                path: 'steps',
                element: <RcaCreationStepsPage />,
              },
            ],
          },
          {
            path: 'edit/:caseId',
            errorElement: <ErrorRouteCatcher />,
            children: [
              {
                index: true,
                element: <EditCasePage />,
              },
            ],
          },
          {
            path: 'outcome/:caseId',
            errorElement: <ErrorRouteCatcher />,
            children: [{ index: true, element: <RcaOutcomePage /> }],
          },
          {
            path: 'tabs/:caseId',
            errorElement: <ErrorRouteCatcher />,
            children: [
              { index: true, element: <RcaEditorOverview /> },
              { path: 'overview', element: <RcaEditorOverview /> },
              { path: 'chart', element: <RcaEditorGraph /> },
              { path: 'tasks', element: <RcaEditorTasks /> },
              { path: 'impacts', element: <RcaEditorImpacts /> },
              { path: 'evidence', element: <RcaEditorEvidence /> },
              { path: 'solutions', element: <RcaEditorSolutions /> },
              { path: 'files', element: <RcaEditorFiles /> },
            ],
          },
        ],
      },
      {
        path: 'register-interest',
        element: <AppLandingLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [{ index: true, element: <RegisterInterestPage /> }],
      },
      {
        path: 'outcome/:caseId/report/:outcomeId',
        element: <OutcomeReportPage />,
        errorElement: <ErrorRouteCatcher />,
      },
    ],
  },
]);

export default appRoutes;

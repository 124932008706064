import requireAuth from '@components/hoc/with-required-auth-hoc';
import useCompanyImpacts from './company-impacts-hook';
import SubPageTitle from '@components/menu-items/subpage-title';
import { Gap } from '@components/layout-util-components/gap';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { WCTTableFilters } from '@components/table';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import HierachyItem from '@pages/app/company-profile/rca-settings/components/hierachy-item';
import { Button } from '@mui/material';
import AddCategoryModal, {
  AddCategoryModalProps,
} from '@pages/app/company-profile/rca-settings/company-impacts/components/add-impact-category-modal';
import EditImpactModal, {
  EditImpactModalProps,
} from '@pages/app/company-profile/rca-settings/company-impacts/components/edit-impact-modal';
import { LoadingIndicator } from '@components/loading-indicator';
import AddImpactModal, {
  AddImpactModalProps,
} from '@pages/app/company-profile/rca-settings/company-impacts/components/add-impact-modal';
import { CaseImpactCategoryResource } from '@api/types/case-impact-type/case-impact-category.resource';
import { CaseImpactType } from '@api/types/case-impact-type/case-impact-type';
import { useGetAllMetricDetailQuery } from '@api/endpoints/case-impact-type-metric.api';
import useCompanyProfileLayout from '@components/layouts/app/company-profile-layout-hook';
import EditImpactCategoryModal, {
  EditImpactCategoryModalProps,
} from './components/edit-impact-category-modal';

function CompanyImpactsPage() {
  const { data: metricOptions, isLoading: loadingMetricOptions } =
    useGetAllMetricDetailQuery();

  const {
    impactCategories,
    loadingCategories,
    fetchingCategories,
    toggleCategory,
    setSearchText,
    searchText,
  } = useCompanyImpacts();

  const { companyProfileDefaultUrl } = useCompanyProfileLayout();

  const { showPopup } = useUiPopup();

  const openAddCategoryModal = async () => {
    await showPopup<AddCategoryModalProps, void>(AddCategoryModal, {
      metricOptions: metricOptions ?? [],
      // allowScroll: true,
    });
  };

  const openAddImpactModal = async (category: CaseImpactCategoryResource) => {
    await showPopup<AddImpactModalProps, void>(AddImpactModal, {
      categoryId: category.caseImpactTypeCategoryId,
      metricOptions: metricOptions ?? [],
    });
  };

  const openEditImpactModal = (impact: CaseImpactType) => {
    showPopup<EditImpactModalProps, void>(EditImpactModal, {
      impact: impact,
      metricOptions: metricOptions ?? [],
    });
  };

  const openEditImpactCategoryModal = (impact: CaseImpactCategoryResource) => {
    showPopup<EditImpactCategoryModalProps, void>(EditImpactCategoryModal, {
      impact: impact,
    });
  };

  return (
    <>
      <SubPageTitle
        items={[
          { label: 'Company Profile', path: companyProfileDefaultUrl },
          { label: 'RCA Settings', path: '/company-profile/rca-settings' },
          { label: 'Impact Configuration' },
        ]}
      />
      <Gap size={32} />
      <WCTTableFilters
        search={searchText}
        onSearch={setSearchText}
        isLoading={loadingCategories}
        isFetching={fetchingCategories}
      >
        <Button variant="contained" onClick={openAddCategoryModal}>
          <FontAwesomeIcon icon={faPlus} />
          Add Category
        </Button>
      </WCTTableFilters>
      <Gap size={20} />
      {loadingCategories || loadingMetricOptions ? (
        <LoadingIndicator show />
      ) : (
        impactCategories?.map((category) => (
          <Fragment key={category.caseImpactTypeCategoryId}>
            <HierachyItem
              canEdit={!category.hasRCA}
              isActive={!category.disabled}
              onEdit={() => {
                openEditImpactCategoryModal(category);
              }}
              name={category.name}
              onToggleActive={(_) => toggleCategory(category)}
              addButton={{
                label: 'Add Impact',
                onPressed: () => openAddImpactModal(category),
              }}
              subItems={category.caseImpactTypes.map((impact) => ({
                id: impact.caseImpactTypeId,
                label: impact.name,
                canEdit: !(category.disabled || impact.disabled),
                onEdit: (_) => {
                  openEditImpactModal(impact);
                },
              }))}
            />
            <Gap size={10} />
          </Fragment>
        ))
      )}
      <Gap size={10} />
    </>
  );
}

export default requireAuth(CompanyImpactsPage);

import {
  Box,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';
import { TextFieldMaxSize } from '@components/input/text-field';
import { optionalLabel } from '@util/string-util';
import { useEffect, useState } from 'react';
import { formatCurrency, parseCurrency } from '@util/currency-util';
import getSymbolFromCurrency from 'currency-symbol-map';
import Row from '@components/layout-util-components/row';
import { HtmlTooltip } from '@components/tooltip/default-tooltip';
import { ReactComponent as CircleQuestion } from '@assets/svgs/circle-question.svg';
import { ReactComponent as CircleQuestionFull } from '@assets/svgs/circle-question-full.svg';

interface Props {
  label?: string | DefaultTFuncReturn;
  name?: string;
  required?: boolean;
  value?: string;
  defaultValue?: string;
  placeholder?: string | DefaultTFuncReturn;
  error?: string | DefaultTFuncReturn;
  onChange: (value: string) => void;
  onBlur?: () => void;
  readonly?: boolean;
  className?: string;
  currency?: string;
  tooltip?: React.ReactNode;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  padding: 0,
  maxWidth: TextFieldMaxSize.small,
  '& .MuiInputBase-root': {
    paddingLeft: '11px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: `2px solid ${theme.palette.common.grey50}`,
    minHeight: '56px',
    '&::before': {
      display: 'none',
    },
    '&:hover::before': {
      display: 'none',
    },
    '&.Mui-focused': {
      border: `2px solid ${theme.palette.primary.main}`,
      '&::after': {
        display: 'none',
      },
    },
    '& input:-webkit-autofill': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:hover': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:focus': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:active': {
      WebkitBackgroundClip: 'text',
    },
  },
  '.MuiInputAdornment-root, .MuiInputAdornment-filled': {
    height: 'unset',
    width: 49,
    maxHeight: 'unset',
    alignSelf: 'stretch',
    justifyContent: 'center',
    marginLeft: -11,
    backgroundColor: '#CECECE',
  },
}));

const HoverableIcon = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  marginLeft: 'auto',
  marginRight: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '& .default-icon': {
    position: 'absolute',
    opacity: 1,
    transition: 'opacity 0.3s ease',
  },
  '& .hover-icon': {
    position: 'absolute',
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  '&:hover .default-icon': {
    opacity: 0,
  },
  '&:hover .hover-icon': {
    opacity: 1,
  },
}));

const StyledCircleQuestion = styled(CircleQuestion)(({ theme }) => ({
  width: 20,
  height: 20,
}));

const StyledCircleQuestionFull = styled(CircleQuestionFull)(({ theme }) => ({
  width: 20,
  height: 20,
}));

export default function WCTCurrencyField({
  label,
  name,
  required,
  value,
  defaultValue,
  error,
  placeholder,
  onChange,
  onBlur,
  readonly,
  className,
  tooltip,
  currency = 'GBP',
}: Props) {
  const [currencyValue, setCurrencyValue] = useState<string | undefined>(
    formatCurrency(value || '', currency)
  );

  const onInputChange = (newValue: string) => {
    onChange(parseCurrency(newValue));
    setCurrencyValue(newValue);
  };

  const onInputBlur = () => {
    setCurrencyValue(formatCurrency(currencyValue || '', currency));
    onBlur && onBlur();
  };

  useEffect(() => {
    setCurrencyValue(formatCurrency(defaultValue || '', currency));
  }, [defaultValue, setCurrencyValue, currency]);

  const [open, setOpen] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {label && (
        <Typography style={{ paddingBottom: '10px' }}>
          {optionalLabel(label, required)}
        </Typography>
      )}
      <Row>
        <StyledTextField
          variant="filled"
          name={name}
          className={className}
          helperText={error}
          placeholder={placeholder as string | undefined}
          required={required}
          fullWidth
          value={currencyValue}
          error={!!error}
          disabled={readonly}
          onChange={(e: any) => {
            if (e == null) {
              onInputChange('');
            } else if (typeof e === 'string') {
              onInputChange(e);
            } else {
              onInputChange(e.target.value);
            }
          }}
          onBlur={onInputBlur}
          type="text"
          hiddenLabel={true}
          InputProps={{
            readOnly: readonly,
            startAdornment: (
              <InputAdornment position="start">
                {getSymbolFromCurrency(currency)}
              </InputAdornment>
            ),
          }}
        />
        {tooltip && (
          <Box sx={{ paddingLeft: '10px' }}>
            <HtmlTooltip
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={tooltip}
              arrow
              clickedAway={handleTooltipClose}
              slotProps={{
                popper: {
                  disablePortal: true,
                },
              }}
            >
              <HoverableIcon
                onClick={(e) => {
                  e.stopPropagation();
                  handleTooltipOpen();
                }}
              >
                <StyledCircleQuestion className="default-icon" />
                <StyledCircleQuestionFull className="hover-icon" />
              </HoverableIcon>
            </HtmlTooltip>
          </Box>
        )}
      </Row>
    </>
  );
}

import BadgeInfo from '@components/outcome-report/components/badge-info';
import RolloverInfo from '@components/rollover-info/rollover-info';
import useRolloverInfo from '@components/rollover-info/use-rollover-info';
import { useTheme } from '@mui/material';
import { truncateString } from '@util/string-util';
import { CSSProperties } from 'react';

interface Props {
  category: string;
  limit?: number;
}

export default function ImpactCategoryBadge({ category, limit }: Props) {
  const { palette } = useTheme();

  const categoryRollOver = useRolloverInfo(
    category,
    limit !== undefined && category?.length > limit
  );

  const overrideStyles = {
    backgroundColor: palette.blue.dark,
    borderRadius: '4px',
    '& .flat-badge': {
      overflow: 'hidden',
      margin: '8px 14px',
      maxHeight: '30px',
      textAlign: 'left',
      wordBreak: 'break-all',
    },
  } as CSSProperties;

  return (
    <>
      <span ref={categoryRollOver.ref}>
        <BadgeInfo
          value={
            limit !== undefined ? truncateString(category, limit) : category
          }
          backgroundColor={palette.blue.dark}
          color="#fff"
          //height="30px"
          padding="0px"
          sx={overrideStyles}
        />
      </span>
      <RolloverInfo {...categoryRollOver} />
    </>
  );
}

import {
  Box,
  IconButton,
  InputAdornment,
  styled,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { ReactComponent as VisibleIcon } from '@assets/svgs/visible.svg';
import { ReactComponent as HiddenIcon } from '@assets/svgs/hidden.svg';
import { DefaultTFuncReturn } from 'i18next';
import { ErrorData } from '@api/types/api-error';
import { optionalLabel } from '@util/string-util';

export enum TextFieldMaxSize {
  small = 269,
  medium = 350,
  Large = 500,
}

interface Props {
  label?: string | DefaultTFuncReturn;
  name?: string;
  required?: boolean;
  multiline?: boolean;
  obscure?: boolean;
  value?: string;
  placeholder?: string | DefaultTFuncReturn;
  error?: ErrorData;
  helperText?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  readonly?: boolean;
  className?: string;
  maxWidth?: TextFieldMaxSize;
  maxLength?: number;
  addRightPadding?: boolean;
  disablePaste?: boolean;
  showCharCount?: boolean;
}

const Container = styled(Box)(({ theme: { palette } }) => ({
  position: 'relative',
  width: '100%',
}));

const StyledTextField = styled(TextField)(({ theme: { palette } }) => ({
  margin: 0,
  padding: 0,
  '.MuiFormHelperText-root': {
    fontSize: 12,
    margin: '4px 0 0 2px',
  },
  label: {
    color: palette.common.textMid,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    transform: 'translate(12px, 16px) scale(1)',
    '&.MuiFormLabel-filled, &.Mui-focused': {
      color: palette.common.textMid,
      transform: 'translate(12px, 3px) scale(0.75)',
    },
  },
  '.MuiInputBase-root': {
    boxSizing: 'border-box',
    minHeight: '48px',
    display: 'flex',
    borderRadius: 4,
    border: `1px solid ${palette.common.grey70}`,
    background: 'white',
    '&::before': {
      display: 'none',
    },
    '&:hover': {
      background: 'white',
    },
    '&:hover::before': {
      display: 'none',
    },
    '&.Mui-focused': {
      border: `1px solid ${palette.primary.main}`,
      outline: '5px solid rgba(255, 108, 0, 0.12)',
      background: 'white',
      '&::after': {
        display: 'none',
      },
    },
    '.MuiInputAdornment-root': {
      button: {
        svg: {
          fill: palette.blue.icon,
          width: 21,
        },
      },
    },
    '&.Mui-disabled,&.fake-disabled': {
      border: `1px solid ${palette.disabledText}`,
      color: palette.disabledText,
      textFillColor: palette.disabledText,
      background: 'transparent',
      '&:hover': {
        color: palette.disabledText,
        border: `1px solid ${palette.disabledText}`,
        textFillColor: palette.disabledText,
        background: 'transparent',
      },
      '.MuiInputBase-readOnly': {
        color: palette.disabledText,
        textFillColor: palette.disabledText,
        background: 'transparent',
      },
    },
    '& input:-webkit-autofill': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:hover': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:focus': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:active': {
      WebkitBackgroundClip: 'text',
    },
  },
  input: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px' /* 150% */,
    letterSpacing: '0.15px',
  },
}));

export default function WCTTextField({
  label,
  name,
  required,
  multiline,
  obscure,
  value,
  error,
  placeholder,
  onChange,
  helperText,
  onBlur,
  readonly,
  className,
  maxWidth,
  maxLength,
  addRightPadding,
  disablePaste,
  showCharCount,
}: Props) {
  const [hidden, setHidden] = useState(obscure);

  return (
    <Container sx={{ marginBottom: showCharCount ? '24px' : undefined }}>
      <StyledTextField
        sx={{
          maxWidth: maxWidth,
        }}
        variant="filled"
        label={optionalLabel(label, required)}
        name={name}
        className={className}
        helperText={error || helperText}
        placeholder={placeholder as string | undefined}
        required={required}
        fullWidth
        value={value || ''}
        error={!!error}
        disabled={readonly}
        multiline={multiline}
        onPaste={(e) => {
          if (disablePaste) {
            e.preventDefault();
          }
        }}
        minRows={multiline ? 3 : 1}
        maxRows={multiline ? 5 : 1}
        onChange={(e: any) => {
          if (e == null) {
            onChange('');
          } else if (typeof e === 'string') {
            onChange(e);
          } else {
            onChange(e.target.value);
          }
        }}
        onBlur={onBlur}
        type={obscure && hidden ? 'password' : 'text'}
        hiddenLabel={!label}
        inputProps={{
          maxLength: maxLength,
          sx: {
            paddingRight: addRightPadding ? '42px' : undefined,
          },
        }}
        InputProps={{
          readOnly: readonly,
          endAdornment: obscure ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setHidden((v) => !v)}
                //onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {hidden ? (
                  <SvgIcon>
                    <HiddenIcon />
                  </SvgIcon>
                ) : (
                  <SvgIcon>
                    <VisibleIcon />
                  </SvgIcon>
                )}
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
      {maxLength && showCharCount && (
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            bottom: '-20px',
            right: 0,
            color: 'gray',
          }}
        >
          {value?.length ?? 0}/{maxLength} Characters
        </Typography>
      )}
    </Container>
  );
}

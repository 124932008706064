import { CollaborationState } from '@pages/app/rca/create/steps/collaboration-step-hook';
import Column from '@components/layout-util-components/column';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { ReactComponent as NoContributorsIcon } from '@assets/svgs/no-contributers.svg';
import { Typography } from '@mui/material';
import { WCTTable, WCTTableFilters } from '@components/table';
import CollaborateRecomendUserRow from '@pages/app/rca/create/components/collaborate-recomend-user-row';
import CollaborateInvitedUserRow from '@pages/app/rca/create/components/collaborate-invited-user-row';
import { LoadingIndicator } from '@components/loading-indicator';
import { Gap } from '@components/layout-util-components/gap';
import NoCollaboratorsInfo from './no-collaborators-info';
import { useNavigate } from 'react-router-dom';
import { CaseRoleOption } from '@api/types/case-role/case-role-option';

interface Props {
  state: CollaborationState;
}

export default function CollaborateUsers({ state }: Props) {
  const {
    isLoading,
    contributors,
    roleOptions,
    contributorSearch,
    recomendedUsers,
    onInviteUser,
    onUpdateUserRole,
    onRemoveUser,
    fetchingContributors,
    fetchingRecommendedUsers,
    onSetChainEditor,
    recomendedUsersPagination,
    contributorsPagination,
  } = state;

  const navigate = useNavigate();

  const hasCollaborators = !(
    contributors?.totalCount === 1 && recomendedUsers?.totalCount === 0
  );

  return (
    <>
      <Gap size={20} />
      {hasCollaborators ? (
        <Column>
          <WCTTableFilters
            isLoading={isLoading}
            isFetching={fetchingContributors || fetchingRecommendedUsers}
            search={contributorSearch.value}
            onSearch={contributorSearch.set}
            searchPlaceholder="Type to search for user"
            size="large"
          />
          <Gap size={24} />

          {isLoading ? (
            <LoadingIndicator show />
          ) : contributors?.totalCount === 0 ? (
            <OnboardingEntryContainer
              icon={<NoContributorsIcon />}
              title="You don’t currently have any contributors"
              message="Currently you haven’t added any team members to allow collaboration on this RCA"
            />
          ) : (
            <>
              <Typography>{`Contributors (${contributors?.totalCount})`}</Typography>
              <WCTTable
                noRowClick
                pagination={contributorsPagination}
                count={contributors?.totalCount ?? 0}
                headers={[
                  { title: 'Name/Job Title' },
                  { title: 'Role' },
                  { title: 'Skills' },
                  { title: 'Chain Editor' },
                  { title: 'Analysis Role' },
                  { title: '' },
                ]}
              >
                {contributors?.model.map((user) => (
                  <CollaborateInvitedUserRow
                    key={user.companyUserId}
                    user={user}
                    onRoleChange={(newRole: CaseRoleOption) =>
                      onUpdateUserRole(user, newRole)
                    }
                    onRemove={() => onRemoveUser(user)}
                    roleOptions={roleOptions!}
                    onSetToChainEditor={() => onSetChainEditor(user)}
                  />
                ))}
              </WCTTable>
              {recomendedUsers && recomendedUsers?.totalCount > 0 && (
                <>
                  <Gap size={32} />
                  <Typography>{`Recommended (${recomendedUsers?.totalCount})`}</Typography>
                  <WCTTable
                    noRowClick
                    pagination={recomendedUsersPagination}
                    count={recomendedUsers?.totalCount ?? 0}
                    headers={[
                      { title: 'Name/Job Title' },
                      { title: 'Role' },
                      { title: 'Skills' },
                      { title: 'RCA(s)' },
                      { title: 'Kudos Points' },
                      { title: '' },
                    ]}
                  >
                    {recomendedUsers?.model.map((user) => (
                      <CollaborateRecomendUserRow
                        key={user.companyUserId}
                        user={user}
                        onInvite={() => onInviteUser(user.companyUserId)}
                      />
                    ))}
                  </WCTTable>
                </>
              )}
            </>
          )}
          <Gap size={128} />
        </Column>
      ) : (
        <NoCollaboratorsInfo
          onCreateClicked={() => {
            navigate('/company-profile/users');
          }}
        />
      )}
    </>
  );
}

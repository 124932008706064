import { api } from '@api/api';
import { makeError } from '@api/types/api-error';
import { maybeUpdateCache } from '@api/cache-util';
import { CreateCaseTypeCategoryRequest } from '@api/types/company/company-case-type-category/create-case-category.request';
import { UpdateCaseTypeCategoryRequest } from '@api/types/company/company-case-type-category/update-case-category.request';
import { CaseTypeCategoryResource } from '@api/types/company/company-case-type-category/case-type-category.resource';

const caseTypeCategoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyCaseTypeCategories: build.query<
      CaseTypeCategoryResource[],
      string | undefined
    >({
      query: (searchText) => ({
        url: 'company/caseTypeCategory',
        method: 'GET',
        params: {
          searchText,
        },
      }),
      providesTags: (response) =>
        response
          ? [
              'CaseType',
              ...response.map((data) => ({
                id: data.caseTypeCategoryId,
                type: 'CaseType' as const,
              })),
            ]
          : [],
    }),
    createCaseTypeCategory: build.mutation<void, CreateCaseTypeCategoryRequest>(
      {
        query: (body) => ({
          url: 'company/caseTypeCategory',
          method: 'POST',
          body,
        }),
        transformErrorResponse: (error) =>
          makeError<CreateCaseTypeCategoryRequest>(error),
        invalidatesTags: (_, error) => (!error ? ['CaseType'] : []),
      }
    ),
    updateCaseTypeCategory: build.mutation<void, UpdateCaseTypeCategoryRequest>(
      {
        query: (body) => ({
          url: 'company/caseTypeCategory',
          method: 'PUT',
          body,
        }),
        transformErrorResponse: (error) =>
          makeError<UpdateCaseTypeCategoryRequest>(error),
        invalidatesTags: (_, error) => (!error ? ['CaseType'] : []),
      }
    ),
    removeCaseTypeCategory: build.mutation<void, number>({
      query: (id) => ({
        url: `company/caseTypeCategory/${id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => makeError<never>(error),
      invalidatesTags: (_, error) => (!error ? ['CaseType'] : []),
    }),
    enableCaseTypeCategory: build.mutation<void, number>({
      query: (id) => ({
        url: `company/caseTypeCategory/${id}/enable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CaseType' }, 'CaseType'] : [],
      onQueryStarted: async (arg, { queryFulfilled }) => {
        const patches = maybeUpdateCache('CaseType', arg, [
          {
            api: caseTypeCategoryApi,
            endpoint:
              caseTypeCategoryApi.endpoints.getCompanyCaseTypeCategories,
            callback: (d: CaseTypeCategoryResource[]) => {
              d.forEach((category) => {
                if (category.caseTypeCategoryId === arg) {
                  category.disabled = undefined;
                }
              });
            },
          },
        ]);

        try {
          await queryFulfilled;
        } catch (e) {
          patches.forEach((x) => x.undo());
        }
      },
    }),
    disableCaseTypeCategory: build.mutation<void, number>({
      query: (id) => ({
        url: `company/caseTypeCategory/${id}/disable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CaseType' }, 'CaseType'] : [],
      onQueryStarted: async (arg, { queryFulfilled }) => {
        const patches = maybeUpdateCache('CaseType', arg, [
          {
            api: caseTypeCategoryApi,
            endpoint:
              caseTypeCategoryApi.endpoints.getCompanyCaseTypeCategories,
            callback: (d: CaseTypeCategoryResource[]) => {
              d.forEach((category) => {
                if (category.caseTypeCategoryId === arg) {
                  category.disabled = Date().toString();
                }
              });
            },
          },
        ]);

        try {
          await queryFulfilled;
        } catch (e) {
          patches.forEach((x) => x.undo());
        }
      },
    }),
  }),
});

export const {
  useGetCompanyCaseTypeCategoriesQuery,
  useRemoveCaseTypeCategoryMutation,
  useCreateCaseTypeCategoryMutation,
  useUpdateCaseTypeCategoryMutation,
  useDisableCaseTypeCategoryMutation,
  useEnableCaseTypeCategoryMutation,
} = caseTypeCategoryApi;

import { styled, TableCell, TableRow, Typography } from '@mui/material';
import { ElipsisButton } from '@components/buttons';
import { useGetMetricDetailQuery } from '@api/endpoints/case-impact-type-metric.api';
import { InlineBoxSkeleton } from '@components/skeletons';
import { CaseImpactUtil } from '@util/case-impact-util';
import { useState } from 'react';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';
import { CaseImpactResource } from '@api/types/case/case-impact/case-impact.resource';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import TrackedIndicator from '@components/outcome-report/components/tracked-indicator';
import ImpactCategoryBadge from '@components/outcome-report/components/impact-category-badge';
import OutcomeSeverityBadge from '@components/outcome-report/components/outcome-severity-badge';
import ImpactValueBadge from '@components/outcome-report/components/impact-value-badge';
import { truncateString } from '@util/string-util';
import useRolloverInfo from '@components/rollover-info/use-rollover-info';
import RolloverInfo from '@components/rollover-info/rollover-info';

interface Props {
  info: CaseImpactResource;
  caseId: number;
  onEditClick: () => void;
  onDeleteClick: (id: number) => Promise<boolean>;
  canContribute: boolean;
}

const Container = styled(TableRow)(({ onClick }) => ({
  ...(onClick == null && {
    cursor: 'default !important',
    '*': {
      cursor: 'default !important',
    },
  }),

  '.tick': {
    width: 30,
    height: 'auto',
  },
}));

export default function ImpactInfoRow({
  info: {
    caseImpactId,
    name,
    caseImpactTypeMetricId,
    tracked,
    impactValue,
    caseImpactTypeMetric,
    caseImpactTypeName,
    description,
    caseImpactSeverityName,
  },
  onEditClick,
  onDeleteClick,
  canContribute,
}: Props) {
  const [isDeleting, setIsDeleting] = useState(false);

  const currency = useAppSelector(selectCurrentRcaCurrency);

  const { data: metricResource, isLoading } = useGetMetricDetailQuery(
    caseImpactTypeMetricId
  );

  const impactNameRollOver = useRolloverInfo(name, name?.length > 50);

  const impactDescriptionRollOver = useRolloverInfo(
    description,
    description?.length > 100
  );

  return (
    <Container
      sx={{ minWidth: '732px' }}
      onClick={!isDeleting ? onEditClick : undefined}
    >
      <TableCell width={72}>
        <TrackedIndicator tracked={tracked} />
      </TableCell>
      <TableCell width={250} className="">
        <ImpactCategoryBadge category={caseImpactTypeName} limit={60} />
      </TableCell>
      <TableCell width={250}>
        <Typography ref={impactNameRollOver.ref} variant="body1">
          {truncateString(name, 50)}
        </Typography>
        <RolloverInfo {...impactNameRollOver} />
      </TableCell>
      <TableCell>
        <Typography ref={impactDescriptionRollOver.ref} variant="body2">
          {truncateString(description, 100)}
        </Typography>
        <RolloverInfo {...impactDescriptionRollOver} />
      </TableCell>
      <TableCell width={125}>
        <OutcomeSeverityBadge severity={caseImpactSeverityName} />
      </TableCell>
      <TableCell width={175}>
        <Typography>
          {isLoading ? (
            <InlineBoxSkeleton />
          ) : (
            <ImpactValueBadge
              value={CaseImpactUtil.getImpactValue(
                impactValue,
                caseImpactTypeMetric,
                metricResource!.options,
                currency
              )}
            />
          )}
        </Typography>
      </TableCell>
      <TableCell width={64}>
        {canContribute ? (
          isDeleting ? (
            <FetchingIndicator show />
          ) : (
            <ElipsisButton
              actions={[
                {
                  label: 'Edit',
                  onClick: onEditClick,
                },
                {
                  label: 'Delete',
                  onClick: () => {
                    setIsDeleting(true);
                    onDeleteClick(caseImpactId).finally(() =>
                      setIsDeleting(false)
                    );
                  },
                },
              ]}
            />
          )
        ) : null}
      </TableCell>
    </Container>
  );
}

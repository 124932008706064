import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import Column from '@components/layout-util-components/column';
import { Button, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import WCTTextField from '@components/input/text-field';
import useField from '@hooks/use-field-hook';
import { lessThan, required } from '@util/validators';
import ActionsRow from '@components/layout-util-components/actions-row';
import React from 'react';
import Spacer from '@components/layout-util-components/spacer';
import WCTSwitchField from '@components/input/switch-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { usePageAlertVariants } from '@components/alerts';
import useBusyAction from '@hooks/use-busy-action-hook';
import { isApiError } from '@api/types/api-error';
import { invalidation } from '@api/cache-util';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import AsyncButton from '@components/buttons/async-button';
import { CompanyCaseTypeResource } from '@api/types/company/company-case-type/company-case-type.resource';
import { CaseTypeCategoryResource } from '@api/types/company/company-case-type-category/case-type-category.resource';
import {
  useDisableCaseTypeCategoryMutation,
  useEnableCaseTypeCategoryMutation,
  useRemoveCaseTypeCategoryMutation,
  useUpdateCaseTypeCategoryMutation,
} from '@api/endpoints/company/company-case-type-category.api';
import {
  useDisableCompanyCaseTypeMutation,
  useEnableCompanyCaseTypeMutation,
  useRemoveCompanyCaseTypeMutation,
  useUpdateCompanyCaseTypeMutation,
} from '@api/endpoints/company/company-case-type.api';
import { UpdateCaseTypeCategoryRequest } from '@api/types/company/company-case-type-category/update-case-category.request';
import { UpdateCompanyCaseTypeRequest } from '@api/types/company/company-case-type/update-company-case-type.request';
import useRolloverInfo from '@components/rollover-info/use-rollover-info';
import RolloverInfo from '@components/rollover-info/rollover-info';

export interface EditCaseTypeModalProps extends ModalProps<void> {
  caseType: CompanyCaseTypeResource | CaseTypeCategoryResource;
}

function EditCaseTypeModal({ caseType, onClose }: EditCaseTypeModalProps) {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const [updateCategory] = useUpdateCaseTypeCategoryMutation();
  const [enableCategory] = useEnableCaseTypeCategoryMutation();
  const [disableCategory] = useDisableCaseTypeCategoryMutation();
  const [updateCaseType] = useUpdateCompanyCaseTypeMutation();
  const [enableCaseType] = useEnableCompanyCaseTypeMutation();
  const [disableCaseType] = useDisableCompanyCaseTypeMutation();

  const [deleteCategory] = useRemoveCaseTypeCategoryMutation();
  const [deleteCaseType] = useRemoveCompanyCaseTypeMutation();

  const name = useField<string>(
    [required(), lessThan(200, 'Enter 200 characters or less')],
    caseType.name
  );
  const isActive = useField<boolean>([required()], !caseType.disabled);

  const { validateAll, isDirty } = useFieldsWatcher([name, isActive]);

  const isCategory = !('caseTypeId' in caseType);

  const id = isCategory
    ? (caseType as CaseTypeCategoryResource).caseTypeCategoryId
    : (caseType as CompanyCaseTypeResource).caseTypeId;

  const itemType = isCategory ? 'Category' : 'Business Function';
  const deleteButtonRollOver = useRolloverInfo(
    isCategory
      ? 'This Category has business functions in use and so can no longer be deleted'
      : 'This business function is in use and so can no longer be deleted',
    caseType.hasRCA
  );

  const [onSave, isSaving] = useBusyAction(async () => {
    if (!isDirty) {
      onClose();
      return;
    }

    try {
      if (caseType.hasRCA) {
        if (!isActive.validate(true)) {
          return;
        }

        if (!isActive.value) {
          if (isCategory) {
            await disableCategory(id).unwrap();
          } else {
            await disableCaseType(id).unwrap();
          }
        } else {
          if (isCategory) {
            await enableCategory(id).unwrap();
          } else {
            await enableCaseType(id).unwrap();
          }
        }
      } else {
        if (!validateAll()) {
          return;
        }

        if (isCategory) {
          await updateCategory({
            caseTypeCategoryId: id,
            name: name.value,
            disable: !isActive.value,
          }).unwrap();
        } else {
          await updateCaseType({
            caseTypeId: id,
            caseTypeCategoryId: caseType.caseTypeCategoryId,
            name: name.value,
            disable: !isActive.value,
          }).unwrap();
        }
      }
      await invalidation('CaseType');

      showSuccessMessage(`${itemType} updated successfully`);

      onClose();
    } catch (error) {
      if (isApiError<UpdateCaseTypeCategoryRequest>(error)) {
        showErrorMessage(
          error.errors?.disable ??
            error.errors?.caseTypeCategoryId ??
            error.message
        );
        name.setError(error.errors?.name);
      } else if (isApiError<UpdateCompanyCaseTypeRequest>(error)) {
        const errors = error.errors as any;
        showErrorMessage(
          errors?.caseTypeCategoryId ?? errors?.caseTypeId ?? error.message
        );

        name.setError(errors?.name);
      }
    }
  });

  const [onDelete, isDeleting] = useBusyAction(async () => {
    try {
      await (isCategory ? deleteCategory(id) : deleteCaseType(id)).unwrap();

      await invalidation('CaseType');

      showSuccessMessage(`${itemType} deleted successfully`);

      onClose();
    } catch (error) {
      if (isApiError(error)) {
        const errors = error.errors as any;
        showErrorMessage(
          errors?.caseTypeCategoryId ?? errors?.caseTypeId ?? error.message
        );
      }
    }
  });
  return (
    <>
      <Column>
        <Typography>Edit {itemType}</Typography>
        <Gap size={20} />
        <WCTTextField
          name="case_type_name"
          label={`Rename ${itemType}`}
          value={name.value}
          onChange={name.set}
          error={name.error}
          readonly={caseType.hasRCA}
          helperText={
            caseType.hasRCA
              ? isCategory
                ? 'This Category has Business Functions in use and so can no longer be renamed'
                : 'This Business Function is in use and so can no longer be renamed'
              : undefined
          }
        />
        <Gap size={13} />
        <WCTSwitchField
          label="Active"
          value={isActive.value}
          onChange={isActive.set}
          error={isActive.error}
          sx={{ alignSelf: 'flex-end' }}
        />
        <Gap size={20} />
        <ActionsRow>
          <Button variant="outlined" onClick={() => onClose()}>
            Cancel
          </Button>
          <Spacer />
          <AsyncButton
            ref={deleteButtonRollOver.ref}
            variant="outlined"
            onClick={() => {
              if (!caseType.hasRCA) {
                onDelete();
              }
            }}
            className={caseType.hasRCA ? 'fake-disabled' : ''}
            disabled={isSaving}
            isBusy={isDeleting}
          >
            <FontAwesomeIcon icon={faTrash} />
            Delete {itemType}
          </AsyncButton>
          <AsyncButton onClick={onSave} disabled={isDeleting} isBusy={isSaving}>
            Save
          </AsyncButton>
        </ActionsRow>
      </Column>
      <RolloverInfo {...deleteButtonRollOver} />
    </>
  );
}

export default withModal(EditCaseTypeModal);

import { createTheme } from '@mui/material';
import { palette } from './palette';
import { typography } from './typography';
import { chipStyles } from '@styles/components/chip.styles';
import { buttonStyles } from '@styles/components/button.styles';
import { switchStyles } from '@styles/components/switch.styles';
import { formLabelStyles } from '@styles/components/form-labels.styles';
import { linkStyles } from '@styles/components/link.styles';
import { buttonBaseStyles } from '@styles/components/button-base.styles';
import { iconButtonStyles } from '@styles/components/icon-button.styles';

const createAppTheme = (mode: 'light' | 'dark') =>
  createTheme({
    palette,
    typography,
    breakpoints: {
      values: {
        xs: 414,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiButtonBase: buttonBaseStyles(palette),
      MuiButton: buttonStyles(palette),
      MuiChip: chipStyles(palette),
      MuiFormLabel: formLabelStyles(palette),
      MuiIconButton: iconButtonStyles(palette),
      MuiLink: linkStyles(palette),
      MuiSwitch: switchStyles(palette),
    },
  });

export default createAppTheme;

import { api } from '@api/api';
import { Option } from '@api/types/option';

const caseImpactSeverityApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseImpactSeverityOptions: build.query<Option[], number>({
      query: (caseId) => ({
        url: 'caseImpactSeverity/options',
        method: 'GET',
        params: {
          caseId,
        },
      }),
      providesTags: ['CaseImpact'],
    }),
  }),
});

export const { useGetCaseImpactSeverityOptionsQuery } = caseImpactSeverityApi;

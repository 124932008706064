import { api } from '@api/api';
import { maybeUpdateCache } from '@api/cache-util';
import { makeError } from '@api/types/api-error';
import { CompanyCaseTypeResource } from '@api/types/company/company-case-type/company-case-type.resource';
import { CreateCompanyCaseTypeRequest } from '@api/types/company/company-case-type/create-company-case-type.request';
import { UpdateCompanyCaseTypeRequest } from '@api/types/company/company-case-type/update-company-case-type.request';

const caseTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyCaseTypes: build.query<CompanyCaseTypeResource[], void>({
      query: (searchText) => ({
        url: 'company/caseType',
        method: 'GET',
      }),
      providesTags: (response) =>
        response
          ? [
              'CaseType',
              ...response.map((data) => ({
                id: data.caseTypeId,
                type: 'CaseType' as const,
              })),
            ]
          : [],
    }),
    createCompanyCaseType: build.mutation<void, CreateCompanyCaseTypeRequest>({
      query: (body) => ({
        url: 'company/caseType',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateCompanyCaseTypeRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['CaseType'] : []),
    }),
    updateCompanyCaseType: build.mutation<void, UpdateCompanyCaseTypeRequest>({
      query: (body) => ({
        url: 'company/caseType',
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<UpdateCompanyCaseTypeRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['CaseType'] : []),
    }),
    removeCompanyCaseType: build.mutation<void, number>({
      query: (id) => ({
        url: `company/caseType/${id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => makeError<never>(error),
      invalidatesTags: (_, error) => (!error ? ['CaseType'] : []),
    }),
    enableCompanyCaseType: build.mutation<void, number>({
      query: (id) => ({
        url: `company/caseType/${id}/enable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CaseType' }, 'CaseType'] : [],
    }),
    disableCompanyCaseType: build.mutation<void, number>({
      query: (id) => ({
        url: `company/caseType/${id}/disable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CaseType' }, 'CaseType'] : [],
    }),
  }),
});

export const {
  useGetCompanyCaseTypesQuery,
  useCreateCompanyCaseTypeMutation,
  useRemoveCompanyCaseTypeMutation,
  useUpdateCompanyCaseTypeMutation,
  useEnableCompanyCaseTypeMutation,
  useDisableCompanyCaseTypeMutation,
} = caseTypeApi;

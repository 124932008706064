import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FullScreenLoadingIndicator } from '@components/loading-indicator';
import { useGetTimezoneOptionsQuery } from '@api/endpoints/timezone.api';
import { useGetTaskTypeOptionsQuery } from '@api/endpoints/task-type.api';
import { useGetTaskStatusOptionsQuery } from '@api/endpoints/task-detail.api';
import { useGetSystemTextQuery } from '@api/endpoints/system-text.api';
import { useGetSolutionTypeOptionsQuery } from '@api/endpoints/solution-type.api';
import { useGetSolutionTermOptionsQuery } from '@api/endpoints/solution-term.api';
import reportTypeApi, {
  useGetReportTypeOptionsQuery,
} from '@api/endpoints/report-type.api';
import { useGetFrequencyTypeOptionsQuery } from '@api/endpoints/frequency-type.api';
import { useGetEfficacyOptionsQuery } from '@api/endpoints/efficacy.api';
import { useGetDateFormatOptionsQuery } from '@api/endpoints/dateformat.api';
import { useGetCurrenciesQuery } from '@api/endpoints/currency.api';
import { useGetCaseTypeOptionsQuery } from '@api/endpoints/case-type.api';
import { useGetCaseStatusOptionsQuery } from '@api/endpoints/case-status.api';
import { useGetCaseSeverityOptionsQuery } from '@api/endpoints/case-severity.api';
import { useGetCaseRoleOptionsQuery } from '@api/endpoints/case-role.api';
import { useGetCasePriorityOptionsQuery } from '@api/endpoints/case-priority.api';
import { useAppDispatch, useAppSelector } from '@store/store';
import { useGetCaseAnalysisTypesQuery } from '@api/endpoints/case-analysis-type.api';
import { useGetCompanyLocationDetailOptionsQuery } from '@api/endpoints/company-location.api';
import {
  selectIsLoggedInAndFullyAuthenticated,
  selectUserCompanyId,
} from '@store/user/user-selectors';
import { isApp, isWctAdmin } from '@util/env';
import { useGetInstallConfigurationQuery } from '@api/endpoints/install.api';
import {
  useGetBoltOnOptionsQuery,
  useGetBoltOnTypeOptionsQuery,
} from '@api/endpoints/wct-admin/wct-admin-bolton.api';
import { useGetAllWAInstallNameOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-install.api';
import { useGetAllWAPackageOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-package.api';
import { useGetAllWARoleOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-role.api';
import { useGetAllWASubscriptionStatusOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-subscription-status.api';
import config from 'src/config';

interface CommonApiCacheProviderProps {
  refresh: () => Promise<void>;
}
export const CommonApiCacheContext = createContext<CommonApiCacheProviderProps>(
  {} as CommonApiCacheProviderProps
);

export default function CommonApiCacheProvider({
  children,
}: PropsWithChildren<{}>) {
  const [manuallyRefetching, setManuallyRefetching] = useState(false);
  const dispatch = useAppDispatch();
  const { iss } = useAppSelector((state) => state.user);

  const companyId = useAppSelector(selectUserCompanyId);
  const canCacheAppData =
    useAppSelector(selectIsLoggedInAndFullyAuthenticated) &&
    isApp &&
    iss === config.apiUrl;
  const canCacheWAData =
    useAppSelector(selectIsLoggedInAndFullyAuthenticated) &&
    iss === config.apiUrl &&
    isWctAdmin;

  const { isLoading: loadingTimezone, refetch: fetchTimezones } =
    useGetTimezoneOptionsQuery(undefined, {
      skip: !canCacheAppData,
    });
  const { isLoading: loadingTaskTypeOptions, refetch: fetchTaskTypes } =
    useGetTaskTypeOptionsQuery(undefined, {
      skip: !canCacheAppData,
    });
  const { isLoading: loadingTaskStatusOptions, refetch: fetchTaskStatus } =
    useGetTaskStatusOptionsQuery(undefined, {
      skip: !canCacheAppData,
    });
  const { isLoading: loadingSystemText, refetch: fetchSystemText } =
    useGetSystemTextQuery(undefined, {
      skip: !canCacheAppData,
    });

  const { isLoading: loadingSolutionTypes, refetch: fetchSolutionTypes } =
    useGetSolutionTypeOptionsQuery(undefined, {
      skip: !canCacheAppData,
    });
  const { isLoading: loadingSolutionTerms, refetch: fetchSolutionTerms } =
    useGetSolutionTermOptionsQuery(undefined, {
      skip: !canCacheAppData,
    });
  const { isLoading: loadingReportTypeOptions, refetch: fetchReportTypes } =
    useGetReportTypeOptionsQuery(undefined, {
      skip: !canCacheAppData,
    });
  const {
    isLoading: loadingFrequencyTypeOptions,
    refetch: fetchFrequencyOptions,
  } = useGetFrequencyTypeOptionsQuery(undefined, {
    skip: !canCacheAppData,
  });
  const { isLoading: loadingEfficacyOptions, refetch: fetchEfficacyOptions } =
    useGetEfficacyOptionsQuery(undefined, {
      skip: !canCacheAppData,
    });
  const {
    isLoading: loadingDateFormatOptions,
    refetch: fetchDateFormatOptions,
  } = useGetDateFormatOptionsQuery(undefined, {
    skip: !canCacheAppData,
  });
  const { isLoading: loadingCurrencyOptions, refetch: fetchCurrencyOptions } =
    useGetCurrenciesQuery(undefined, {
      skip: !canCacheAppData,
    });
  const { isLoading: loadingCaseTypeOptions, refetch: fetchCaseTypeOptions } =
    useGetCaseTypeOptionsQuery(
      {},
      {
        skip: !canCacheAppData,
      }
    );
  const {
    isLoading: loadingCaseStatusOptions,
    refetch: fetchCaseStatusOptions,
  } = useGetCaseStatusOptionsQuery(undefined, {
    skip: !canCacheAppData,
  });
  const {
    isLoading: loadingCaseSeverityOptions,
    refetch: fetchCaseSeverityOptions,
  } = useGetCaseSeverityOptionsQuery(undefined, {
    skip: !canCacheAppData,
  });
  const { isLoading: loadingCaseRoles, refetch: fetchCaseRoles } =
    useGetCaseRoleOptionsQuery(undefined, {
      skip: !canCacheAppData,
    });
  const {
    isLoading: loadingCasePriorityOptions,
    refetch: fetchCasePriorityOptions,
  } = useGetCasePriorityOptionsQuery(undefined, {
    skip: !canCacheAppData,
  });
  const {
    isLoading: loadingCaseAnalysisTypes,
    refetch: fetchCaseAnalysisTypes,
  } = useGetCaseAnalysisTypesQuery({}, { skip: !canCacheAppData });

  const { isLoading: loadingCompanyLocations, refetch: fetchCompanyLocations } =
    useGetCompanyLocationDetailOptionsQuery(undefined, {
      skip: !canCacheAppData,
    });

  const {
    isLoading: loadingInstallConfiguration,
    refetch: fetchInstallConfiguration,
  } = useGetInstallConfigurationQuery(undefined, {
    skip: !canCacheAppData,
  });

  const { isLoading: loadingBoltOnOptions, refetch: fetchBoltOnOptions } =
    useGetBoltOnOptionsQuery(undefined, {
      skip: !canCacheWAData,
    });

  const {
    isLoading: loadingBoltOnTypeOptions,
    refetch: fetchBoltOnTypeOptions,
  } = useGetBoltOnTypeOptionsQuery(undefined, {
    skip: !canCacheWAData,
  });

  const {
    isLoading: loadingInstallNameOptions,
    refetch: fetchInstallNameOptions,
  } = useGetAllWAInstallNameOptionsQuery(undefined, {
    skip: !canCacheWAData,
  });

  const { isLoading: loadingWAPackageOptions, refetch: fetchWAPackageOptions } =
    useGetAllWAPackageOptionsQuery(undefined, {
      skip: !canCacheWAData,
    });

  const { isLoading: loadingWARoleOptions, refetch: fetchWARoleOptions } =
    useGetAllWARoleOptionsQuery(undefined, {
      skip: !canCacheWAData,
    });
  const {
    isLoading: loadingWASubscriptionStatusOptions,
    refetch: fetchWASubscriptionStatusOptions,
  } = useGetAllWASubscriptionStatusOptionsQuery(undefined, {
    skip: !canCacheWAData,
  });

  const isLoading =
    loadingTimezone ||
    loadingTaskTypeOptions ||
    loadingTaskStatusOptions ||
    loadingSystemText ||
    loadingSolutionTypes ||
    loadingSolutionTerms ||
    loadingReportTypeOptions ||
    loadingFrequencyTypeOptions ||
    loadingEfficacyOptions ||
    loadingDateFormatOptions ||
    loadingCurrencyOptions ||
    loadingCaseTypeOptions ||
    loadingCaseStatusOptions ||
    loadingCaseSeverityOptions ||
    loadingCaseRoles ||
    loadingCasePriorityOptions ||
    loadingCaseAnalysisTypes ||
    loadingCompanyLocations ||
    loadingInstallConfiguration ||
    loadingBoltOnOptions ||
    loadingBoltOnTypeOptions ||
    loadingInstallNameOptions ||
    loadingWAPackageOptions ||
    loadingWARoleOptions ||
    loadingWASubscriptionStatusOptions;

  const fetchLazy = useCallback(async () => {
    // Load report types
    const reportTypes = await dispatch(
      reportTypeApi.endpoints.getReportTypeOptions.initiate(undefined, {
        subscribe: true,
      })
    ).unwrap();

    // For each report type, load in the subtypes
    await Promise.all(
      reportTypes.map((type) =>
        dispatch(
          reportTypeApi.endpoints.getReportSubtypeOptions.initiate(
            type.reportTypeId,
            {
              subscribe: true,
            }
          )
        ).unwrap()
      )
    );

    // For each sub type, load in the rank by options
    await Promise.all(
      reportTypes.map((type) =>
        dispatch(
          reportTypeApi.endpoints.getReportRankByOptions.initiate(
            type.reportTypeId,
            {
              subscribe: true,
            }
          )
        ).unwrap()
      )
    );
  }, [dispatch]);

  const refetchAll = useCallback(async () => {
    // noinspection ES6MissingAwait
    fetchLazy().catch(() => {});

    try {
      setManuallyRefetching(true);
      await Promise.all([
        fetchTimezones(),
        fetchTaskTypes(),
        fetchTaskStatus(),
        fetchSystemText(),
        fetchSolutionTypes(),
        fetchSolutionTerms(),
        fetchReportTypes(),
        fetchFrequencyOptions(),
        fetchEfficacyOptions(),
        fetchDateFormatOptions(),
        fetchCurrencyOptions(),
        fetchCaseTypeOptions(),
        fetchCaseStatusOptions(),
        fetchCaseSeverityOptions(),
        fetchCaseRoles(),
        fetchCasePriorityOptions(),
        fetchCaseAnalysisTypes(),
        fetchCompanyLocations(),
        fetchInstallConfiguration(),
        fetchBoltOnOptions(),
        fetchBoltOnTypeOptions(),
        fetchInstallNameOptions(),
        fetchWAPackageOptions(),
        fetchWARoleOptions(),
        fetchWASubscriptionStatusOptions(),
      ]);
    } catch (e) {
      console.log('Failed to refetch common API cache', e);
    } finally {
      setManuallyRefetching(false);
    }
  }, [
    fetchLazy,
    fetchTimezones,
    fetchTaskTypes,
    fetchTaskStatus,
    fetchSystemText,
    fetchSolutionTypes,
    fetchSolutionTerms,
    fetchReportTypes,
    fetchFrequencyOptions,
    fetchEfficacyOptions,
    fetchDateFormatOptions,
    fetchCurrencyOptions,
    fetchCaseTypeOptions,
    fetchCaseStatusOptions,
    fetchCaseSeverityOptions,
    fetchCaseRoles,
    fetchCasePriorityOptions,
    fetchCaseAnalysisTypes,
    fetchCompanyLocations,
    fetchInstallConfiguration,
    fetchBoltOnOptions,
    fetchBoltOnTypeOptions,
    fetchInstallNameOptions,
    fetchWAPackageOptions,
    fetchWARoleOptions,
    fetchWASubscriptionStatusOptions,
  ]);

  useEffect(() => {
    if (canCacheWAData) {
      refetchAll();
    } else if (canCacheAppData && !!companyId) {
      refetchAll();
    }
  }, [companyId, canCacheAppData, refetchAll, canCacheWAData]);

  if (isLoading || manuallyRefetching) {
    return <FullScreenLoadingIndicator />;
  }

  return (
    <CommonApiCacheContext.Provider value={{ refresh: refetchAll }}>
      {children}
    </CommonApiCacheContext.Provider>
  );
}

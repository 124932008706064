import { api } from '@api/api';
import { CaseImpactTypeMetricsResource } from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import { Option } from '@api/types/option';

const caseImpactTypeMetricApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMetricDetail: build.query<CaseImpactTypeMetricsResource, number>({
      query: (metricId) => ({
        url: `caseImpactTypeMetric/${metricId}`,
      }),
    }),
    getAllMetricDetail: build.query<CaseImpactTypeMetricsResource[], void>({
      query: () => ({
        url: 'caseImpactTypeMetric',
      }),
    }),
    getCaseImpactMetricOptions: build.query<Option[], void>({
      query: () => ({
        url: 'caseImpactTypeMetric/options',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetMetricDetailQuery,
  useGetCaseImpactMetricOptionsQuery,
  useGetAllMetricDetailQuery,
} = caseImpactTypeMetricApi;

export default caseImpactTypeMetricApi;

import { ComponentType } from 'react';
import {
  Button,
  Dialog,
  DialogProps,
  Paper,
  PaperProps,
  styled,
} from '@mui/material';
import { UiPopupProps } from '@components/ui-popup/ui-popup-type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

export interface ModalProps<T> extends UiPopupProps<T> {}

interface HOCOptions {
  noPadding?: boolean;
  blurBackdrop?: boolean;
}

const StyledModal = styled(Dialog)<DialogProps & HOCOptions>(
  ({ blurBackdrop }) => ({
    background: 'rgba(0, 0, 0, 0.25)',
    backdropFilter: blurBackdrop ? 'blur(15px)' : undefined,
    '& .MuiPaper-root': {
      maxWidth: '1000px',
      borderRadius: 10,
    },
  })
);

const StyledContainer = styled(Paper)<HOCOptions & PaperProps>(
  ({ theme, noPadding }) => ({
    width: 1000,
    padding: noPadding ? 0 : 20,
    outline: 0,
    borderBottom: theme.palette.border.dividers,
    borderRadius: 10,
    '> div': {
      width: '100%',
    },
    '.close-button': {
      position: 'absolute',
      right: 12,
      top: 12,
      color: theme.palette.mainBlue,
      cursor: 'pointer',
    },
  })
);

export default function withModal<P extends ModalProps<T>, T = any>(
  Component: ComponentType<P>,
  opt: HOCOptions = { noPadding: false, blurBackdrop: false }
) {
  return (props: P) => {
    const { show, onClose } = props;

    return (
      <StyledModal
        open={show}
        onClose={() => onClose()}
        {...opt}
        scroll={'body'}
      >
        <StyledContainer {...opt}>
          <Component {...props} />
          <Button className="close-button" onClick={() => onClose()}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </StyledContainer>
      </StyledModal>
    );
  };
}

import requireAuth from '@components/hoc/with-required-auth-hoc';
import useCompanyRcaSettings from './company-rca-settings-hook';
import SettingsOptionContainer from '@components/containers/settings-option-container';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

function CompanyRcaSettingsPage() {
  const navigate = useNavigate();
  const { modifiedTimes } = useCompanyRcaSettings();

  return (
    <>
      <Typography sx={{ lineHeight: '40px' }} variant="h2">
        RCA Settings
      </Typography>
      <SettingsOptionContainer
        title="Label Configuration"
        message="Configure the labels within your What Caused This platform to suit your organisational reporting"
        modifiedTime={
          modifiedTimes?.systemText
            ? new Date(modifiedTimes?.systemText)
            : undefined
        }
        onClick={() => navigate('/company-profile/rca-settings-labels')}
      />
      <SettingsOptionContainer
        title="RCA Flow Type"
        message="Configure the RCA flow options available when creating an RCA"
        modifiedTime={
          modifiedTimes?.caseAnalysisType
            ? new Date(modifiedTimes?.caseAnalysisType)
            : undefined
        }
        onClick={() => navigate('/company-profile/rca-settings-types')}
      />
      <SettingsOptionContainer
        title="Impact Category"
        message="Configure the impact category options available when creating an RCA"
        modifiedTime={
          modifiedTimes?.caseImpact
            ? new Date(modifiedTimes?.caseImpact)
            : undefined
        }
        onClick={() => navigate('/company-profile/rca-settings-impacts')}
      />
      <SettingsOptionContainer
        title="Business Function Category"
        message="Configure the business function category options available when creating an RCA"
        modifiedTime={
          modifiedTimes?.caseType
            ? new Date(modifiedTimes?.caseType)
            : undefined
        }
        onClick={() => navigate('/company-profile/rca-settings-case-types')}
      />
      <SettingsOptionContainer
        title="Theme Configuration"
        message="Configure the theme options available when adding themes to a cause box"
        modifiedTime={
          modifiedTimes?.theme ? new Date(modifiedTimes?.theme) : undefined
        }
        onClick={() => navigate('/company-profile/rca-settings-themes')}
      />
    </>
  );
}

export default requireAuth(CompanyRcaSettingsPage);

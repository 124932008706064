import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import Column from '@components/layout-util-components/column';
import { Button, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import WCTTextField from '@components/input/text-field';
import useField from '@hooks/use-field-hook';
import {
  lessThan,
  required,
  ruleForEach,
  validatorSelector,
} from '@util/validators';
import ActionsRow from '@components/layout-util-components/actions-row';
import Spacer from '@components/layout-util-components/spacer';
import WCTSwitchField from '@components/input/switch-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { usePageAlertVariants } from '@components/alerts';
import useBusyAction from '@hooks/use-busy-action-hook';
import { isApiError } from '@api/types/api-error';
import { invalidation } from '@api/cache-util';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import AsyncButton from '@components/buttons/async-button';
import { UpdateCaseImpactCategoryRequest } from '@api/types/case-impact-type/update-case-impact-category.request';
import { CompanyCaseImpactResource } from '@api/types/company/company-case-impact/company-case-impact.resource';
import {
  useRemoveCompanyCaseImpactMutation,
  useUpdateCompanyCaseImpactMutation,
} from '@api/endpoints/company/company-case-impact-type.api';
import { UpdateCompanyCaseImpactRequest } from '@api/types/company/company-case-impact/update-company-case-impact.request';
import useRolloverInfo from '@components/rollover-info/use-rollover-info';
import RolloverInfo from '@components/rollover-info/rollover-info';
import { CaseTypeMetricInput } from './case-type-metric-input';
import { CaseImpactTypeMetricsResource } from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import Row from '@components/layout-util-components/row';
import { ReactComponent as CircleQuestion } from '@assets/svgs/circle-question.svg';

interface HelpTextItem {
  caseImpactTypeMetricOptionId?: number;
  helpText?: string;
}

export interface EditImpactModalProps extends ModalProps<void> {
  impact: CompanyCaseImpactResource;
  metricOptions: CaseImpactTypeMetricsResource[];
}

function EditImpactModal({
  impact,
  onClose,
  metricOptions,
}: EditImpactModalProps) {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const [updateImpact] = useUpdateCompanyCaseImpactMutation();
  const [deleteImpact] = useRemoveCompanyCaseImpactMutation();

  const name = useField<string>(
    [required(), lessThan(200, 'Enter 200 characters or less')],
    impact.name
  );
  const metric = useField<number>([required()], impact.caseImpactTypeMetricId);
  const isActive = useField<boolean>([required()], !impact.disabled);
  const guidance = useField<boolean>([], impact.helpText.length > 0);
  const helpText = useField<HelpTextItem[]>(
    [
      ruleForEach(
        validatorSelector<HelpTextItem>('helpText', [
          lessThan(150, 'Enter 150 characters or less'),
        ])
      ),
    ],
    impact.helpText
  );

  const { validateAll, isDirty } = useFieldsWatcher([
    name,
    metric,
    helpText,
    isActive,
    guidance,
  ]);

  const categoryId = impact.caseImpactTypeCategoryId;

  const itemType = 'Impact';
  const deleteButtonRollOver = useRolloverInfo(
    'This impact is in use and so can no longer be deleted',
    impact.hasRCA
  );

  const [onSave, isSaving] = useBusyAction(async () => {
    if (!isDirty) {
      onClose();
      return;
    }

    try {
      if (!validateAll()) {
        return;
      }

      await updateImpact({
        caseImpactTypeId: impact.caseImpactTypeId,
        caseImpactTypeCategoryId: categoryId,
        name: name.value,
        disable: !isActive.value,
        caseImpactTypeMetricId: metric.value,
        helpText: guidance.value ? helpText.value : [],
      }).unwrap();

      await invalidation('CaseImpact');

      showSuccessMessage(`${itemType} updated successfully`);

      onClose();
    } catch (error) {
      if (isApiError<UpdateCaseImpactCategoryRequest>(error)) {
        showErrorMessage(
          error.errors?.disable ??
            error.errors?.caseImpactTypeCategoryId ??
            error.message
        );
        name.setError(error.errors?.name);
      } else if (isApiError<UpdateCompanyCaseImpactRequest>(error)) {
        const errors = error.errors as any;
        showErrorMessage(
          errors?.caseImpactTypeCategoryId ??
            errors?.caseImpactTypeId ??
            error.message
        );

        name.setError(errors?.name);
        metric.setError(errors?.caseImpactTypeMetricId);
      }
    }
  });

  const [onDelete, isDeleting] = useBusyAction(async () => {
    try {
      await deleteImpact(impact.caseImpactTypeId).unwrap();

      await invalidation('CaseImpact');

      showSuccessMessage(`${itemType} deleted successfully`);

      onClose();
    } catch (error) {
      if (isApiError(error)) {
        const errors = error.errors as any;
        showErrorMessage(
          errors?.caseImpactTypeCategoryId ??
            errors?.caseImpactTypeId ??
            error.message
        );
      }
    }
  });

  const metricOption = metricOptions.find(
    (x) => x.caseImpactTypeMetricId === metric.value
  );

  return (
    <>
      <Column>
        <Typography>Edit {itemType}</Typography>
        <Gap size={20} />
        <WCTTextField
          name="impact_name"
          label={`Rename ${itemType}`}
          value={name.value}
          onChange={name.set}
          error={name.error}
          readonly={impact.hasRCA}
          helperText={
            impact.hasRCA
              ? 'This Impact is in use and so can no longer be renamed'
              : undefined
          }
        />
        <Gap size={10} />
        <CaseTypeMetricInput
          value={metric.value}
          metricOptions={metricOptions}
          onChange={(v: number) => {
            metric.set(v);
            helpText.set([]);
          }}
          error={metric.error}
          readonly={impact.hasRCA}
          helperText={
            impact.hasRCA
              ? 'This Impact is in use and so can no longer be changed'
              : undefined
          }
        />
        <Gap size={10} />
        {metric.value && (
          <WCTSwitchField
            label="Give help guidance to users on what each value represents within your organisation"
            name={'impact_guidance'}
            value={!!guidance.value}
            error={guidance.error}
            onChange={guidance.set}
          />
        )}
        {guidance.value && (
          <Column>
            <Gap size={20} />
            <Typography variant="body1">Help Guidance</Typography>
            <Row>
              <Typography
                variant="body2"
                style={{ padding: '2px 8px 8px 0px' }}
              >
                This guidance will appear for the option on hover of a question
                mark icon
              </Typography>
              <CircleQuestion width={14} height={14} />
            </Row>

            {(((metricOption && metricOption.options) ?? []).length > 0
              ? metricOption!.options
              : [
                  {
                    label: metricOptions.find(
                      (x) =>
                        x.caseImpactTypeMetricId ===
                        impact.caseImpactTypeMetricId
                    )?.name,
                    id: null,
                  },
                ]
            ).map((metricOption) => {
              const ht = helpText.value?.find(
                (x) => x.caseImpactTypeMetricOptionId === metricOption.id
              );

              console.log('helpText.value?', helpText.value, metricOption.id);

              return (
                <>
                  <Gap size={10} />
                  <WCTTextField
                    key={metricOption.id}
                    name={`impact_help_text_${metricOption.id}`}
                    label={`Help Guidance for ${metricOption.label}`}
                    value={ht?.helpText}
                    maxLength={150}
                    showCharCount
                    onChange={(value) => {
                      const existingHelpText = helpText.value ?? [];

                      const updatedHelpText = existingHelpText.some(
                        (x) =>
                          x.caseImpactTypeMetricOptionId === metricOption.id
                      )
                        ? existingHelpText.map((item) =>
                            item.caseImpactTypeMetricOptionId ===
                            metricOption.id
                              ? { ...item, helpText: value }
                              : item
                          )
                        : [
                            ...existingHelpText,
                            {
                              caseImpactTypeMetricOptionId: metricOption.id,
                              helpText: value,
                            },
                          ];

                      helpText.set(updatedHelpText);
                    }}
                  />
                </>
              );
            })}
          </Column>
        )}
        <Gap size={13} />
        <WCTSwitchField
          label="Active"
          value={isActive.value}
          onChange={isActive.set}
          error={isActive.error}
          sx={{ alignSelf: 'flex-end' }}
        />
        <Gap size={20} />
        <ActionsRow>
          <Button variant="outlined" onClick={() => onClose()}>
            Cancel
          </Button>
          <Spacer />
          <AsyncButton
            ref={deleteButtonRollOver.ref}
            variant="outlined"
            onClick={() => {
              if (!impact.hasRCA) {
                onDelete();
              }
            }}
            className={impact.hasRCA ? 'fake-disabled' : ''}
            disabled={isSaving}
            isBusy={isDeleting}
          >
            <FontAwesomeIcon icon={faTrash} />
            Delete {itemType}
          </AsyncButton>
          <AsyncButton onClick={onSave} disabled={isDeleting} isBusy={isSaving}>
            Save
          </AsyncButton>
        </ActionsRow>
      </Column>
      <RolloverInfo {...deleteButtonRollOver} />
    </>
  );
}

export default withModal(EditImpactModal);
